.u-bold {
  font-weight: bold;
}

/*FC2ロゴ*/
@font-face {
  font-family: "feticon";
  src: url("../fonts/feticon.ttf") format("truetype"),
    url("../fonts/feticon.woff") format("woff"),
    url("../fonts/feticon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="fi-"],
[class*=" fi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "feticon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-fc2:before {
  content: "\e90b";
}
