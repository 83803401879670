.p-lpv {
  &_inner {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @media (--md-max) {
      padding:0 10px;
    }
  }

  &_header {
    background: #000;
    margin-bottom:60px;
    background:url(../img/ja/lpv/LPV_joinBackground.png);

    @media (--md-max) {
        margin-bottom:45px;
      }

      @media (--sm-max) {
        margin-bottom:30px;
      }
  }

  &_movies {
    margin-bottom:60px;
    text-align: center;

    @media (--md-max) {
        margin-bottom:45px;
      }

    @media (--sm-max) {
        margin-bottom:0px;
      }



    &_h2 {
      font-size: 5rem;
      font-weight: bold;
      line-height: 1.5;

      @media (--md-max) {
          font-size: 3.5rem;
      }

      @media (--sm-max) {
          font-size: 2.3rem;
      }
    }

    &_p {
      font-size: 2rem;
      margin-bottom:30px;

      @media (--md-max) {
          font-size: 1.8rem;
      }

      @media (--sm-max) {
          font-size: 1.4rem;
      }
    }

    &_items {
      display:flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &_item {
      margin:4px;
      width:15.5%;
      max-width: 190px;

      @media (--sm-max) {
        width:30%;
      }
    }

    &_link {
      
    }

    &_img {
      max-width: 190px;
      width: 100%;
    }
  }

  &_how {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &_h2 {
      font-size: 5rem;
      font-weight: bold;
      line-height: 1.5;
      margin-bottom:10px;

      @media (--md-max) {
          font-size: 3.5rem;
      }

      @media (--sm-max) {
          font-size: 2.3rem;
      }
    }

    &_h3 {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.5;
      margin-bottom:30px;

      @media (--md-max) {
          font-size: 1.8rem;
      }

      @media (--sm-max) {
          font-size: 1.6rem;
      }
    }

    &_p {
      font-size: 1.6rem;
      line-height: 2;
      text-align: center;
      margin-bottom:30px;

      @media (--md-max) {
          font-size: 1.4rem;
      }

      &::after{
        content:"▼";
        display: block;
        font-size:3rem;
        color: var(--color--red);
        margin-bottom:30px;
      }
    }
  }

  &_buy {
    display: flex;
    flex-direction:column;
    align-items: center;
    background:url(../img/ja/lpv/LPV_joinBackground.png) repeat-x;
    padding: 30px 20px;
    margin-bottom:60px;
    text-align: center;

    @media (--md-max) {
      margin-bottom: 45px;
    }

    @media (--sm-max) {
      margin-bottom: 30px;
    }

    &_img {
      width:119.5px;
      margin-bottom:0px;
    }

    &_h2 {
      font-size:5rem;
      color:var(--color--white);

      @media (--md-max) {
          font-size: 3.5rem;
      }

      @media (--sm-max) {
          font-size: 2.3rem;
      }
    }

    &_p {
      font-size: 1.6rem;
      color:var(--color--white);
      margin-bottom: 0px;

      @media (--md-max) {
          font-size: 1.8rem;
      }

      @media (--sm-max) {
          font-size: 1.6rem;
      }
    }

    &_h3 {
      font-size:3rem;
      color:var(--color--white);
      margin-bottom:10px;
    }

    &_link {
      width: 320px;
      font-size:2rem;
      font-weight: bold;
      text-align: center;
      background:var(--color--red);
      color:var(--color--white);
      padding:10px;

      &:hover {
        color:var(--color--white);
      }
    }
  }

  &_more {
    margin-bottom:30px;
    text-align: center;

    @media (--sm-max) {
      margin-bottom:10px;
    }

    &_h2 {
      font-size: 5rem;
      font-weight: bold;
      line-height: 1.5;

      @media (--md-max) {
          font-size: 3.5rem;
      }

      @media (--sm-max) {
          font-size: 2.3rem;
      }
    }

    &_p {
      font-size: 2rem;
      margin-bottom:30px;

      @media (--md-max) {
        font-size: 1.6rem;
        margin-bottom:10px;
      }

      &::after {
        content:"▼";
        display: block;
        font-size:3rem;
        color: var(--color--red);
        margin-bottom:30px;

        @media (--sm-max) {
          margin-bottom:10px;
        }
      }
    }
  }

  &_single {
    background: var(--color--black);
    color:var(--color--white);
    text-align: center;
    padding: 30px 20px;

    &_h2 {
      font-size: 5rem;
      font-weight: bold;

      @media (--md-max) {
          font-size: 3.5rem;
      }

      @media (--sm-max) {
          font-size: 2.3rem;
      }
    }

    &_p {
      font-size: 1.6rem;
      line-height: 2;
      margin-bottom:20px;

      @media (--md-max) {
          font-size: 1.4rem;
      }
    }

    &_h3 {
      font-size: 2rem;
      margin-bottom:30px;

      @media (--md-max) {
          font-size: 1.8rem;
      }

      @media (--sm-max) {
          font-size: 1.6rem;
      }
    }

    &_link {
      width: 320px;
      font-size:2rem;
      font-weight: bold;
      background:var(--color--red);
      color:var(--color--white);
      padding:10px 20px;

      &:hover {
        color:var(--color--white);
      }
    }
  }

}