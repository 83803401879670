/*
c-exhibitorCard

女優一覧のカードです

markup:

Styleguide 3.4.0
 */

.c-exhibitor {
  &Grid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    @media (--sm-max) {
      margin-top: 20px;
    }
  }

  &Card {
    width: calc(20% - 20px);
    margin-right: 18px;
    margin-bottom: 20px;
    @media (--md-max) {
      width: calc(33.3333% - 20px);
    }
    @media (--sm-max) {
      width: calc(50% - 20px);
    }
  }
}
