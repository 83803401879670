/*affiliateLP*/
/*流用*/
.p-lpAf {
  &_inner {
    max-width: 1120px;
    margin: 0 auto 80px;
    @media (--lg-max) {
      margin: 0 20px 60px;
    }
    @media (--md-max) {
      margin: 0 20px 40px;
    }
    @media (--sm-max) {
      margin: 0 15px 40px;
    }
  }
  &_ttH2 {
    font-size: 2rem;
    padding: 12px 0;
    margin-bottom: 30px;
    position: relative;
    border-bottom: 3px solid #b9b9b9;
    &::before {
      content: "";
      display: block;
      z-index: 1;
      position: absolute;
      left: 0;
      bottom: -3px;
      border-bottom: 3px solid #ff0000;
      width: 150px;
    }
  }
  &_p {
    font-size: 1.6rem;
    line-height: 2;
    white-space: pre-line;
    @media (--sm-max) {
      font-size: 1.4rem;
    }
  }
  &_join {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--color--black);
    padding: 60px 0;
    @media (--sm-max) {
      padding: 40px 0;
    }
    &Tt {
      padding: 0 15px 35px;
    }
    &Bt {
      display: flex;
      align-items: center;
      padding: 14px 70px 10px;
      background: var(--color--red);
      color: var(--color--white);
      font-size: 3rem;
      font-weight: bold;
      @media (--sm-max) {
        padding: 14px 20px 10px;
        font-size: 2.6rem;
      }
      &:hover {
        color: var(--color--white);
      }
      &-min {
        font-size: 1.8rem;
        margin-right: 20px;
      }
    }
  }
  &_pc {
    display: block;
    @media (--sm-max) {
      display: none;
    }
  }
  &_sp {
    display: none;
    @media (--sm-max) {
      display: block;
    }
  }

  /*section*/
  &_head {
    background-image: url(/tpl/g1/img/ja/lpAf/affiLP_head_after.png);
    background-repeat: repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 120px;
    @media (--md-max) {
      margin-bottom: 80px;
    }
    @media (--sm-max) {
      margin-bottom: 60px;
    }

    &H1 {
      max-width: 1460px;
    }
  }
  &_prpo {
  }
  &_discri {
    &Img {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
    }
  }
  &_how {
    margin-top: 80px;
    @media (--sm-max) {
      margin-top: 60px;
    }
  }

  &_reco {
    max-width: 1020px;
    width: auto;
    margin: 0 auto 80px;
    background: #f3f3f3;
    border-radius: 15px;
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (--lg-max) {
      margin: 0 20px 60px;
    }

    @media (--md-max) {
      margin: 0 20px 60px;
    }

    @media (--sm-max) {
      margin: 0 auto 60px;
      border-radius: 0;
    }
    &H2 {
      font-size: 2rem;
      margin-bottom: 40px;
    }
    &Lab {
      font-size: 1.6rem;
      background: var(--color--white);
      padding: 13px 20px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--color--black);
      margin-bottom: 45px;
      font-weight: bold;
    }
    &Box {
      display: flex;
      @media (--sm-max) {
        flex-wrap: wrap;
      }
    }
    &Link {
      margin: 0 20px 20px;
      @media (--sm-max) {
        margin: 0 0 10px;
      }
    }
  }
  &_step {
    &Item {
      list-style: none;
    }
    &Item {
      list-style: none;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 60px;
      @media (--sm-max) {
        margin-top: 40px;
      }
    }
    &Items {
      list-style: none;
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      margin-bottom: 10px;
      @media (--sm-max) {
        flex-direction: column;
      }
      @media (--sm-max) {
        flex-direction: column;
        align-items: normal;
        margin-bottom: 20px;
      }
    }
    &P {
      &--left {
        font-size: 2.2rem;
        font-weight: bold;
        color: var(--color--black);
        margin-bottom: 10px;
        margin-right: 60px;
        @media (--md-max) {
          margin-right: 30px;
        }
        @media (--sm-max) {
          margin-right: 30px;
        }
        &Min {
          color: var(--color--red);
        }
      }
      &--right {
        font-size: 1.6rem;
        margin-bottom: 10px;
      }
    }
  }
  &_chara {
    margin-bottom: 40px;
    @media (--sm-max) {
      margin-bottom: 20px;
    }
    &Item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 60px;
      @media (--sm-max) {
        flex-direction: column;
        margin-top: 40px;
      }
    }
    &Items {
      max-width: 210px;
      margin: 0 10px 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &Img {
      margin-bottom: 20px;
    }
    &H3 {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 15px;
      text-align: center;
    }
    &P {
      font-size: 1.4rem;
      text-align: center;
    }
  }
  &_QA {
    margin-top: 80px;
    margin-bottom: 80px;
    @media (--sm-max) {
      margin-top: 60px;
      margin-bottom: 60px;
    }
    &Item {
      margin-top: 40px;
    }
    &Items {
      margin-bottom: 45px;
    }
    &Q {
      background: #f3f3f3;
      padding: 20px;
      font-size: 1.6rem;
      margin-bottom: 25px;
      @media (--sm-max) {
        font-size: 1.4rem;
        padding: 5px 10px;
        margin-bottom: 15px;
      }
      &Red {
        color: var(--color--red);
        padding-right: 10px;
      }
    }
    &A {
      font-size: 1.6rem;
      line-height: 2;
      white-space: pre-line;
      @media (--sm-max) {
        font-size: 1.4rem;
      }
    }
  }
}
