/*
c-copyright

コピーライトコンポーネント

markup:
<div class="l-footer__bar">
  <div class="l-footer__barInner u-container">
    <div class="l-footer__copy c-copyright">
      <small class="c-copyright__small">Copyright ©『xxx』All Rights Reserved.</small>
    </div><!-- /.l-footer__copy -->
  </div><!-- /.l-footer__barInner -->
</div>

Styleguide 5.1.0
 */

.c-copyright {
  &__small {
    color: #fff;
    white-space: nowrap;
  }
}
