/*
c-footerMainNav

フッターメインナビゲーションコンポーネントです。

markup:
<nav class="c-footerMainNav">
  <div class="c-footerMainNav__title">ヘルプ</div>
  <ul class="c-footerMainNav__list">
    <li class="c-footerMainNav__item">
      <a href="/guide/payment" class="c-footerMainNav__link">ご購入ガイド</a>
    </li>
    <li class="c-footerMainNav__item">
      <a href="/devices" class="c-footerMainNav__link">動作環境</a>
    </li>
    <li class="c-footerMainNav__item">
      <a href="/faq" class="c-footerMainNav__link">よくある質問</a>
    </li>
    <li class="c-footerMainNav__item">
      <a href="/inquiry" class="c-footerMainNav__link">お問い合わせ</a>
    </li>
  </ul>
</nav><!-- /.c-footerMainNav -->


Styleguide 17.2.0
 */

.c-footerMainNav {
  &__title {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }

  &__list { }

  &__item {
    margin-bottom: 5px;
  }

  &__link {
    display: block;
    padding: 3px 0;
  }
}

