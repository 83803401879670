.c-filterNav {
  &__list {
    display: flex;
    flex-wrap: wrap;
    border-bottom: solid 1px var(--color--gray);
    margin-bottom: 10px;

    &Wrapper {
    }
  }

  &__item {
    margin-bottom: 10px;

    @media (--xs-max) {
      width: calc(100% / 2);
    }

    @media (--xs) {
      &:not(:last-of-type) {
        margin-right: 25px;
      }
    }
  }

  &__submit {
    height: 26px;
    width: 70px;
    -webkit-appearance: none;
    background-color: var(--color--black);
    border: none;
    color: #fff;
    border-radius: 15px;
    line-height: 26px;
    text-align: center;
    outline: none;

    &--more {
      height: 26px;
      width: 92px;
      -webkit-appearance: none;
      background-color: var(--color--black);
      border: none;
      color: #fff;
      border-radius: 15px;
      line-height: 26px;
      text-align: center;
      outline: none;
    }
  }
}
