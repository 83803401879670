.c-overrideCheckbox {
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;

  input[type="checkbox"] {
    width: 27px;
    height: 27px;
    margin: 0;

    &:checked + label::before {
      width: 20px;
      height: 20px;
      background-color: var(--color--red);
      border-color: var(--color--red);
      margin: 2px;
    }

    &:checked + label::after {
      width: 27px;
      height: 27px;
      background-color: var(--color--black);
      border-color: var(--color--black);
      margin: 2px;
    }
  }

  label {
    &::before {
      width: 27px;
      height: 27px;
      margin-right: 10px;
      margin-left: -1px;
      margin-top: 1px;
    }
  }
}
