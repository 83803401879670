.p-lp {
  &m {
    width: 100%;

    &_inner {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    }

    &_title {
      margin-bottom: 60px;
      text-align: center;
      background: #000;
    }

    &_memberJoin {
      width: 100%;
      background: #000;
      color: #fff;
      padding: 60px 20px;

      &_box {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &_img {
        margin-bottom: 30px;
      }

      &_h2 {
        font-size: 7rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.5;

        @media (--md-max) {
          font-size: 6rem;
        }

        @media (--sm-max) {
          font-size: 2.3rem;
        }
      }

      &_p {
        font-size: 2rem;
        margin-bottom: 20px;
        text-align: center;

        @media (--sm-max) {
          font-size: 1.4rem;
        }
      }

      &_h3 {
        font-size: 2rem;
        font-weight: 100;
        margin-bottom: 30px;

        @media (--sm-max) {
          font-size: 2.3rem;
        }
      }

      &_link {
        display: inline-block;
        background: red;
        font-size: 2.5rem;
        color: #fff;
        padding: 10px 30px 8px;

        @media (--sm-max) {
          font-size: 2.5rem;
        }

        &:hover {
          color: #fff;
        }
      }
    }

    &_PushGal {
      margin: 0 auto 60px;

      @media (--md-max) {
        margin: 0 auto 40px;
        padding: 0 20px;
      }

      @media (--sm-max) {
        margin: 0 auto 40px;
        padding: 0 20px;
      }

      &_h2 {
        font-size: 5rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.5;

        @media (--sm-max) {
          font-size: 3rem;
        }
      }

      &_p {
        font-size: 2rem;
        margin-bottom: 30px;
        text-align: center;
        word-break: keep-all;
        @media (--sm-max) {
          font-size: 1.6rem;
          word-break: break-all;
        }
      }

      &_items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
      }

      &_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        border-radius: 5px;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
        border: 1px solid #eee;
        max-width: 23%;
        width: 23%;
        padding: 20px 20px 30px;
        margin: 0 10px 30px;

        @media (--md-max) {
          width: 47%;
          max-width: 47%;
          justify-content: space-between;
        }

        @media (--sm-max) {
          width: 100%;
          max-width: 100%;
          justify-content: space-between;
        }
      }

      &_img {
        max-width: 138px;
        width: 100%;
        margin-bottom: 20px;
      }

      &_h3 {
        font-size: 1.6rem;
        color: #ff639f;
        margin-bottom: 10px;

        @media (--sm-max) {
          font-size: 2rem;
        }
      }

      &_itemP {
        font-size: 1.4rem;
        text-align: center;

        @media (--sm-max) {
          font-size: 1.4rem;
        }
      }
    }

    &_galList {
      margin: 60px 20px;

      &_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      &_h2 {
        font-size: 5rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.5;

        @media (--sm-max) {
          font-size: 2rem;
          margin-bottom: 10px;
        }

        &-red {
          display: inline-block;
          color: #fff;
          background: red;
          padding: 10px 10px 0px;
          margin: 0 10px;
          border-radius: 6px;
          line-height: 1;

          @media (--sm-max) {
            padding: 7px 10px 3px;
          }
        }
      }

      &_p {
        font-size: 2rem;
        margin-bottom: 50px;

        @media (--sm-max) {
          font-size: 1.6rem;
        }

        &-red {
          color: red;
        }
      }

      &_items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
      }

      &_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 11%;
        min-width: 120px;
        margin: 0 5px 30px;
      }

      &_img {
        max-width: 120px;
        width: 100%;
        margin-bottom: 15px;
      }

      &_h3 {
        font-size: 1.2rem;
        font-weight: 100;
      }
    }

    &_longSample {
      margin: 60px 20px;

      &_box {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &_h2 {
        font-size: 5rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.5;

        @media (--md-max) {
          font-size: 5rem;
        }

        @media (--sm-max) {
          font-size: 2.3rem;
        }
      }

      &_p {
        font-size: 2rem;
        margin-bottom: 50px;

        @media (--sm-max) {
          font-size: 1.6rem;
        }
      }

      &_items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        @media (--md-max) {
          justify-content: flex-start;
        }
      }

      &_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 23%;
        max-width: 23%;
        margin: 0 10px 50px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.1);
        border: 1px solid #eee;
        padding: 30px;

        @media (--md-max) {
          width: 47%;
          max-width: 47%;
          margin: 0 10px 20px;
        }

        @media (--sm-max) {
          width: 100%;
          min-width: 100%;
        }
      }

      &_img {
        max-width: 138px;
        width: 138px;
        margin-bottom: 15px;

        @media (--md-max) {
          min-width: 138px;
          width: 100%;
          margin-right: 15px;
        }

        @media (--sm-max) {
          min-width: 138px;
          margin-bottom: 15px;
        }
      }

      &_h3 {
        font-size: 1.2rem;
        font-weight: 100;

        @media (--md-max) {
          font-size: 1.8rem;
          max-width: 100%;
        }

        @media (--sm-max) {
          font-size: 1.6rem;
          max-width: 100%;
        }
      }
    }

    &_pushMovie {
      margin: 60px 20px;

      @media (--sm-max) {
        margin: 60px 20px 30px;
      }

      &_box {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &_h2 {
        font-size: 5rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.5;

        @media (--md-max) {
          font-size: 5rem;
        }

        @media (--sm-max) {
          font-size: 2.3rem;
        }
      }

      &_p {
        font-size: 2rem;
        margin-bottom: 50px;

        @media (--sm-max) {
          margin-bottom: 30px;
          font-size: 1.6rem;
        }
      }

      &_items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      &_item {
        display: flex;
        flex-direction: column;
        width: 45%;
        max-width: 520px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
        border: 1px solid #eee;
        padding: 30px;
        margin: 0 20px 50px;

        @media (--md-max) {
          width: 100%;
          max-width: 100%;
          margin: 0 0 30px;
        }

        @media (--sm-max) {
          width: 100%;
          max-width: 100%;
          margin: 0 0 30px;
        }
      }

      &_img {
        max-width: 460px;
        width: 100%;
        margin-bottom: 30px;

        @media (--md-max) {
          max-width: 100%;
        }
      }

      &_h3 {
        color: #ff639f;
        font-size: 1.6rem;
        line-height: 1.5;
        margin-bottom: 15px;

        @media (--md-max) {
          font-size: 1.6rem;
        }

        @media (--sm-max) {
          font-size: 1.6rem;
        }
      }

      &_itemP {
        font-size: 1.4rem;
        line-height: 2;
        word-break: keep-all;

        @media (--md-max) {
          font-size: 1.6rem;
        }

        @media (--sm-max) {
          font-size: 1.6rem;
          word-break: break-all;
        }
      }
    }
    &_formBox {
      padding: 60px 20px;
      max-width: 900px;
      margin: auto;
    }
    &_footer {
      width: 100%;
      position: fixed;
      bottom: 0px;
      background: black;
      padding: 15px;
      z-index: 9999;
      text-align: center;
      &Btn {
        width: auto;
        max-height: 60px;
      }
    }
    &_slider {
      margin-bottom: 80px !important;

      .c-productCard {
        padding: 0 10px;
      }
      .c-productGrid__item {
        width:200px !important;
      }
    }
  }
}
