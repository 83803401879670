button,
input,
optgroup,
select,
textarea { margin:0; font:inherit; color:inherit; border: solid 1px var(--color--form-gray); }
button { overflow:visible; }
button,
select { text-transform:none; }
button,
html input[type=button],
input[type=reset],
input[type=submit] { -webkit-appearance:button; cursor:pointer; }
button[disabled],
html input[disabled] { cursor:default; }
button::-moz-focus-inner,
input::-moz-focus-inner { padding:0; border:0; }
input { line-height:normal; }
input[type=checkbox],
input[type=radio] { box-sizing:border-box; padding:0; }
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { height:auto; }
input[type=search] { box-sizing:content-box; -webkit-appearance:textfield; }
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration { -webkit-appearance:none; }
fieldset { padding:.35em .625em .75em; margin:0 2px; border:1px solid silver; }
legend { padding:0; border:0; }
textarea { overflow:auto; }

button,
input,
select,
textarea { font-family:inherit; font-size:inherit; line-height:inherit; }

fieldset { min-width:0; padding:0; margin:0; border:0; }
legend { display:block; width:100%; padding:0; margin-bottom:22px; font-size:21px; line-height:inherit; color:inherit; border:0; border-bottom:1px solid transparent; }
label { display:inline-block; }
input[type=search] { box-sizing:border-box; }
input[type=checkbox],
input[type=radio] { margin:4px 0 0; margin-top:1px \9; line-height:normal; }
input[type=file] { display:block; }
input[type=range] { display:block; width:100%}
select[multiple],
select[size] { height:auto; }
input[type=file]:focus,
input[type=checkbox]:focus,
input[type=radio]:focus { outline:thin dotted; outline:5px auto -webkit-focus-ring-color; outline-offset:-2px; }
output { display:block; padding-top:7px; font-size:14px; line-height:1.57142857; color:#76838f; }
.form-control { display:block; width:100%; height:36px; padding:6px 15px; font-size:14px; line-height:1.57142857; color:#76838f; background-color:#fff; background-image:none; border:1px solid var(--color--form-gray); border-radius:3px; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075); transition:border-color ease-in-out .15s,  box-shadow ease-in-out .15s; }
.form-control:focus { border-color:var(--color--black); outline:0; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(98, 168, 234, .6); }
.form-control.focus,
.form-control:focus { border-color:var(--color--black); box-shadow:none; }
.form-control::-moz-placeholder { color:#a3afb7; opacity:1; }
.form-control:-ms-input-placeholder { color:#a3afb7; }
.form-control::-webkit-input-placeholder { color:#a3afb7; }
.form-control::-ms-expand { background-color:transparent; border:0; }
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control { background-color:#f3f7f9; opacity:1; }
.form-control[disabled],
fieldset[disabled] .form-control { cursor:not-allowed; }
textarea.form-control { height:auto; }
input[type=search] { -webkit-appearance:none; }

.form-group { margin-bottom:20px; }
.checkbox,
.radio { position:relative; display:block; margin-top:10px; margin-bottom:10px; }
.checkbox label,
.radio label { min-height:22px; padding-left:20px; margin-bottom:0; font-weight:400; cursor:pointer; }
.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio] { position:absolute; margin-top:4px 9px; margin-left:-20px; }
.checkbox+.checkbox,
.radio+.radio { margin-top:-5px; }
.checkbox-inline,
.radio-inline { position:relative; display:inline-block; padding-left:20px; margin-bottom:0; font-weight:400; vertical-align:middle; cursor:pointer; }
.checkbox-inline+.checkbox-inline,
.radio-inline+.radio-inline { margin-top:0; margin-left:10px; }
fieldset[disabled] input[type=checkbox],
fieldset[disabled] input[type=radio],
input[type=checkbox].disabled,
input[type=checkbox][disabled],
input[type=radio].disabled,
input[type=radio][disabled] { cursor:not-allowed; }
.checkbox-inline.disabled,
.radio-inline.disabled,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .radio-inline { cursor:not-allowed; }
.checkbox.disabled label,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .radio label { cursor:not-allowed; }
.form-control-static { min-height:36px; padding-top:7px; padding-bottom:7px; margin-bottom:0; }
.form-control-static.input-lg,
.form-control-static.input-sm { padding-right:0; padding-left:0; }
.input-sm { height:32px; padding:6px 13px; font-size:12px; line-height:1.5; border-radius:2px; }
select.input-sm { height:32px; line-height:32px; }
select[multiple].input-sm,
textarea.input-sm { height:auto; }
select.input-sm { padding-top:0; padding-bottom:0; }
.form-group-sm .form-control { height:32px; padding:6px 13px; font-size:12px; line-height:1.5; border-radius:2px; }
.form-group-sm select.form-control { height:32px; line-height:32px; }
.form-group-sm select[multiple].form-control,
.form-group-sm textarea.form-control { height:auto; }
.form-group-sm .form-control-static { height:32px; min-height:34px; padding:7px 13px; font-size:12px; line-height:1.5; }
.input-lg { height:46px; padding:10px 18px; font-size:18px; line-height:1.3333333; border-radius:4px; }
select.input-lg { height:46px; line-height:46px; }
select[multiple].input-lg,
textarea.input-lg { height:auto; }
select.input-lg { padding-top:0; padding-bottom:0; }
.form-group-lg .form-control { height:46px; padding:10px 18px; font-size:18px; line-height:1.3333333; border-radius:4px; }
.form-group-lg select.form-control { height:46px; line-height:46px; }
.form-group-lg select[multiple].form-control,
.form-group-lg textarea.form-control { height:auto; }
.form-group-lg .form-control-static { height:46px; min-height:40px; padding:11px 18px; font-size:18px; line-height:1.3333333; }
.has-feedback { position:relative; }
.has-feedback .form-control { padding-right:45px; }
.form-control-feedback { position:absolute; top:0; right:0; z-index:2; display:block; width:36px; height:36px; line-height:36px; text-align:center; pointer-events:none; }
.form-group-lg .form-control+.form-control-feedback,
.input-group-lg+.form-control-feedback,
.input-lg+.form-control-feedback { width:46px; height:46px; line-height:46px; }
.form-group-sm .form-control+.form-control-feedback,
.input-group-sm+.form-control-feedback,
.input-sm+.form-control-feedback { width:32px; height:32px; line-height:32px; }
.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label { color:#fff; }
.has-success .form-control { border-color:#fff; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075); }
.has-success .form-control:focus { border-color:#e6e6e6; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff; }
.has-success .input-group-addon { color:#fff; background-color:#46be8a; border-color:#fff; }
.has-success .form-control-feedback { color:#fff; }
.has-success .form-control { box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05); }
.has-success .form-control:focus { border-color:#fff; outline:0; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6); }
.has-success .form-control.focus,
.has-success .form-control:focus { border-color:#fff; box-shadow:none; }
.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label { color:#fff; }
.has-warning .form-control { border-color:#fff; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075); }
.has-warning .form-control:focus { border-color:#e6e6e6; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff; }
.has-warning .input-group-addon { color:#fff; background-color:#f2a654; border-color:#fff; }
.has-warning .form-control-feedback { color:#fff; }
.has-warning .form-control { box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05); }
.has-warning .form-control:focus { border-color:#fff; outline:0; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6); }
.has-warning .form-control.focus,
.has-warning .form-control:focus { border-color:#fff; box-shadow:none; }
.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label { color:#fff; }
.has-error .form-control { border-color:#fff; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075); }
.has-error .form-control:focus { border-color:#e6e6e6; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fff; }
.has-error .input-group-addon { color:#fff; background-color: var(--color--red); border-color:#fff; }
.has-error .form-control-feedback { color:#fff; }
.has-error .form-control { box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05); }
.has-error .form-control:focus { border-color:#fff; outline:0; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 255, 255, .6); }
.has-error .form-control.focus,
.has-error .form-control:focus { border-color:#fff; box-shadow:none; }
.has-feedback label~.form-control-feedback { top:27px; }
.has-feedback label.sr-only~.form-control-feedback { top:0; }
.help-block { display:block; color:#bcc2c8; font-size:10px; }
@media (min-width:768px) {.form-inline .form-group { display:inline-block; margin-bottom:0; vertical-align:middle; }
  .form-inline .form-control { display:inline-block; width:auto; vertical-align:middle; }
  .form-inline .form-control-static { display:inline-block; }
  .form-inline .input-group { display:inline-table; vertical-align:middle; }
  .form-inline .input-group .form-control,
.form-inline .input-group .input-group-addon,
.form-inline .input-group .input-group-btn { width:auto; }
  .form-inline .input-group>.form-control { width:100%}
  .form-inline .control-label { margin-bottom:0; vertical-align:middle; }
  .form-inline .checkbox,
.form-inline .radio { display:inline-block; margin-top:0; margin-bottom:0; vertical-align:middle; }
  .form-inline .checkbox label,
.form-inline .radio label { padding-left:0; }
  .form-inline .checkbox input[type=checkbox],
.form-inline .radio input[type=radio] { position:relative; margin-left:0; }
  .form-inline .has-feedback .form-control-feedback { top:0; }
}
.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline { padding-top:7px; margin-top:0; margin-bottom:0; }
.form-horizontal .checkbox,
.form-horizontal .radio { min-height:29px; }
.form-horizontal .form-group { margin-right:-15px; margin-left:-15px; }
.form-horizontal .has-feedback .form-control-feedback { right:15px; }


input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block { width:100%}

[data-toggle=buttons]>.btn input[type=checkbox], [data-toggle=buttons]>.btn input[type=radio], [data-toggle=buttons]>.btn-group>.btn input[type=checkbox], [data-toggle=buttons]>.btn-group>.btn input[type=radio] { position:absolute; clip:rect(0, 0, 0, 0); pointer-events:none; }
.input-group { position:relative; display:table; border-collapse:separate; }
.input-group[class*=col-] { float:none; padding-right:0; padding-left:0; }
.input-group .form-control { position:relative; z-index:2; float:left; width:100%; margin-bottom:0; }
.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn { height:46px; padding:10px 18px; font-size:18px; line-height:1.3333333; border-radius:4px; }
select.input-group-lg>.form-control,
select.input-group-lg>.input-group-addon,
select.input-group-lg>.input-group-btn>.btn { height:46px; line-height:46px; }
select[multiple].input-group-lg>.form-control,
select[multiple].input-group-lg>.input-group-addon,
select[multiple].input-group-lg>.input-group-btn>.btn,
textarea.input-group-lg>.form-control,
textarea.input-group-lg>.input-group-addon,
textarea.input-group-lg>.input-group-btn>.btn { height:auto; }
select.input-group-lg>.form-control,
select.input-group-lg>.input-group-addon,
select.input-group-lg>.input-group-btn>.btn { padding-top:0; padding-bottom:0; }
.input-group-sm>.form-control,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.btn { height:32px; padding:6px 13px; font-size:12px; line-height:1.5; border-radius:2px; }
select.input-group-sm>.form-control,
select.input-group-sm>.input-group-addon,
select.input-group-sm>.input-group-btn>.btn { height:32px; line-height:32px; }
select[multiple].input-group-sm>.form-control,
select[multiple].input-group-sm>.input-group-addon,
select[multiple].input-group-sm>.input-group-btn>.btn,
textarea.input-group-sm>.form-control,
textarea.input-group-sm>.input-group-addon,
textarea.input-group-sm>.input-group-btn>.btn { height:auto; }
select.input-group-sm>.form-control,
select.input-group-sm>.input-group-addon,
select.input-group-sm>.input-group-btn>.btn { padding-top:0; padding-bottom:0; }
.input-group .form-control,
.input-group-addon,
.input-group-btn { display:table-cell; }
.input-group .form-control:not(:first-child):not(:last-child),
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child) { border-radius:0; }
.input-group-addon,
.input-group-btn { width:1%; white-space:nowrap; vertical-align:middle; }
.input-group-addon { padding:6px 15px; font-size:14px; font-weight:400; line-height:1; color:#76838f; text-align:center; background-color:#f3f7f9; border:1px solid var(--color--form-gray); border-radius:3px; }
.input-group-addon.input-sm { padding:6px 13px; font-size:12px; border-radius:2px; }
.input-group-addon.input-lg { padding:10px 18px; font-size:18px; border-radius:4px; }
.input-group-addon input[type=checkbox],
.input-group-addon input[type=radio] { margin-top:0; }
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group>.btn,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn-group:not(:last-child)>.btn,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) { border-top-right-radius:0; border-bottom-right-radius:0; }
.input-group-addon:first-child { border-right:0; }
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:first-child>.btn-group:not(:first-child)>.btn,
.input-group-btn:first-child>.btn:not(:first-child),
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group>.btn,
.input-group-btn:last-child>.dropdown-toggle { border-top-left-radius:0; border-bottom-left-radius:0; }
.input-group-addon:last-child { border-left:0; }
.input-group-btn { position:relative; font-size:0; white-space:nowrap; }
.input-group-btn>.btn { position:relative; }
.input-group-btn>.btn+.btn { margin-left:-1px; }
.input-group-btn>.btn:active,
.input-group-btn>.btn:focus,
.input-group-btn>.btn:hover { z-index:2; }
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group { margin-right:-1px; }
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group { z-index:2; margin-left:-1px; }

/* Extras */
.checkbox-custom,
.radio-custom { position:relative; display:block; margin-top:10px; margin-bottom:10px; }
.checkbox-custom label,
.radio-custom label { min-height:22px; margin-bottom:0; font-weight:300; cursor:pointer; }
.checkbox-custom input[type=checkbox],
.radio-custom input[type=radio] { position:absolute; margin-top:0; margin-bottom:0; margin-left:-20px; }
.checkbox-custom.disabled label,
.radio-custom.disabled label,
fieldset[disabled] .checkbox-custom label,
fieldset[disabled] .radio-custom label { cursor:not-allowed; }
.input-group-addon .checkbox-custom,
.input-group-addon .radio-custom { margin:0; }
.checkbox-custom { padding-left:20px; }
.checkbox-custom label { position:relative; display:inline-block; padding-left:10px; vertical-align:middle; }
.checkbox-custom label:empty { padding-left:0; }
.checkbox-custom label::before { position:absolute; left:0; display:inline-block; width:20px; height:20px; margin-left:-20px; content:""; background-color:#fff; border:1px solid var(--color--form-gray); border-radius:3px; }
.checkbox-custom label::after { position:absolute; top:0; left:0; display:inline-block; width:20px; height:20px; padding-top:1px; margin-left:-20px; font-size:12px; line-height:20px; color:#76838f; text-align:center; }
.checkbox-custom input[type=radio],
.checkbox-custom input[type=checkbox] { z-index:1; width:20px; height:20px; opacity:0; }
.checkbox-custom input[type=radio]:focus+label::before,
.checkbox-custom input[type=checkbox]:focus+label::before { outline:thin dotted; outline:5px auto -webkit-focus-ring-color; outline-offset:-2px; }
.checkbox-custom input[type=radio]:checked+label::before,
.checkbox-custom input[type=checkbox]:checked+label::before { border-color:var(--color--form-gray); border-width:10px; transition:all 300ms ease-in-out 0s; }
.checkbox-custom input[type=radio]:checked+label::after,
.checkbox-custom input[type=checkbox]:checked+label::after { font-family:"FontAwesome"; content:"\f00c"}
.checkbox-custom input[type=radio]:disabled+label,
.checkbox-custom input[type=checkbox]:disabled+label { opacity:.65; }
.checkbox-custom input[type=radio]:disabled+label::before,
.checkbox-custom input[type=checkbox]:disabled+label::before { cursor:not-allowed; background-color:#f3f7f9; border-color:var(--color--form-gray); border-width:1px; }
.checkbox-custom.checkbox-circle label::before { border-radius:50%}
.checkbox-custom.checkbox-inline { display:inline-block; margin-top:0; margin-bottom:0; }
.checkbox-inline+.checkbox-inline { margin-left:20px; }
.checkbox-default input[type=radio]:checked+label::before,
.checkbox-default input[type=checkbox]:checked+label::before { background-color:#fff; border-color:var(--color--form-gray); border-width:1px; }
.checkbox-default input[type=radio]:checked+label::after,
.checkbox-default input[type=checkbox]:checked+label::after { color:var(--color--black); }
.checkbox-primary input[type=radio]:checked+label::before,
.checkbox-primary input[type=checkbox]:checked+label::before { background-color:var(--color--black); border-color:var(--color--black);}
.checkbox-primary input[type=radio]:checked+label::after,
.checkbox-primary input[type=checkbox]:checked+label::after { color:#fff; }
.checkbox-danger input[type=radio]:checked+label::before,
.checkbox-danger input[type=checkbox]:checked+label::before { background-color: var(--color--red); border-color: var(--color--red); }
.checkbox-danger input[type=radio]:checked+label::after,
.checkbox-danger input[type=checkbox]:checked+label::after { color:#fff; }
.checkbox-info input[type=radio]:checked+label::before,
.checkbox-info input[type=checkbox]:checked+label::before { background-color:#57c7d4; border-color:#57c7d4; }
.checkbox-info input[type=radio]:checked+label::after,
.checkbox-info input[type=checkbox]:checked+label::after { color:#fff; }
.checkbox-warning input[type=radio]:checked+label::before,
.checkbox-warning input[type=checkbox]:checked+label::before { background-color:#f2a654; border-color:#f2a654; }
.checkbox-warning input[type=radio]:checked+label::after,
.checkbox-warning input[type=checkbox]:checked+label::after { color:#fff; }
.checkbox-success input[type=radio]:checked+label::before,
.checkbox-success input[type=checkbox]:checked+label::before { background-color:#46be8a; border-color:#46be8a; }
.checkbox-success input[type=radio]:checked+label::after,
.checkbox-success input[type=checkbox]:checked+label::after { color:#fff; }
.checkbox-sm { padding-left:18px; }
.checkbox-sm label { padding-left:8px; }
.checkbox-sm label:empty { padding-left:0; }
.checkbox-sm label::after,
.checkbox-sm label::before { width:18px; height:18px; margin-left:-18px; }
.checkbox-sm label::after { font-size:10px; line-height:18px; }
.checkbox-sm input[type=radio],
.checkbox-sm input[type=checkbox] { width:18px; height:18px; }
.checkbox-sm input[type=radio]:checked+label::before,
.checkbox-sm input[type=checkbox]:checked+label::before { border-width:9px; }
.checkbox-lg { padding-left:24px; }
.checkbox-lg label { padding-left:12px; }
.checkbox-lg label:empty { padding-left:0; }
.checkbox-lg label::after,
.checkbox-lg label::before { width:24px; height:24px; margin-left:-24px; }
.checkbox-lg label::after { font-size:14px; line-height:24px; }
.checkbox-lg input[type=radio],
.checkbox-lg input[type=checkbox] { width:24px; height:24px; }
.checkbox-lg input[type=radio]:checked+label::before,
.checkbox-lg input[type=checkbox]:checked+label::before { border-width:12px; }
.radio-custom { padding-left:20px; }
.radio-custom label { position:relative; display:inline-block; padding-left:10px; vertical-align:middle; }
.radio-custom label:empty { padding-left:0; }
.radio-custom label::before { position:absolute; left:0; display:inline-block; width:20px; height:20px; margin-left:-20px; content:""; background-color:#fff; border:1px solid var(--color--form-gray); border-radius:50%; transition:border 300ms ease-in-out 0s,  color 300ms ease-in-out 0s; }
.radio-custom label::after { position:absolute; top:7px; left:7px; display:inline-block; width:6px; height:6px; margin-left:-20px; content:" "; background-color:transparent; border:2px solid #76838f; border-radius:50%; transition:transform .1s cubic-bezier(.8, -.33, .2, 1.33); -webkit-transform:scale(0, 0); -ms-transform:scale(0, 0); -o-transform:scale(0, 0); transform:scale(0, 0); }
.radio-custom input[type=radio] { z-index:1; width:20px; height:20px; opacity:0; }
.radio-custom input[type=radio]:focus+label::before { outline:thin dotted; outline:5px auto -webkit-focus-ring-color; outline-offset:-2px; }
.radio-custom input[type=radio]:checked+label::before { border-color:var(--color--form-gray); border-width:10px; }
.radio-custom input[type=radio]:checked+label::after { -webkit-transform:scale(1, 1); -ms-transform:scale(1, 1); -o-transform:scale(1, 1); transform:scale(1, 1); }
.radio-custom input[type=radio]:disabled+label { opacity:.65; }
.radio-custom input[type=radio]:disabled+label::before { cursor:not-allowed; }
.radio-custom.radio-inline { display:inline-block; margin-top:0; margin-bottom:0; }
.radio-inline+.radio-inline { margin-left:20px; }
.radio-default input[type=radio]:checked+label::before { background-color:#fff; border-color:var(--color--form-gray); border-width:1px; }
.radio-default input[type=radio]:checked+label::after { border-color:var(--color--black); }
.radio-primary input[type=radio]:checked+label::before { border-color:var(--color--black); outline: none; }
.radio-primary input[type=radio]:checked+label::after { border-color:#fff; }
.radio-danger input[type=radio]:checked+label::before { border-color: var(--color--red); }
.radio-danger input[type=radio]:checked+label::after { border-color:#fff; }
.radio-info input[type=radio]:checked+label::before { border-color:#57c7d4; }
.radio-info input[type=radio]:checked+label::after { border-color:#fff; }
.radio-warning input[type=radio]:checked+label::before { border-color:#f2a654; }
.radio-warning input[type=radio]:checked+label::after { border-color:#fff; }
.radio-success input[type=radio]:checked+label::before { border-color:#46be8a; }
.radio-success input[type=radio]:checked+label::after { border-color:#fff; }
.radio-sm { padding-left:18px; }
.radio-sm label { padding-left:8px; }
.radio-sm label:empty { padding-left:0; }
.radio-sm label::before { width:18px; height:18px; margin-left:-20px; }
.radio-sm label::after { top:7px; left:7px; width:4px; height:4px; margin-left:-20px; border-width:2px; }
.radio-sm input[type=radio] { width:18px; height:18px; }
.radio-sm input[type=radio]:checked+label::before { border-width:9px; }
.radio-lg { padding-left:24px; }
.radio-lg label { padding-left:12px; }
.radio-lg label:empty { padding-left:0; }
.radio-lg label::before { width:24px; height:24px; margin-left:-20px; }
.radio-lg label::after { top:8px; left:8px; width:8px; height:8px; margin-left:-20px; border-width:2px; }
.radio-lg input[type=radio] { width:24px; height:24px; }
.radio-lg input[type=radio]:checked+label::before { border-width:12px; }
.form-horizontal .checkbox-custom,
.form-horizontal .radio-custom { padding-top:7px; margin-top:0; margin-bottom:0; }
.form-horizontal .checkbox-custom,
.form-horizontal .radio-custom { min-height:29px; }
.form-material { position:relative; }
.form-material.floating { margin-top:20px; margin-bottom:20px; }
.form-material.floating+.form-material.floating { margin-top:40px; }
.form-material .form-control { height: 50px; padding:5px 20px; background-color:rgba(0, 0, 0, 0); background-repeat:no-repeat; background-position:center bottom,  center calc(100% - 1px); background-size:0 2px, 100% 1px; transition:background 0s ease-out; background: white;}
.form-material textarea.form-control { height: auto; padding: 20px !important; }
.form-material .form-control::-webkit-input-placeholder { color:#a3afb7; }
.form-material .form-control::-moz-placeholder { color:#a3afb7; }
.form-material .form-control:-ms-input-placeholder { color:#a3afb7; }
.form-material .form-control:disabled::-webkit-input-placeholder { color:#ccd5db; }
.form-material .form-control:disabled::-moz-placeholder { color:#ccd5db; }
.form-material .form-control:disabled:-ms-input-placeholder { color:#ccd5db; }
.form-material .form-control.focus,
.form-material .form-control:focus { background-size:100% 2px, 100% 1px; outline:0; transition-duration:.3s; }
.form-material .form-control:disabled,
.form-material .form-control[disabled],
fieldset[disabled] .form-material .form-control { background-color: var(--color--gray); }
.form-material .form-control:disabled~.floating-label,
.form-material .form-control[disabled]~.floating-label,
fieldset[disabled] .form-material .form-control~.floating-label { color:#ccd5db; }
.form-material .control-label { margin-bottom:10px; font-weight:bold; }
.form-material .floating-label { position:absolute; left:0; font-size:14px; color:#76838f; pointer-events:none; transition:.3s ease all; }
.form-material .floating-label.floating-label-static { position:relative; top:auto; display:block; }
.form-material [class*=col-]>.floating-label { left:15px; }
.form-material .form-control~.floating-label { top:8px; font-size:14px; }
.form-material .form-control.focus~.floating-label,
.form-material .form-control:focus~.floating-label,
.form-material .form-control:not(.empty)~.floating-label { top:-11.2px; font-size:11.2px; }
.form-material .form-control:-webkit-autofill~.floating-label { top:-11.2px; font-size:11.2px; }
.form-material .form-control.input-sm~.floating-label { top:6px; font-size:12px; }
.form-material .form-control.input-sm.focus~.floating-label,
.form-material .form-control.input-sm:focus~.floating-label,
.form-material .form-control.input-sm:not(.empty)~.floating-label { top:-9.6px; font-size:9.6px; }
.form-material .form-control.input-sm:-webkit-autofill~.floating-label { top:-9.6px; font-size:9.6px; }
.form-material .form-control.input-lg~.floating-label { top:6px; font-size:18px; }
.form-material .form-control.input-lg.focus~.floating-label,
.form-material .form-control.input-lg:focus~.floating-label,
.form-material .form-control.input-lg:not(.empty)~.floating-label { top:-14.4px; font-size:14.4px; }
.form-material .form-control.input-lg:-webkit-autofill~.floating-label { top:-14.4px; font-size:14.4px; }
.form-material .form-control.focus~.floating-label,
.form-material .form-control:focus~.floating-label,
.form-material .form-control:not(.empty)~.floating-label { font-weight:500; }
.form-material .form-control:-webkit-autofill~.floating-label { font-weight:500; }
.form-material .form-control.focus~.floating-label,
.form-material .form-control:focus~.floating-label { color:var(--color--black); }
.form-material textarea.form-control { padding-bottom:6px; resize:none; }
.form-material.floating textarea.form-control { padding-top:6px; }
.form-material select.form-control { border:0; border-radius:0; }
.form-material:not(.floating) .control-label+select[multiple] { margin-top:5px; }
.form-material .hint { position:absolute; display:none; font-size:80%}
.form-material .form-control.focus~.hint,
.form-material .form-control:focus~.hint { display:block; }
.form-material .form-control.focus:invalid~.floating-label,
.form-material .form-control:not(.empty):invalid~.floating-label { color: var(--color--red); }
.form-material.form-group.has-warning .form-control:not(.empty) { background-size:100% 2px, 100% 1px; }
.form-material.form-group.has-warning .control-label { color:#f2a654; }
.form-material.form-group.has-warning .form-control.focus~.floating-label,
.form-material.form-group.has-warning .form-control:focus~.floating-label,
.form-material.form-group.has-warning .form-control:not(.empty)~.floating-label { color:#f2a654; }
.form-material.form-group.has-warning .form-control:-webkit-autofill~.floating-label { color:#f2a654; }
.form-material.form-group.has-error .form-control.focus,
.form-material.form-group.has-error .form-control:focus,
.form-material.form-group.has-error .form-control:not(.empty) { background-size:100% 2px, 100% 1px; }
.form-material.form-group.has-error .control-label { color: var(--color--red); }
.form-material.form-group.has-error .form-control.focus~.floating-label,
.form-material.form-group.has-error .form-control:focus~.floating-label,
.form-material.form-group.has-error .form-control:not(.empty)~.floating-label { color: var(--color--red); }
.form-material.form-group.has-error .form-control:-webkit-autofill~.floating-label { color: var(--color--red); }
.form-material.form-group.has-success .form-control:not(.empty) { background-size:100% 2px, 100% 1px; }
.form-material.form-group.has-success .control-label { color:#46be8a; }
.form-material.form-group.has-success .form-control.focus~.floating-label,
.form-material.form-group.has-success .form-control:focus~.floating-label,
.form-material.form-group.has-success .form-control:not(.empty)~.floating-label { color:#46be8a; }
.form-material.form-group.has-success .form-control:-webkit-autofill~.floating-label { color:#46be8a; }
.form-material.form-group.has-info .form-control:not(.empty) { background-size:100% 2px, 100% 1px; }
.form-material.form-group.has-info .control-label { color:#57c7d4; }
.form-material.form-group.has-info .form-control.focus~.floating-label,
.form-material.form-group.has-info .form-control:focus~.floating-label,
.form-material.form-group.has-info .form-control:not(.empty)~.floating-label { color:#57c7d4; }
.form-material.form-group.has-info .form-control:-webkit-autofill~.floating-label { color:#57c7d4; }
.form-material .input-group .form-control-wrap { margin-right:5px; margin-left:5px; }
.form-material .input-group .form-control-wrap .form-control { float:none; }
.form-material .input-group .input-group-addon { background:0 0; border:0; }
.form-material .input-group .input-group-btn .btn { margin:0; border-radius:4px; }
.form-material input[type=file] { position:absolute; top:0; right:0; bottom:0; left:0; z-index:100; width:100%; height:100%; opacity:0; }
.form-control-wrap { position:relative; }

/* Extended */
.form-control { box-shadow:none; transition:box-shadow .25s linear,  border .25s linear,  color .25s linear,  background-color .25s linear; }
.form-control:not(select) { -webkit-appearance:none; }
.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label { color:#46be8a; }
.has-success .form-control { border-color:#46be8a; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075); }
.has-success .form-control:focus { border-color:#369b6f; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #91d9ba; }
.has-success .input-group-addon { color:#46be8a; background-color:#fff; border-color:#46be8a; }
.has-success .form-control-feedback { color:#46be8a; }
.has-success .form-control { box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05); }
.has-success .form-control:focus { border-color:#46be8a; outline:0; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(70, 190, 138, .6); }
.has-success .form-control.focus,
.has-success .form-control:focus { border-color:#46be8a; box-shadow:none; }
.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label { color:#f2a654; }
.has-warning .form-control { border-color:#f2a654; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075); }
.has-warning .form-control:focus { border-color:#ee8d25; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #f9d7b3; }
.has-warning .input-group-addon { color:#f2a654; background-color:#fff; border-color:#f2a654; }
.has-warning .form-control-feedback { color:#f2a654; }
.has-warning .form-control { box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05); }
.has-warning .form-control:focus { border-color:#f2a654; outline:0; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(242, 166, 84, .6); }
.has-warning .form-control.focus,
.has-warning .form-control:focus { border-color:#f2a654; box-shadow:none; }
.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label { color: var(--color--red); }
.has-error .form-control { border-color: var(--color--red); box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075); }
.has-error .form-control:focus { border-color: var(--color--red); box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fdcaca; }
.has-error .input-group-addon { color: var(--color--red); background-color:#fff; border-color: var(--color--red); }
.has-error .form-control-feedback { color: var(--color--red); }
.has-error .form-control { box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05); }
.has-error .form-control:focus { border-color: var(--color--red); outline:0; box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(249, 104, 104, .6); }
.has-error .form-control.focus,
.has-error .form-control:focus { border-color: var(--color--red); box-shadow:none; }
.form-group.has-feedback.no-label .form-control-feedback { top:0; }
.form-group.has-feedback.left-feedback .form-control-feedback { right:auto; left:0; }
.form-group.has-feedback.left-feedback .form-control { padding-right:13px; padding-left:50px; }
.form-control.square { border-radius:0; }
.form-control.round { border-radius:200px; }
textarea.form-control.no-resize { resize:none; }
.help-block { margin-top:7px; margin-bottom:8px; }
.help-block>.icon { margin:0 5px; }
.input-search { position:relative; }
.input-search .form-control { border-radius:200px; }
.input-search .input-search-icon { position:absolute; top:50%; z-index:1; width:36px; -webkit-transform:translateY(-50%); -ms-transform:translateY(-50%); -o-transform:translateY(-50%); transform:translateY(-50%); }
.input-search .input-search-icon+.form-control { padding-left:43.5px; }
.input-search .input-search-icon { left:8px; font-size:16px; color:#a3afb7; text-align:center; pointer-events:none; }
.input-search-btn+.form-control { padding-right:50px; }
.input-search-btn { position:absolute; top:2px; left:0; height:100%; padding:0 10px; background:0 0; border:none; border-radius:0 200px 200px 0; }
.input-search-btn>.icon { margin:0 3px; }
.searchWidget .form-group { margin:0; }
.form-inline .form-group { margin-right:20px; }
.form-inline .form-group:last-child { margin-right:0; }
.form-inline .control-label { margin-right:5px; }
