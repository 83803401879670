/*
c-colonSeparatorTable

コロン区切りのテーブルコンポーネントです。

markup:
<table class="c-colonSeparatorTable">
  <tbody>
  <tr class="c-colonSeparatorTable__tr">
    <th class="c-colonSeparatorTable__th">出展者</th>
    <td class="c-colonSeparatorTable__td c-colonSeparatorTable__td--colon">:</td>
    <td class="c-colonSeparatorTable__td">kwt</td>
  </tr>
  <tr class="c-colonSeparatorTable__tr">
    <th class="c-colonSeparatorTable__th">出品日</th>
    <td class="c-colonSeparatorTable__td c-colonSeparatorTable__td--colon">:</td>
    <td class="c-colonSeparatorTable__td">2018-04-23</td>
  </tr>
  <tr class="c-colonSeparatorTable__tr">
    <th class="c-colonSeparatorTable__th">収録時間</th>
    <td class="c-colonSeparatorTable__td c-colonSeparatorTable__td--colon">:</td>
    <td class="c-colonSeparatorTable__td">00:07</td>
  </tr>
  <tr class="c-colonSeparatorTable__tr">
    <th class="c-colonSeparatorTable__th">ファイル形式</th>
    <td class="c-colonSeparatorTable__td c-colonSeparatorTable__td--colon">:</td>
    <td class="c-colonSeparatorTable__td">mp4</td>
  </tr>
  <tr class="c-colonSeparatorTable__tr">
    <th class="c-colonSeparatorTable__th">ファイルサイズ</th>
    <td class="c-colonSeparatorTable__td c-colonSeparatorTable__td--colon">:</td>
    <td class="c-colonSeparatorTable__td">786.43KB</td>
  </tr>
  </tbody>
</table>

Styleguide 24.1.0
 */

.c-colonSeparatorTable {
  width: 100%;
  table-layout: fixed;

  &__th,
  &__td {
    vertical-align: top;
    padding: 8px 0;
  }

  &__th {
    font-weight: normal;
  }

  &__td {
    &--colon {
      width: 40px;
      padding-left: 15px;
    }
  }

  &__count {
    margin-right: 3px;
  }
}
