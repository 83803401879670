.p-devices {
  &__parentTitle {
    font-size: 2.0rem;
    margin-bottom: 10px;
  }

  &__table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;

    &__thead {
      display: table-header-group;
      box-sizing: border-box;
    }

    &__th {
      vertical-align: middle;
      border: 1px solid var(--color--darkgray);
      background: var(--color--gray);
      font-size: 1.4rem;
      font-weight: normal;
      word-wrap: break-word;
      padding: 15px;
    }

    &__td {
      vertical-align: middle;
      font-size: 1.4rem;
      padding: 15px;
      border: 1px solid var(--color--darkgray);
      font-weight: normal;
      word-wrap: break-word;
    }
  }
}
