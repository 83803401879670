/*
c-searchBtn

サーチボタン

markup:
<div></div>

Styleguide 2.3.0
 */

a.c-searchBtn {
  font-size: 2.2rem;
  color: var(--color--darkgray);
}
