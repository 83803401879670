.p-businessPermit {
  &__description {
    margin: 0 auto;
  }

  &__image {
    width: 150px;
    max-width: 150px;
    margin: 0 auto;
  }

  &__paragraph {

    &--caution {
      color: var(--color--red);
      text-align: center;
    }

    &--text {
      color: var(--color--text);
      text-align: center;
    }
  }
}

.p-businessPermitTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 36px 0;

  &__th {
    background-color: var(--color--gray);
    text-align: left;
    word-break: break-all;
    border: 1px solid var(--color--darkgray);
    padding: 15px;
  }

  &__td {
    font-size: 1.4rem;
    text-align: left;
    word-break: break-all;
    border: 1px solid var(--color--darkgray);
    padding: 15px;
  }
}
