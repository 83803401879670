.p-inquiry {
  &__section {
    border-top: solid 1px var(--color--gray);
    padding-top: 40px;
  }

  &__description {
    font-size: 1.6rem;
    margin-bottom: 25px;
  }

  &__form {
    margin-top: 45px;

    &Group {
      margin-bottom: 30px;
    }
  }

  &__submitBtn {
    width: 150px;
    margin-top: 30px;
  }
}
