/*
c-phoneticNav

カナナビゲーションコンポーネントです。
※現在使用していません

markup:
<nav class="c-phoneticNav">
  <ul class="c-phoneticNav__list">
    <li class="c-phoneticNav__item"><a href="#phonetic-a" class="c-phoneticNav__link">あ</a></li>
    <li class="c-phoneticNav__item"><a href="#phonetic-ka" class="c-phoneticNav__link">か</a></li>
    <li class="c-phoneticNav__item"><a href="#phonetic-sa" class="c-phoneticNav__link">さ</a></li>
    <li class="c-phoneticNav__item"><a href="#phonetic-ta" class="c-phoneticNav__link">た</a></li>
    <li class="c-phoneticNav__item"><a href="#phonetic-na" class="c-phoneticNav__link">な</a></li>
    <li class="c-phoneticNav__item"><a href="#phonetic-ha" class="c-phoneticNav__link">は</a></li>
    <li class="c-phoneticNav__item"><a href="#phonetic-ma" class="c-phoneticNav__link">ま</a></li>
    <li class="c-phoneticNav__item"><a href="#phonetic-ya" class="c-phoneticNav__link">や</a></li>
    <li class="c-phoneticNav__item"><a href="#phonetic-ra" class="c-phoneticNav__link">ら</a></li>
    <li class="c-phoneticNav__item"><a href="#phonetic-wa" class="c-phoneticNav__link">わ</a></li>
  </ul>
</nav><!-- /.c-phoneticNav -->

Styleguide 17.7.0
 */

.c-phoneticNav {
  &__list {
    display: flex;
  }

  &__item {
    margin-right: 5px;
  }

  &__link {
    display: block;
    padding: 5px;
  }
}
