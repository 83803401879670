/*
c-paragraph

段落コンポーネントです。

.c-paragraph - 基本スタイル
.c-paragraph--sm - スモールスタイル
.c-paragraph--lg - ラージスタイル

markup:
<p class="c-paragraph {$modifiers}">xxxの無料会員登録方法です。</p>

Styleguide 19.1.0
 */

.c-paragraph {
  &--sm {
    font-size: 1.2rem;
    line-height: 1.8;
  }

  &--lg {
    font-size: 1.6rem;
    line-height: 1.8;
  }

  &--img {
    font-size: 1.6rem;
    line-height: 1.8;
    padding: 25px;
    border: 1px solid #eee;
    border-radius: 5px;
    display: inline-block;
    margin: 10px 0;
    background: #fff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  }
}
