/*
c-headerRegisterNav

ヘッダーレジスターナビゲーションコンポーネントです。

markup:
<nav class="c-headerRegisterNav">
  <ul class="c-headerRegisterNav__list">
    <li class="c-headerRegisterNav__item">
      <a href="/login" class="c-headerRegisterNav__link c-btn c-btn--outline c-btn--radius c-btn--block">ログイン</a>
    </li>
    <li class="c-headerRegisterNav__item">
      <a href="/signup" class="c-headerRegisterNav__link c-btn c-btn--outline c-btn--red c-btn--radius c-btn--block">会員登録</a>
    </li>
  </ul>
</nav><!-- /.c-headerRegisterNav -->

Styleguide 17.5.0
 */

.c-headerRegisterNav {
  &__list {
    display: flex;
  }

  &__item {
    @media (--sm) {
      width: 100px;
    }

    &:first-of-type {
      margin-right: 10px;
    }
  }

  &__link {
    @media (--sm-max) {
      border: none !important;
      padding: 5px 0 5px 5px !important;
    }
  }
}
