/*
c-blogList

ブログリストコンポーネントです。

markup:
<ul class="c-blogList">
  <li class="c-blogList__item">
    <a href="http://g1-test.bgp5.info/article/post/5/" class="c-blogList__link" title="お知らせ02">
      <div class="c-thumbnailCard">
        <div class="c-thumbnailCard__img">
          <img src="http://g1-test.bgp5.info/g1/resources/01/media/notice/01/0/59d1dbb96666d3d8eeca603f230093d9/59d1dbb96666d3d8eeca603f230093d9-5.jpg" alt="お知らせ02">
        </div>
        <div class="c-thumbnailCard__body">
          <time class="c-thumbnailCard__date" datetime="2018-04-06">2018.04.06</time>
          <h3 class="c-thumbnailCard__title">お知らせ02</h3>
        </div>
      </div>
    </a>
  </li>
</ul><!-- /.c-blogList -->

Styleguide 15.1.0
 */

.c-blogList {
  &__item {
    border-bottom: solid 1px var(--color--gray);

    &:first-of-type {
      border-top: solid 1px var(--color--gray);
    }
  }

  &__link {
    display: block;
    padding: 18px 0;
  }

  .c-thumbnailCard__img {
    width: 60px;
    min-width: 60px;
    margin-right: 20px;

    @media (--xs) {
      width: 60px;
      min-width: 60px;
      min-height: 60px;
    }

    img {
      @media (--xs) {
        max-width: 60px;
        max-height: 60px;
      }
    }
  }

  .c-thumbnailCard__title,
  .c-thumbnailCard__description {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &--comment {
    .c-thumbnailCard__img {
      width: 100px;
      min-width: 100px;
      height: 100px;
      margin-right: 20px;

      img {
        max-width: 100px;
        max-height: 100px;
      }
    }
  }
}
