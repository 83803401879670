/*
c-underLineTitle

囲みタイトルコンポーネントです。

markup:
<h2 class="c-underLineTitle">囲みタイトル</h2>

Styleguide 26.4.0
 */

.c-boxSpan {
  &--red {
    font-size: 1.2rem;
    border: 1px solid red;
    border-radius: 3px;
    color: var(--color--red);
    padding: 2px 5px;
    margin: 5px;
  }

  &--redB {
    font-size: 1.2rem;
    border: 1px solid red;
    border-radius: 3px;
    color: var(--color--white);
    background: var(--color--red);
    padding: 2px 5px;
    margin: 5px;
  }

  &--black {
    font-size: 1.2rem;
    border: 1px solid black;
    border-radius: 3px;
    color: var(--color--black);
    padding: 2px 5px;
    margin: 5px;
  }
}
