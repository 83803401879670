.l-detailSidebar {
  width: 360px;
  background-color: var(--color--gray);
  padding: 45px 3% 50px;

  @media (--lg-max) {
    width: 100%;
    min-width: 360px;
    padding: 45px 40px 50px;
  }

  &__title {
    font-size: 2.4rem;
    margin-bottom: 20px;
  }

  &__types {
    margin-bottom: 11px;
  }

  &__price {
    display: flex;
    align-items: flex-end;
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 10px;

    &Icon {
      margin-right: 10px;
    }

    &Num {
      display: inline-block;
      font-size: 3rem;
      line-height: 0.8;
      white-space: nowrap;
      margin-right: 3px;
    }

    &Rate {
      font-size: 1.8rem;
      font-weight: normal;
      margin-bottom: 22px;
    }

    &List {
      font-size: 2.6rem;
      font-weight: normal;
    }
  }

  &__buyBtnForm {
    padding-bottom: 10px;
  }

  &__downloadBtn,
  &__buyBtn {
    height: 52px !important;
    font-size: 2rem;
  }
  &__cartBtn {
    height: 50px;
    width: 25px;
    border-radius: 90px 0 0 90px;
    background: black;
    position: absolute;
    top: 38%;
    right: 0;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    padding-left: 9px;
    border: none;
  }

  &__info {
    &Table {
      th {
        width: 105px;

        @media (--sm) {
          width: 115px;
        }
      }
    }
  }

  &__tags {
    padding-top: 10px;
  }

  &__helperNav {
    &__list {
    }

    &__item {
      margin-bottom: 10px;
    }

    &__link {
      display: block;
      padding: 3px 0;

      i.fa {
        margin-right: 7px;
      }
    }
  }

  &__parchasedText {
    color: var(--color--red);
    font-size: 2rem;
    margin-bottom: 10px;
  }
}
