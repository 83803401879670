/*
c-footerBarNav

フッターバーナビゲーションコンポーネントです。

markup:
<footer id="l-footer" class="l-footer" role="contentinfo">
	<div class="l-footer__bar">
		<div class="l-footer__barInner u-container">
      <nav class="c-footerBarNav">
        <ul class="c-footerBarNav__list">
          <li class="c-footerBarNav__item">
            <a href="/company" class="c-footerBarNav__link">会社概要</a>
          </li>
          <li class="c-footerBarNav__item">
            <a href="/term_of_use" class="c-footerBarNav__link">ご利用規約</a>
          </li>
          <li class="c-footerBarNav__item">
            <a href="/traderule" class="c-footerBarNav__link">特定商取引法に基づく表記</a>
          </li>
          <li class="c-footerBarNav__item">
            <a href="/business_permit" class="c-footerBarNav__link">風営法届出済</a>
          </li>
        </ul>
      </nav><!-- /.c-footerBarNav -->
		</div><!-- /.l-footer__barInner -->
	</div><!-- /.l-footer__bar -->
</footer><!-- /.l-footer -->

Styleguide 17.1.0
 */

.c-footerBarNav {
  &__list {
    @media (--sm-max) {
      margin-bottom: 30px;
    }
    @media (--sm) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    @media (--sm) {
      &:not(:last-of-type) {
        position: relative;
        &::after {
          content: "";
          display: block;
          width: 1px;
          height: 10px;
          position: absolute;
          top: 50%;
          right: 0;
          background-color: #fff;
          transform: translateY(-50%);
        }
      }
    }

    &:first-of-type {
      .l-footer__barNav__link {
        padding-left: 0;
      }
    }
  }

  a&__link {
    color: #fff;
    white-space: nowrap;

    @media (--sm-max) {
      display: block;
      padding: 3px 0;
    }

    @media (--sm) {
      padding: 5px 10px;
    }
  }
}
