/*
c-headerBarNav

ヘッダーバーナビゲーションコンポーネントです。

markup:
<nav class="c-headerBarNav">
  <ul class="c-headerBarNav__list">
    <li class="c-headerBarNav__item">
      <a href="/guide/payment" class="c-headerBarNav__link">購入ガイド</a>
    </li>
    <li class="c-headerBarNav__item">
      <a href="/faq" class="c-headerBarNav__link">よくある質問</a>
    </li>
    <li class="c-headerBarNav__item">
      <a href="/inquiry" class="c-headerBarNav__link">お問い合わせ</a>
    </li>
  </ul>
</nav><!-- /.c-headerBarNav -->

Styleguide 17.4.0
 */


.c-headerBarNav {
  &__list {
    display: flex;
    justify-content: left;
  }

  &__item {
    position: relative;
    &:not(:last-of-type)::after {
      content: "";
      display: block;
      width: 1px;
      height: 14px;
      position: absolute;
      top: 50%;
      right: 0;
      background-color: var(--color--black);
      transform: translateY(-50%);
    }
  }

  a&__link {
    white-space: nowrap;
    padding: 5px 12px;

    @media (--sm-only) {
      font-size: 1.3rem;
    }
  }
}
