.p-videoDetail {
  &_metadata {
    margin-bottom: 5px;
  }
  &_metadataList {
    margin-bottom: 20px;
  }
  &__editer {
    border: none;
    background: var(--color--red);
    color: var(--color--white);
    padding: 5px 8px 3px;
    line-height: 1;
    margin-bottom: 5px;
  }

  &__inner {
    padding: 0 3%;

    @media (--sm) {
      max-width: 960px;
      padding: 0 65px;
      margin: 0 auto;
    }
  }

  &__title {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  &__player {
    margin-bottom: 10px;

    #video__loading__sign {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .video-js {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      .vjs-big-play-button {
        width: 60px;
        height: 70px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none;
        background-color: transparent !important;

        .vjs-icon-placeholder {
          display: block;

          &::before {
            content: "";
            display: block;
            width: 60px;
            height: 70px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(/tpl/g1/img/ja/icon-play.png);
          }
        }
      }
    }
  }

  &__thumbnailNav {
    margin-bottom: 30px;

    @media (--sm) {
      margin-bottom: 20px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      width: calc((100% - (10px * 4)) / 5);
      margin-bottom: 10px;

      &:not(:nth-of-type(5n)) {
        margin-right: 9.9px;
      }
    }

    &__link {
      display: block;
      position: relative;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-color: var(--color--gray);
      padding-top: 56.25%;
      overflow: hidden;
    }

    &__img {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__shareWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  &__shareViews {
    display: flex;

    &__term {
      font-size: 1.8rem;
      font-weight: normal;
      margin-right: 5px;
    }

    &__count {
      font-size: 1.8rem;
    }
  }

  &__shareNav {
    &__list {
      width: 100%;
      flex-grow: 1;
      display: flex;
    }

    &__item {
      &:not(:first-of-type) {
        margin-left: 20px;
      }
    }

    &__link {
      display: inline-block;
      white-space: nowrap;
      padding: 3px 0;
    }
  }

  &__snsNav {
    width: 100%;
    margin-top: 20px;
  }

  &__description {
    max-height: 90px;
    overflow: hidden;
    border-bottom: solid 1px var(--color--gray);
    margin: 15px 0 15px;

    a {
      color: var(--color--red);
    }

    @media (--sm) {
      max-height: 135px;
      margin: 45px 0 15px;
    }

    &[aria-expanded="true"] {
      max-height: inherit;
    }

    &__title {
      margin: 25px 0 20px;
      border: 1px solid var(--color--darkgray);
      display: inline-block;
      padding: 10px 15px;
    }

    &__extra {
      border-bottom: 1px solid var(--color--darkgray);
      padding-bottom: 30px;
      margin-bottom: 50px;
    }
  }

  &__more {
    display: flex;
    justify-content: center;

    &__link {
      display: inline-block;
      border: 2px solid red;
      padding: 5px 10px;
      color: var(--color--red);
      border-radius: 20px;

      &:hover {
        color: var(--color--red);
      }

      &:focus {
        color: var(--color--red);
      }
    }
  }

  &__comment {
    margin-top: 50px;
  }

  &__recentlyArea {
    width: 100%;
    margin: 45px 0 50px;
  }
  &EndItems {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 30px 30px 40px 30px;
    @media (--sm-max) {
      padding: 10px 10px 40px 10px;
    }

    a.c-headerRegisterNav__link.c-btn.c-btn--outline.c-btn--red.c-btn--radius.c-btn--block {
      width: 80%;
      font-size: 1.8rem;
    }
  }
  &EndItem {
    width: 100%;
    height: 38%;
    background-color: rgba(0, 0, 0, 0.5);
    @media (--sm-max) {
      height: 70%;
      margin-bottom: 10px;
    }

    &Del {
      @media (--sm-max) {
        display: none;
      }
    }
    &Box {
      height: 100%;
      display: flex;
      &-l {
        width: 32%;
        height: 100%;
        position: relative;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-color: #eee;
        background-color: var(--color--gray);
        margin-bottom: 8px;
      }
      &-r {
        display: flex;
        flex-direction: column;
        width: 68%;
        padding: 20px;
        overflow: hidden;
        @media (--lg-max) {
          padding: 15px;
        }
        @media (--sm-max) {
          padding: 10px;
        }
        &H4 {
          font-size: 1.6rem;
          line-height: 1.5;
          width: 100%;
          height: 45px;
          text-align: left;
          overflow: hidden;
          color: #fff;

          @media (--lg-max) {
            font-size: 1.4rem;
            line-height: 1.6;
          }
          @media (--sm-max) {
            font-size: 1.4rem;
            line-height: 1.6;
          }
        }
        &Time {
          font-size: 1.6rem;
          width: 100%;
          height: 12px;
          text-align: left;
          margin-top: 15px;
          color: #fff;
          @media (--lg-max) {
            display: none;
          }
        }
        &Yean {
          font-size: 2rem;
          margin-left: auto;
          margin-top: auto;
          color: #fff;
          @media (--lg-max) {
            display: none;
          }
        }
      }
    }
  }
}

/*phpvibe*/
.video-player {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: black;
  text-align: center;
  position: relative;

  @media (--xs-max) {
    min-height: 240px;
  }
}

.vprocessing-kai {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vpre {
  padding-top: 0;
  @media (--xs-max) {
    font-size: 28px;
  }
}

.vex {
  @media (--xs-max) {
    font-size: 20px;
    margin-top: 0;
  }
}

.video-player {
  background: #e3e3e3;
  display: block;
  position: relative;
  margin: 0 !important;
  width: 100%;
  min-height: 194px;
  @media (--xs) {
    height: 194px;
  }
  @media (--sm) {
    height: 387px;
  }
  @media (--md) {
    height: 387px;
  }
  @media (--lg) {
    height: 480px;
  }
  clear: both;
}

.video-player iframe,
.video-player iframe .swfObject,
.video-player iframe ._53j5,
.video-player object,
.video-player embed,
.video-player video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 194px;
  @media (--xs) {
    height: 194px;
  }
  @media (--sm) {
    height: 387px;
  }
  @media (--md) {
    height: 387px;
  }
  @media (--lg) {
    height: 480px;
  }
}

.gritter-without-image {
  height:auto;
  padding:10px;
}

.gritter-item {
  height:auto;
  padding:10px;
}

.gritter-with-image, .gritter-without-image {
  display: inline;
}
