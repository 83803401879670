/*
c-label

ラベルコンポーネントです。

.c-label - 基本スタイル
.c-label--new - 新作スタイル
.c-label--semi - 準新作スタイル
.c-label--outline - アウトラインスタイル
.c-label--sm - サイズSmallスタイル
.c-label--lg - サイズLargeスタイル

markup:
<span class="c-label {$modifiers}">ラベル（{$modifiers}）</span>

Styleguide 13.1.0
 */

.c-label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  background-color: var(--color--black);
  padding: 3px 8px;

  &:not(.c-label--outline) {
    color: #fff;
  }

  &--outline {
    background-color: #fff;
    border: solid 1px var(--color--black);
  }

  &--sm {
    font-size: 1.2rem;
  }

  &--lg {
    font-size: 1.6rem;
  }

  &--new,
  &--free,
  &--red {
    &:not(.c-label--outline) {
      background-color: var(--color--red);
    }

    &.c-label--outline {
      color: var(--color--red);
      border: solid 1px var(--color--red);
    }
  }

  &--monthly {
    &:not(.c-label--outline) {
      background-color: var(--color--darkblue);
    }

    &.c-label--outline {
      color: var(--color--darkblue);
      border: solid 1px var(--color--darkblue);
    }
  }
}
