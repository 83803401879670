/*
c-blogPost

ブログ記事コンポーネントです。

markup:
<article class="c-blogPost">
  <header class="c-blogPost__header">
    <time class="c-blogPost__date" datetime="2018-04-05">2018.04.05</time>
    <h3 class="c-blogPost__title">動画をアップしました</h3>
  </header>
  <div class="c-blogPost__body">
    <div class="c-blogPost__img">
      <img src="http://g1-test.bgp5.info/g1/resources/01/media/notice/01/01/eweeeefwefwef/thumb.jpg" alt="動画をアップしました">
    </div><!-- /.c-blogPost__img -->
    <div class="c-blogPost__description">
      <p>新作動画をアップロードしました。</p>
    </div>
  </div><!-- /.p-blogDetail__body -->
</article><!-- /.c-blogPost -->

Styleguide 21.1.0
 */

.c-blogPost {
  margin-bottom: 45px;

  &__header {
    border-top: solid 1px var(--color--gray);
    border-bottom: solid 1px var(--color--gray);
    padding: 30px 0;
    margin-bottom: 35px;
  }

  &__date {
    display: block;
    font-size: 1.3rem;
    margin-bottom: 10px;
  }

  &__title {
    font-size: 2.4rem;
  }

  &__body {}

  &__img {
    margin-bottom: 30px;
  }

  &__description {}
}
