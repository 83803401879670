.c-404 {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;

  &__title {
    font-size: 20rem;
    line-height: 222px;

    @media (--sm-max) {
      font-size:15rem;
      line-height: 175px;
    }

    &--red {
      color: var(--color--red);
    }

    &--gray {
      color: var(--color--darkgray);
    }

    &--darkgray {
      color: var(--color--bllack);
    }
  }

  &__discription {
    @media (--sm-max) {
      font-size: 1.4rem;
    }

    font-size: 1.6rem;
    margin-bottom: 30px;
  }
}
