.l-globalNav {
  background: var(--color--black);

  &__list {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item {
    padding: 5px 10px;

    @media (max-width: 320px) {
      padding: 5px;
    }

    &:last-of-type {
      @media (--sm-max) {
        /*display: none;*/
      }
    }

    &--deleat {
      display: none;

      @media (--sm) {
        display: block;
      }
    }

    a i {
      margin-right: 1px;
    }
  }

  a&__link {
    color: #fff;
    white-space: nowrap;
  }
}
