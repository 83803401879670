/*
c-textLink

テキストリンクコンポーネントです。

.c-textLink - 基本スタイル
.c-textLink--blue - カラーブルースタイル

markup:
<a href="#" class="c-textLink {$modifiers}">もっと見る（{$modifiers}）</a>

Styleguide 14.1.0
 */

a.c-textLink {
  &--blue {
    color: var(--color--blue);
  }
}

