.p-notice {
  &__select {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  &__items {
    display: flex;
    width: calc(20% - 6px);
    background: var(--color--white);
    border: 1px solid var(--color--gray);
    margin: 0 5px 5px 0;

    @media (--md-max) {
      width: calc(25% - 6px);
    }

    @media (--md-max) {
      width: calc(33.3333% - 6px);
    }

    @media (--xs-max) {
      width: calc(50% - 6px);
    }

    &--all {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(20% - 6px);
      background: var(--color--white);
      border: 1px solid var(--color--gray);
      margin: 0 5px 5px 0;

      @media (--md-max) {
        width: calc(25% - 6px);
      }

      @media (--md-max) {
        width: calc(33.3333% - 6px);
      }

      @media (--xs-max) {
        width: calc(50% - 6px);
      }

      &.active {
        background: var(--color--gray);
      }
    }

    &.active {
      background: var(--color--gray);
    }

    &__box {
      display: flex;
      width: 100%;

      &--left {
        flex: 0 0 45px;
        width: 45px;
        height: 45px;
      }

      &--right {
        display: flex;
        align-items: center;
        padding: 0 5px;
        overflow: hidden;
        font-size: 1.2rem;
        line-height: 1.2;
        height: 45px;
        width: 100%;
        flex: 0 0 155x;
      }
    }
  }
}
.c-thumbnailCard > .p-notice__link {
  width: 90px;

  @media (--sm-max) {
    width: 105px;
  }
}
