/* globalDrawer start */
.l-header,
.l-globalNav,
.l-wrapper,
.l-footer {
  transform: translateX(0);
  transition: all 0.3s ease-in-out;
}

.l-globalDrawer {
  transform: translateX(-300px);
  transition: all 0.3s ease-in-out;
}
/* globalDrawer end */

/* headerSearch start */
.l-header__searchWrapper--sp {
  display: none;
}
/* headerSearch end */

body.is-opened {
  &--globalDrawer {
    overflow: hidden;

    .l-header,
    .l-globalNav,
    .l-wrapper,
    .l-footer {
      transform: translateX(300px);
    }

    .l-globalDrawer {
      transform: translateX(0);
    }

    .l-overlay {
      opacity: 1;
      visibility: visible;
    }

    /*
	.c-hamburger__bar {
	  transform: translateY(0) rotate(360deg);
	  &:first-of-type { transform: translateY(3px) rotate(-45deg); }
	  &:nth-of-type(2) { opacity: 0; }
	  &:last-of-type { transform: translateY(-3px) rotate(45deg); }
	}
	*/
  }

  &--headerSearch {
    .l-header__searchWrapper--sp {
      @media (--sm-max) {
        display: flex;
      }
    }
  }
}

