/*
c-progressNav

決済ページなどの進捗用ナビコンポーネントです。

markup:
<nav class="c-progressNav">
  <ul class="c-progressNav__list">
    <li class="c-progressNav__item c-progressNav__item--current">決済情報の入力・確認</li>
    <li class="c-progressNav__item">完了</li>
  </ul>
</nav>

Styleguide 17.8.0
 */

.c-progressNav {
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item {
    &--current {
      color: var(--color--red);
    }

    &:not(:first-of-type) {
      display: flex;
      align-items: center;

      &::before {
        content: "";
        width: 30px;
        height: 1px;
        background-color: var(--color--black);
        margin: 0 10px;
      }
    }
  }
}

