.p-guide {
  &__parentTitle {
    font-size: 2rem;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color--darkgray);
    margin-bottom: 25px;
  }

  &__childTitle {
    font-size: 1.6rem;
    padding-bottom: 20px;
    font-weight: 600;
  }

  &__section {
    padding-top: 10px;
    margin-bottom: 125px;
  }

  &__hoge {
    display: block;
  }

  &__imageWapper {
    margin-bottom: 70px;

    &__image {
    }
  }

  &__anchorList {
    font-size: 1.6rem;
    border-bottom: 1px solid var(--color--darkgray);
    list-style-type: disc;
    padding: 30px 0 30px 20px;
    margin-bottom: 40px;

    &__item {
    }

    &--min {
      font-size: 1.4rem;
      list-style-type: disc;
      padding: 0 0 0 20px;
    }
  }
}
.guideAffi_tab_{
    &cnt {
        margin-bottom: 50px;
        display: none;
        padding: 30px 30px 0px;
        border:1px solid #ddd;
        background-color: #f4f4f4;
    }
    &cnt_show {
        display: block;
    }
}
#guideAffi_tabTitle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: -1px;
    li{
        color: #043c78;
        margin: 0;
        list-style: none;
        -webkit-transition: .3s;
        transition: .3s;
        border-radius: 5px 5px 0 0;
        -webkit-border-radius: 5px 5px 0 0;
        -moz-border-radius: 5px 5px 0 0;
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-bottom: none;
        span{
            padding: 10px 20px 8px;
            cursor: pointer;
            display: block;
            -webkit-transition: opacity .3s linear;
            transition: opacity .3s linear;
            border-radius: 5px 5px 0 0;
            -webkit-border-radius: 5px 5px 0 0;
            -moz-border-radius: 5px 5px 0 0;
            &:hover{
                @media (--sm) {
                    opacity: 0.7;
                }
            }
        }

    }
}
.guideAffi_tabTitle_active{
    color: #fff;
    cursor: auto;
    color: var(--color--red)!important;
    border-top: 1px solid #ddd !important;
    border-left: 1px solid #ddd !important;
    border-right: 1px solid #ddd !important;
    background-color: #f4f4f4;

}
