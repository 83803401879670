.p-mailmg {
  &-container {
    margin: 0 12px;
  }
  &__signup {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 30px;
  }
  &__changeEmail {
    max-width: 640px;
    border: 1px solid var(--color--gray);
    padding: 16px;
    margin: 32px auto;
    overflow: hidden;
    &--text {
      text-align: center;
    }
    &--btnCont {
      text-align: center;
    }
  }
}
