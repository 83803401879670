/*
c-authorCard

出演者専用サムネイルカードコンポーネントです。

markup:
<div class="c-authorCard">
  <a href="/author/2" class="c-authorCard__link">
    <div class="c-authorCard__img" style="background-image: url(http://g1-test.bgp5.info/g1/resources/01/media/author/02/2/e653699c51f4a41e5db1fb9606fe632f-3//e653699c51f4a41e5db1fb9606fe632f-3.jpg)">
    </div>
    <div class="c-authorCard__body">
      <div class="c-authorCard__maker">a田B美</div>
      <div class="c-authorCard__counter">出展数 26本</div>
    </div>
  </a>
</div><!-- /.c-authorCard -->

Styleguide 3.1.0
 */

.c-authorCard {
  &__link {
    display: block;
    &--side {
      margin: 15px 15px -8px;
      display: block;
    }
  }

  &__img {
    width: 100%;
    position: relative;
    background-color: var(--color--gray);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-bottom: 5px;

    &::before {
      content: "";
      width: 100%;
      display: block;
      padding-top: 100%;
    }
  }

  &__body {
  }

  &__maker {
  }

  &__counter {
    font-size: 1.3rem;
    color: var(--color--text-gray);
  }
}
