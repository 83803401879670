/*
c-headerNoticeNav

フッターお知らせナビゲーションコンポーネントです。

markup:
<nav class="c-footerNoticeNav">
  <h2 class="c-footerNoticeNav__sectionTitle">お知らせ</h2>
  <div class="c-footerNoticeNav__scrollView">
    <ul class="c-footerNoticeNav__list">
      <li class="c-footerNoticeNav__item">
        <a href="http://g1-test.bgp5.info/article/home/13" class="c-footerNoticeNav__link">
          <time class="c-footerNoticeNav__date" datetime=">2018-04-06">2018/04/06</time>
          <span class="c-footerNoticeNav__title">お知らせ08</span>
        </a>
      </li>
    </ul>
  </div><!-- /.c-footerNoticeNav__scrollView -->
</nav><!-- /.c-footerNoticeNav -->

Styleguide 17.3.0
 */

.c-footerNoticeNav {
  &__areaTitle {
    font-size: 1.6rem;
    margin-right: 20px;

    &Wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }

    &__link {
      font-size: 1.2rem;

      i.fa {
        margin-right: 5px;
      }
    }
  }

  &__scrollView {
    height: 200px;
    background-color: #fff;
    overflow-y: scroll;
    padding: 15px 20px 5px;
  }

  &__list {
  }

  &__item {
    margin-bottom: 10px;
  }

  &__link {
    display: block;
    padding: 3px 0;

    &__box {
      display: flex;
    }
  }

  &__date {
    display: block;
    font-size: 1.2rem;
    color: var(--color--red);
    margin-right: 10px;
  }

  &__tag {
    background-color: var(--color--black);
    color: var(--color--white);
    font-size: 1rem;
    padding: 0px 5px 0;
    border-radius: 3px;

    &--info {
      background-color: var(--color--black);
      color: var(--color--white);
      font-size: 1rem;
      padding: 0px 5px 0;
      border-radius: 3px;
      margin-bottom: 10px;
      display: inline-block;
    }
  }
}
