/*
c-btn

汎用的なボタン

.c-btn - 基本スタイル
.c-btn--outline - アウトラインスタイル
.c-btn--red - レッドスタイル
.c-btn--radius - 角丸スタイル
.c-btn--lg - 高さLargeスタイル
.c-btn--block - 角丸スタイル
.c-btn--outline.c-btn--red.c-btn--radius - 組み合わせ例

markup:
<a href="#" class="c-btn {$modifiers}">ボタン（{$modifiers}）</a>

Styleguide 2.1.0
*/

a.c-btn,
button.c-btn {
  height: 40px;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color--black);
  border: none;
  padding: 0 10px;

  &Top {
    width: 40px;
    height: 40px;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color--black);
    border: none;
    padding: 0 10px;
  }

  &--outline {
    background-color: #fff;
    border: solid 1px var(--color--black);
  }

  &:not(.c-btn--outline) {
    color: #fff;
  }

  &--block {
    display: flex;
    width: 100%;
  }

  &--w27 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
  }

  &--radius {
    border-radius: 3px;

    &Top {
      border-radius: 50%;
    }

    &12 {
      border-radius: 12px;
    }
  }

  &--lg {
    height: 50px;

    &.c-btn--radius {
      border-radius: 7px;
    }
  }

  &--red {
    &:not(.c-btn--outline) {
      background-color: var(--color--red);
    }

    &.c-btn--outline {
      border: solid 1px var(--color--red);
      color: var(--color--red);
    }
  }

  &--white {
    &:not(.c-btn--outline) {
      background-color: var(--color--white);
    }

    &.c-btn--outline {
      border: solid 2px var(--color--black);
      color: var(--color--black);
    }
  }

  &--crimsonRed {
    &:not(.c-btn--outline) {
      background-color: var(--color--crimsonRed);
    }

    &.c-btn--outline {
      border: solid 1px var(--color--crimsonRed);
      color: var(--color--crimsonRed);
    }
  }

  &--facebook {
    &:not(.c-btn--outline) {
      background-color: var(--color--facebook-blue);
    }

    &.c-btn--outline {
      border: solid 1px var(--color--facebook-blue);
      color: var(--color--facebook-blue);
    }

    i.fab,
    svg {
      font-size: 1.6rem;
      margin-right: 7px;
    }
  }

  &--fc2 {
    &:not(.c-btn--outline) {
      background-color: var(--color--white);
      color: var(--color--fc2-red);
      border: solid 2px var(--color--darkgray);
      text-align: center;
    }

    &.c-btn--outline {
      border: solid 2px var(--color--darkgray);
      color: var(--color--fc2-red);
    }

    i.fi-fc2,
    svg {
      font-size: 1.6rem;
      margin-right: 7px;
    }
  }

  &--twitter {
    &:not(.c-btn--outline) {
      background-color: var(--color--twitter-blue);
    }

    &.c-btn--outline {
      border: solid 1px var(--color--twitter-blue);
      color: var(--color--twitter-blue);
    }

    i.fab,
    svg {
      font-size: 1.6rem;
      margin-right: 7px;
    }
  }

  &--googleplus {
    &:not(.c-btn--outline) {
      background-color: var(--color--googleplus-red);
    }

    &.c-btn--outline {
      border: solid 1px var(--color--googleplus-red);
      color: var(--color--googleplus-red);
    }

    i.fab,
    svg {
      font-size: 1.6rem;
      margin-right: 7px;
    }
  }
  &--cartDel {
    color: var(--color--white);
    width: 25px;
    height: 50px;
    border-radius: 90px 0 0 90px;
    position: absolute;
    top: 38%;
    right: 0;
    border: none;
    font-size: 1rem;
    padding-left: 10px;
    background: var(--color--black);
  }
}
