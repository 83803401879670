/*
c-categoryTitle

タイトルコンポーネントです。

markup:
<h2 class="c-categoryTitle"><i class="fa fa-list-ul"></i>カテゴリタイトル</h2>

Styleguide 26.1.0
 */

.c-categoryTitle {
  font-size: 2.4rem;

  @media (--sm) {
    font-size: 2.8rem;
  }

  i.fa {
    margin-right: 10px;
  }
}
