.u-dn {
  &--sp {
    display: none;

    @media (--sm) {
      display: block;
    }
  }

  &--pc {
    display: block;

    @media (--sm) {
      display: none;
    }
  }

  &--tab {
    display: none;
    @media (--sm-only) {
      display: block;
    }
  }
}
