/*
c-sortBtn

ユーザーメニューボタンコンポーネントです。

markup:


Styleguide 2.5.0
 */

.c-sortBtn {
  &__item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  &__items {
    margin-right: 5px;
    margin-bottom: 10px;
  }
  &__link {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: inline-block;
  }
  &__link:hover {
    background: var(--color--gray);
    padding: 5px 10px;
    border: 1px solid #000;
  }
  &__link.active {
    background: var(--color--black);
    color: var(--color--white);
    padding: 4px 10px;
    border: 1px solid #000;
  }
}
