html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style-type: none;
}

html {
  height: 100%;
  text-size-adjust: none;
  font-size: 62.5%;
}

body {
  min-height: 100%;
  font-family: var(--font-family--gothic1);
  font-size: 1.4rem;
  line-height: 1.6;
  color: var(--color--text);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  color: #043c78;
  text-decoration: none;

  &:not(.u-nofade) {
    transition: opacity 0.3s linear;
    opacity: 1;
    &:hover {
      opacity: 0.8;
    }
  }

  &:focus,
  &:hover {
    color: #26499d;
    text-decoration: inherit;
  }
}

img {
  max-width: 100%;
  height: auto;
}

::placeholder {
  color: var(--color--black);
  opacity: 0.5;
}
