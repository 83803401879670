.c-ribbonLabel {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 68px;
  height: 71px;
  overflow: hidden;

  &__item {
    display: inline-block;
    position: absolute;
    padding: 3px 0 1px;
    left: -34px;
    top: 10px;
    width: 114px;
    text-align: center;
    font-size: 1.3rem;
    line-height: 16px;
    background: var(--color--red);
    color: #fff;
    letter-spacing: 0.05em;
    transform: rotate(-45deg);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    &::before,
    &::after {
      position: absolute;
      content: "";
      border-top: 4px solid var(--color--darkorenge);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }

    &::before {
      bottom: -4px;
      left: 14px;
    }

    &::after {
      bottom: -4px;
      right: 18px;
    }
  }
}
