/*
c-verification

年齢認証コンポーネントです。

markup:
<div id="js-verification" class="c-verification" style="visibility: visible; opacity: 1;">
  <div class="c-verificationInner">
    <div class="c-verification__logo">
      <img src="http://g1-test.bgp5.info/tpl/g1/img/ja/site_logo.png" alt="xxx">
    </div><!-- /.c-verification__logo -->
    <div class="c-verification__message">
      <p class="c-verification__message__text">
        xxxはギャル系コンテンツに特化したアダルトサイトです！<br>
        ここから先は、成人向け(アダルト)コンテンツです。<br />
        成人向けの言葉や映像が含まれています。18歳未満の方は閲覧できません。
      </p>
      <p class="c-verification__message__text c-verification__message__text--lg">
        あなたは18歳以上ですか？
      </p>
      <p class="c-verification__message__text">
        (ご自身の居住区にや地域社会における規範・法律に従ってください。)
      </p>
    </div><!-- /.c-verification__message -->
    <nav class="c-verification__nav">
      <ul class="c-verification__nav__list">
        <li class="c-verification__nav__item">
          <a href="#" id="js-verification__nav__link--no" class="c-verification__nav__link c-btn c-btn--radius c-btn--outline" role="button">いいえ</a>
        </li>
        <li class="c-verification__item">
          <a href="#" id="js-verification__nav__link--yes" class="c-verification__nav__link c-btn c-btn--radius c-btn--outline c-btn--red" role="button">はい (入場する)</a>
        </li>
      </ul>
    </nav><!-- /.c-verification__nav -->
  </div><!-- /.c-verificationInner -->
</div><!-- /.c-verification -->

Styleguide 27.1.0
 */

.c-verification {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;

  &Inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 20px;

    @media (--sm-max) {
      width: 100%;
      margin: 0 3%;
    }

    @media (--sm) {
      padding: 50px;
    }
  }

  &__logo {
    width: 150px;
    height: 100%;
    margin-bottom: 20px;

    @media (--sm) {
      width: 255px;
      margin-bottom: 40px;
    }
  }

  &__message {
    width: 100%;
    margin-bottom: 35px;

    &__text {
      text-align: center;

      &--lg {
        font-size: 2.1rem;
        padding: 15px 0;

        @media (--sm) {
          font-size: 3.8rem;
          padding: 0;
        }
      }
    }
  }

  &__nav {
    &__list {
      display: flex;
    }

    &__item {
      &:first-of-type {
        margin-right: 15px;
      }
    }

    &__link {
      width: 120px;

      @media (--sm) {
        width: 135px;
      }
    }
  }
}

