.l-sidebar {
  padding: 0 10px;

  &__section {
    &:not(:first-of-type) {
      padding-top: 0;

      @media (--sm) {
        padding-top: 25px;
      }
    }
  }

  &__title {
    font-size: 1.8rem;
    margin-bottom: 10px;

    &--ranking {
      margin-bottom: 15px;
    }
  }

  &__thumbnailNav {
    &__list {
      @media (--xs-only) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &__item {
      margin-bottom: 10px;
      @media (--xs-only) {
        width: calc(33.3333% - 10px);
        margin: 5px 5px;
      }
    }

    &__link {
      display: block;
    }

    .c-thumbnailCard__img {
      width: 60px;
      height: 60px;
      min-width: 60px;
    }

    &--col-sm-6 {
      .l-sidebar__thumbnailNav__list {
        @media (--sm-max) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }

      .l-sidebar__thumbnailNav__item {
        @media (--sm-max) {
          width: 48.5%;
        }
      }
    }

    &--performer {
    }

    &--ranking {
      .l-sidebar__thumbnailNav__item {
        position: relative;
        margin-bottom: 27px;

        &::after {
          font-size: 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          position: absolute;
          left: 4px;
          top: 0;
          color: #fff;
          transform: translate(-50%, -50%);
          background-color: var(--color--darkblue);
          border-radius: 50%;
          margin-left: 5px;

          @media (--sm) {
            left: 0;
          }
        }

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          &::after {
            background-color: var(--color--red);
          }
        }

        &:nth-of-type(1)::after {
          //counter-increment: ranking;
          //content: counter(rankign);
          content: "1";
        }
        &:nth-of-type(2)::after {
          content: "2";
        }
        &:nth-of-type(3)::after {
          content: "3";
        }
        &:nth-of-type(4)::after {
          content: "4";
        }
        &:nth-of-type(5)::after {
          content: "5";
        }
        &:nth-of-type(6)::after {
          content: "6";
        }
        &:nth-of-type(7)::after {
          content: "7";
        }
        &:nth-of-type(8)::after {
          content: "8";
        }
        &:nth-of-type(9)::after {
          content: "9";
        }
        &:nth-of-type(10)::after {
          content: "10";
        }
      }
    }

    &--comment {
      .l-sidebar__thumbnailNav__item {
        margin-bottom: 30px;
      }
    }
  }

  &__monthlyBanner {
    &__link {
      display: block;
      text-align: center;
    }

    &__img {
      display: inline-block;
      width: 100%;
      max-width: 355px;
    }
  }

  &--left {
  }

  &--right {
  }
}
