/*
c-incrimentList

インクリメント用リスト（2-5）です。

.c-incrimentList - 基本スタイル

markup:
<ol class="c-incrimentList__parent">
  <li>aaa</li>
  <li>aaa</li>
  <li>aaa</li>
  <li>aaa
    <ol class=".c-incrimentList__child">
      <li>bbb</li>
      <li>bbb</li>
      <li>bbb</li>
    </ol>
  </li>
</ol>

Styleguide 15.5.0
 */

ol.c-incrimentList {
  &__parent {
    counter-reset: parent;

    & > li::before {
    counter-increment: parent;
    content: counter(parent)". ";
    }
  }

  &__child {
    counter-reset: child;

    & > li::before {
    counter-increment: child;
    content: counter(parent)"-"counter(child)". ";
    }
  }
}