.l-tagsSidebar {
  width: 100%;
  background-color: var(--color--gray);
  border-left: solid 1px var(--color--darkgray);
  padding: 45px 3% 50px;

  @media (--sm) {
    width: 240px;
    min-width: 240px;
    padding: 45px 10px 50px;
  }

  &__title {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
}
