/*
c-productGrid

製品用のグリッドコンポーネントです。

.c-productGridSetting--layoutCol1 - レイアウトが1カラムの場合
.c-productGridSetting--layoutCol2 - レイアウトが2カラムの場合
.c-productGridSetting--layoutCol3 - レイアウトが3カラムの場合
.c-productGridSetting--single - シングルの場合

markup:
<div class="{$modifiers}">
  <div class="c-productGrid">
    <article class="c-productCard c-productGrid__item">
      <div class="c-productCard__img" style="background-image:url(/g1/resources/01/media/video/0f/239/4d1ea3b4904326eae4830b5bfbd9c0ad/image/thumb.jpg)">
        <a class="c-productCard__img__link" href="http://g1-test.bgp5.info/video/239/">
          <span class="c-productCard__label c-label c-label--new c-label--sm">新作</span>
          <time class="c-productCard__time">00:07</time>
        </a>
      </div><!-- /.c-productCard__img -->
      <div class="c-productCard__body">
        <h4 class="c-productCard__title">
        <a href="http://g1-test.bgp5.info/video/239/" class="c-productCard__title__link">test__2本立て！】天然かわいい ヲタ女子 聖ちゃん 19才 アニオタのスケベ度は半端ない！壁に足掛け全力で膣壁擦る痴態を世界へ晒したライブチャット映像【全国配信】</a>
        </h4>
        <div class="c-productCard__price"><i class="c-icons--yen"></i><span class="c-productCard__priceNum u-color--red">140</span> 円</div>
        <div class="c-productCard__tags c-tagNav">
          <a href="/tag/おっぱい" class="c-tagNav__link">おっぱい</a>
          <a href="/tag/フェラ" class="c-tagNav__link">フェラ</a>
          <a href="/tag/ハメ撮り" class="c-tagNav__link">ハメ撮り</a>
        </div>
      </div>
    </article>
  </div><!-- /.c-productGrid -->
</div><!-- /.c-productGridSetting--layoutCol3 -->

Styleguide 8.2.0
 */

.c-productGrid {
  display: flex;
  flex-wrap: wrap;

  &__item {
    margin-bottom: 30px;

    @media (--sm) {
      margin-bottom: 50px;
    }
  }

  &__item-s {
    margin-bottom: 30px;
    width: 100%;

    @media (--sm) {
      margin-bottom: 50px;
      max-width: 24.9%;
    }
  }

  &Setting {
    &--single {
      .c-productGrid__item {
        width: 100%;
        max-width: 414px;
        margin: 0 auto;
      }
    }

    &--layoutCol1 {
      .c-productGrid__item {
        @media (--sm-max) {
          width: 48.5%;

          &:nth-of-type(2n + 1) {
            margin-right: 3%;
          }
        }

        @media (min-width: 768px) and (max-width: 991px) {
          width: calc((100% - (25px * 3)) / 4);

          &:not(:nth-of-type(4n)) {
            margin-right: 24.5px;
          }
        }

        @media (min-width: 992px) and (max-width: 1399px) {
          width: calc((100% - (25px * 5)) / 6);

          &:not(:nth-of-type(6n)) {
            margin-right: 24.5px;
          }
        }

        @media (min-width: 1400px) and (max-width: 1899px) {
          /* width: calc((100% - (25px * 7)) / 8); */
          width: calc((100% - (25.2px * 7)) / 8);

          &:not(:nth-of-type(8n)) {
            margin-right: 24.5px;
          }
        }

        @media (min-width: 1900px) {
          width: calc((100% - (25px * 9)) / 10);

          &:not(:nth-of-type(10n)) {
            margin-right: 24.5px;
          }
        }
      }
    }

    &--layoutCol2 {
      .c-productGrid__item {
        @media (--sm-max) {
          width: 48.5%;

          &:nth-of-type(2n + 1) {
            margin-right: 3%;
          }
        }

        @media (min-width: 768px) and (max-width: 991px) {
          width: calc((100% - (25px * 1)) / 2);

          &:not(:nth-of-type(2n)) {
            margin-right: 24.5px;
          }
        }

        @media (min-width: 992px) and (max-width: 1199px) {
          width: calc((100% - (25px * 3)) / 4);

          &:not(:nth-of-type(4n)) {
            margin-right: 24.5px;
          }
        }

        @media (min-width: 1200px) and (max-width: 1699px) {
          width: calc((100% - (25px * 5)) / 6);

          &:not(:nth-of-type(6n)) {
            margin-right: 24.5px;
          }
        }

        @media (min-width: 1700px) {
          /* width: calc((100% - (25px * 7)) / 8); */
          width: calc((100% - (25.2px * 7)) / 8);

          &:not(:nth-of-type(8n)) {
            margin-right: 24.5px;
          }
        }
      }
    }

    &--layoutCol3 {
      .c-productGrid__item {
        @media (--sm-max) {
          width: 48.5%;

          &:nth-of-type(2n + 1) {
            margin-right: 3%;
          }
        }

        @media (min-width: 768px) and (max-width: 850px) {
          width: calc((100% - (25px * 3)) / 4);

          &:not(:nth-of-type(4n)) {
            margin-right: 24.5px;
          }
        }

        @media (min-width: 851px) and (max-width: 1099px) {
          width: calc((100% - (25px * 1)) / 2);

          &:not(:nth-of-type(2n)) {
            margin-right: 24.5px;
          }
        }

        @media (min-width: 1100px) and (max-width: 1499px) {
          width: calc((100% - (25px * 3)) / 4);

          &:not(:nth-of-type(4n)) {
            margin-right: 24.5px;
          }
        }

        @media (min-width: 1500px) and (max-width: 1999px) {
          width: calc((100% - (25px * 5)) / 6);

          &:not(:nth-of-type(6n)) {
            margin-right: 24.5px;
          }
        }

        @media (min-width: 2000px) {
          /* width: calc((100% - (25px * 7)) / 8); */
          width: calc((100% - (25.2px * 7)) / 8);

          &:not(:nth-of-type(8n)) {
            margin-right: 24.5px;
          }
        }
      }
    }

    &--mb0 {
      .c-productGrid__item {
        margin-bottom: 0;
      }
    }
  }
}
