/*
c-myDataList

マイページ専用のデータリストコンポーネントです。

markup:
<ul class="c-myDataList c-myDataList--later">
  <li class="c-myDataList__item">
    <a class="c-myDataList__link tipS" target="_blank" href="http://g1-test.bgp5.info/video/173/" title="視聴する">
      <div class="c-myDataList__thumbnail">
        <img src="http://g1-test.bgp5.info/g1/resources/01/media/video/0d/173/97c3e8aae06fc279e182f3c7b3a1f51a/image/thumb.jpg" alt="AMAD-039 ひろみ１９才（北川瞳）101m03s4">
        <span class="c-myDataList__time">00:07</span>
      </div>
      <div class="c-myDataList__content">
        <div class="c-myDataList__counter">
          <div class="c-myDataList__views">視聴回数<span class="c-myDataList__viewsCount">2</span>回</div>
          <div class="c-myDataList__likes">
            <i class="fa fa-thumbs-up"></i><span class="c-myDataList__viewsCount">0</span>回
          </div>
        </div><!-- /.c-myDataList__counter -->
        <div class="c-myDataList__title">AMAD-039 ひろみ１９才（北川瞳）101m03s4</div>
      </div><!-- /.c-myDataList__content -->
    </a><!-- /.c-myDataList__link -->
    <div class="c-myDataList__trash">
      <a class="c-myDataList__trash__link tipS c-icons--circle" href="" title="削除">
        <i class="fa fa-trash"></i>
      </a>
    </div><!-- /.c-myDataList__trash -->
  </li><!-- /.p-myPage__item -->
</ul>
<ul class="c-myDataList c-myDataList--manager">
  <li class="c-myDataList__item">
    <a class="c-myDataList__link tipS" target="_blank" href="http://g1-test.bgp5.info/video/4/" title="視聴する">
      <div class="c-myDataList__thumbnail">
        <img src="http://g1-test.bgp5.info/g1/resources/01/media/notice/01/0/59d1dbb96666d3d8eeca603f230093d9/59d1dbb96666d3d8eeca603f230093d9-5.jpg" alt="ゆきうさぎ">
        <span class="c-myDataList__time">00:00</span>
      </div>
      <div class="c-myDataList__content">
        <div class="c-myDataList__counter">
          <div class="c-myDataList__views">視聴回数<span class="c-myDataList__viewsCount">3</span>回</div>
          <div class="c-myDataList__likes">
            <i class="fa fa-thumbs-up"></i><span class="c-myDataList__viewsCount">0</span>回
          </div>
        </div><!-- /.c-myDataList__counter -->
        <div class="c-myDataList__title">ゆきうさぎ</div>
        <div class="c-myDataList__price">
          <i class="c-icons--yen c-icons--lg"></i>
          <span class="c-myDataList__priceNum">10 円</span>
        </div><!-- /.c-myDataList__price -->
      </div><!-- /.c-myDataList__content -->
    </a><!-- /.c-myDataList__link -->
    <div class="c-myDataList__select">
      <select name="salesTypes[]">
        <option value="new-4">
        <span class="tag new">新作</span>
        </option>
        <option value="recent-4">
        <span class="tag semi">準新作</span>
        </option>
        <option value="normal-4">
        <span class="tag old">旧作</span>
        </option>
      </select>
    </div><!-- /.c-myDataList__select -->
    <div class="c-myDataList__editGroup">
      <div class="c-myDataList__edit">
        <a class="c-myDataList__edit__link tipS c-icons--circle" href="http://g1-test.bgp5.info/me?sk=edit-video&vid=4" title="編集">
          <i class="fa fa-edit"></i>
        </a>
      </div><!-- /.c-myDataList__edit -->
      <div class="c-myDataList__trash">
        <a class="c-myDataList__trash__link tipS c-icons--circle c-icons--red" href="" title="削除">
        <i class="fa fa-trash"></i>
      </a>
      </div><!-- /.c-myDataList__trash -->
    </div><!-- /.c-myDataList__editGroup -->
    <div class="c-myDataList__checkbox c-overrideCheckbox checkbox-custom checkbox-primary">
      <input type="checkbox" class="js--checkRow" name="checkRow[]" value=" 4" />
      <label></label>
    </div><!-- /.c-myDataList__checkbox -->
  </li><!-- /.p-myPage__item -->
</ul><!-- /.c-myDataList -->

Styleguide 15.3.0
 */

.c-myDataList {
  &--history {
  }

  &__rightGroup {
      max-width:400px;
      display: flex;
      align-items: center;
      margin-left: auto;
      @media(--sm-max) {
        max-width:100%;
      }
    }

  &--later {
    .c-myDataList__item {
      display: block;

      @media (--sm) {
        display: flex;
        align-items: center;
      }
    }

    .c-myDataList__link {
      @media (--sm) {
        width: 100%;
        flex-grow: 1;
      }
    }



    .c-myDataList__trash {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;

      @media (--sm) {
        width: 100px;
        min-width: 100px;
        display: flex;
        justify-content: center;
      }

      &__link {
        @media (--sm) {
        }
      }
    }
  }

  &--manager {
    .c-myDataList__item {
      display: block;

      @media (--sm) {
        display: flex;
        align-items: center;
      }
    }

    .c-myDataList__lock {
      display: flex;
      &__link {
        margin-right:10px;
      }
    }

    .c-myDataList__link {
      @media (--sm) {
        flex-grow: 1;
      }
    }

    .c-myDataList__select {
      margin-right:10px;
      @media (--sm-max) {
        display: none;
        margin-right:0;
      }

      select {
        @media (--sm) {
          width: 65px;
          min-width: 65px;
          height: 27px;
          display: flex;
          justify-content: center;
          border-radius: 3px;
          margin-right: 20px;
        }
      }
    }

    .c-myDataList__encoding {
      color: var(--color--red);
      text-align: center;

      @media (--sm) {
        width: 140px;
      }
    }
    .c-myDataList__publish {
      margin-left:27px;
    }
    .c-myDataList__editGroup {
      display: flex;
      justify-content: space-between;
      width: 190px;

      @media (--sm) {
        margin-right: 20px;
      }
    }

    .c-myDataList__edit {
      @media (--sm) {
        width: 35px;
        min-width: 35px;
        justify-content: center;
      }
    }

    .c-myDataList__trash {
      @media (--sm) {
        width: 35px;
        min-width: 35px;
        justify-content: center;
      }
    }

    .c-myDataList__checkbox {
      @media (--sm-max) {
        display: none;
      }

      @media (--sm) {
        display: flex;
      }

      &--me {
        margin: 0 -30px 27px 0;

        @media (--sm-max) {
          display: none;
        }
      }
    }

    .c-myDataList__price {
      @media (--sm-max) {
        margin-bottom: 10px;
      }
    }
  }

  &--possession {
  }

  &__item {
    border-bottom: solid 1px var(--color--gray);

    @media (--sm-max) {
      padding: 40px 0 20px;
    }
  }

  &__link {
    display: block;

    @media (--sm) {
      display: flex;
      padding: 20px 0;
    }

    &--ribon {
      @media (--sm-max) {
        position: relative;
        width: 200px;
        margin: 0 auto 10px;
      }

      @media (--sm) {
        position: relative;
        display: flex;
      }
    }
  }

  &__thumbnail {
    position: relative;

    @media (--sm-max) {
      width: 200px;
      height: 200px;
      margin: 0 auto 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--color--gray);
    }

    @media (--sm) {
      width: 90px;
      min-width: 90px;
      height: 90px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--color--gray);
    }

    img {
      @media (--sm-max) {
        max-width: 200px;
        max-height: 200px;
      }

      @media (--sm) {
        max-width: 90px;
        max-height: 90px;
      }
    }
  }

  &__time {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: var(--color--black);
    color: #fff;
    line-height: 1;
    text-align: center;
    padding: 2px 0;
  }

  &__content {
    flex-grow: 1;
    width: 100%;
    padding-bottom: 20px;

    @media (--sm) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__tile {
  }

  &__counter {
    display: flex;
    color: var(--color--text-gray);
    margin-bottom: 0;

    @media (--sm) {
    }
  }

  &__views {
    white-space: nowrap;
    margin-right: 10px;

    &Count {
    }
  }

  &__likes {
    .fa {
      margin-right: 3px;
    }
  }

  &__title {
    width: calc(100% - 40px);
    padding-right: 40px;
  }

  &__price {
    white-space: nowrap;
    font-size: 1.8rem;
    line-height: 1;
    margin-top: 5px;
    display: flex;
    align-items: center;

    @media (--sm-max) {
      margin-top: 20px;
    }

    &Num {
      margin-right: 5px;
    }
  }
}
