/*
c-pageUpBtn

ページアップボタンコンポーネントです。

markup:
<a class="c-pageUpBtn"><i class="fas fa-arrow-circle-up"></i></a>

Styleguide 2.2.0
 */

a.c-pageUpBtn {
  width: 40px;
  height: 40px;
  display: block;
  position: fixed;
  font-size: 4.0rem;
  line-height: 1;
  color: var(--color--black);
  right: 30px;
  bottom: 40px;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  cursor: pointer;

  &--visible {
    visibility: visible;
    opacity: 1;
  }

  &::before {
    content: "";
    display: block;
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  i.fas {
    position: relative;
  }
}
