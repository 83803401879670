/*
c-styleList

スタイルリストコンポーネントです。

.c-styleList - 基本スタイル
.c-styleList--disc - discスタイル
.c-styleList--decimal - decimalスタイル

markup:
<ul class="c-styleList {$modifiers}">
  <li class="c-styleList__item">リストリスト（{$modifiers}）</li>
</ul>

Styleguide 15.4.0
 */

.c-styleList {
  margin-left: 1.7em;

  &--disc {
    list-style-type: disc;
  }

  &--decimal {
    list-style-type: decimal;
  }

  &__item {
    margin-bottom: 10px;
  }
}
