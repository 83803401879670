.p-edit {
  &__icon {
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 2.2rem;
  }

  a.c-btn--lg.c-btn--radius,
  button.c-btn--lg.c-btn--radius {
    text-align: center;

    @media (--sm-max) {
      display: flex;
    }
  }

  .p-myPageSetting__snsForm__labelWrapper {
    margin-bottom: 0;
  }

  .p-myPageSetting__snsForm__btn {
    width: 150px;
    max-width: 150px;
    display: block;
  }

  .form-material .form-control {
    padding: 0 20px;
  }

  .radio-custom label {
    margin-right: 30px;
  }
}

.p-myPageMonthlyPlan {
  &__title {
    font-size: var(--fs--md);
    font-weight: bold;
  }

  &__form {
    border: 1px solid var(--color--form-gray);
    padding: 20px;
    border-radius: 3px;

    &__upper {
      border-bottom: 1px solid var(--color--darkgray);
      padding-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (--lg-max) {
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 5px;
      }

      &__payDay {
        margin-right: 10px;
      }

      &__day {
        margin-right: 10px;
      }

      &__doll {
        margin-right: 10px;
      }

      &__check {
        @media (--lg-max) {
          margin: 0 auto;
        }
      }
    }

    &__down {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
}
