.c-customRadio {
  display: flex;
  align-items: center;
  position: relative;
  height: 26px;

  &__input {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    -webkit-appearance: none;
    background: none;
    border: none;
    margin: 0 !important;

    &:focus {
      outline: none !important;
    }

    &:checked {
      &+.c-customRadio__label {
        &::before {
          border-color: var(--color--black);
        }
      }
    }
  }

  &__label {
    font-weight: normal;
    position: relative;
    padding-left: 36px;
    line-height: 26px;
    white-space: nowrap;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 13px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      transition: 0.3s;
    }

    &::before {
      width: 26px;
      height: 26px;
      background-color: #fff;
      border: solid 7px var(--color--gray);
    }

    &::after {
      width: 6px;
      height: 6px;
      background-color: var(--color--black);
    }
  }
}
