/*
c-paginationHeader

ハンバーガーコンポーネントです。

markup:
<header class="c-paginationHeader">
  <h2 class="c-paginationHeader__title c-categoryTitle"><i class="fa fa-chart-line"></i>人気作品</h2>
  <div class="c-paginationHeader__pagination">
    <nav class="c-pagination"><ul class="c-pagination__list"><li class="c-pagination__item c-pagination__item--disabled"><span class="c-pagination__nolink"><i class="fa fa-chevron-left"></i></span></li><li class="c-pagination__item c-pagination__item--active"><span class="c-pagination__nolink">1</span></li><li class="c-pagination__item"><a class="c-pagination__link" href="http://g1-test.bgp5.info/videos/most-viewed?p=2">2</a></li><li class="c-pagination__item"><a class="c-pagination__link c-pagination__link--next" href="http://g1-test.bgp5.info/videos/most-viewed?p=2"><i class="fa fa-chevron-right"></i></a></li></ul></nav><!-- /.p-myPage__pagination -->
  </div>
</header>

Styleguide 10.1.0
 */

.c-paginationHeader {
  display: flex;
  flex-direction: column;

  @media (--sm) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__title {
    margin-bottom: 15px;
  }

  &__pagination {
    padding-top: 5px;
    margin-left: auto;
  }
  &__text {
    width: 100%;
  }
}
