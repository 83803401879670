.p-lpf {

  &_inner {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

  }

  &_header {
    margin-bottom: 60px;
    background: url(../img/ja/lpf/LPF_background.png);
  }

  &_push {
    display:flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 40px;
    padding:0 20px;

    &_h2 {
      font-size: 5rem;
      font-weight: bold;
      line-height: 1.5;
      margin-bottom:10px;

      @media (--sm-max) {
        font-size: 2.3rem;
      }
    }

    &_p-h2 {
      font-size: 2.3rem;

      @media (--sm-max) {
        font-size: 1.6rem;
      }
    }

    &_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size:7rem;

      i {
        margin:30px 10px;
        color: #aaa;
      }
    }

    &_h3 {
      font-size:3.6rem;
      font-weight: bold;

      @media (--sm-max) {
        font-size: 2rem;
        margin-bottom: 10px;
      }
    }

    &_p-h3 {
      font-size: 2rem;
      line-height: 1.5;
      margin-bottom:30px;

      @media (--sm-max) {
        font-size: 1.6rem;
      }
    }

    &_items {
      display: flex;
      flex-wrap:wrap;
      justify-content: space-around;
    }

    &_item {
      display: flex;
      flex-direction: column;
      width:22%;
      margin:0 10px 30px;
      background: var(--color--white);
      padding:25px 20px 30px;
      border: 1px solid #eee;
      border-radius: 8px;
      box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.1);

      @media (--md-max) {
           width:45%;
        }

      @media (--sm-max) {
         width:100%;
      }
    }

    &_link {
      margin-bottom:10px;
    }

    &_img {
      width :100%;
      max-width: 188px;
    }

    &_h4 {
      font-size:1.2rem;
      font-weight: 100;
      line-height: 2;
      text-align: left;
    }
  }

  &_longSample {
      background: var(--color--black);
      padding: 60px 20px 40px;
      margin-bottom:60px;

      &_box {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &_h2 {
        color: var(--color--white);
        font-size: 5rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.5;
        margin-bottom: 10px;

        @media (--md-max) {
          font-size: 5rem;
        }

        @media (--sm-max) {
          font-size: 2.3rem;
        }
      }

      &_p {
        color: var(--color--white);
        text-align: center;
        font-size: 2rem;
        margin-bottom:50px;

        @media (--sm-max) {
          font-size: 1.6rem;
        }
      }

      &_items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        @media (--md-max) {
        justify-content: flex-start;
        }
      }

      &_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 22%;
        margin: 0 10px 50px;
        background: #FFF;
        border-radius: 5px;
        box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.1);
        border: 1px solid #eee;
        padding: 30px;

        @media (--md-max) {
        width: 47%;
        max-width: 47%;
        margin: 0 10px 20px;
        }

        @media (--sm-max) {
          width: 100%;
          min-width: 100%;
        }
      }

      &_img {
        max-width: 138px;
        width:138px;
        margin-bottom: 15px;

        @media (--md-max) {
          min-width: 138px;
          width: 100%;
          margin-right: 15px;
        }

        @media (--sm-max) {
          min-width: 138px;
          margin-bottom: 15px;
        }
      }

      &_h3 {
        font-size: 1.2rem;
        font-weight: 100;

        @media (--md-max) {
           font-size: 1.8rem;
           max-width: 100%;
        }

        @media (--sm-max) {
           font-size: 1.6rem;
           max-width: 100%;
        }
      }
    }

  &_genre {
    display:flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 60px;
    padding:0 20px;

    @media (--sm-max) {
      margin-bottom: 40px;
    }

    &_h2 {
      font-size: 5rem;
      font-weight: bold;
      line-height: 1.5;
      margin-bottom:10px;

      @media (--sm-max) {
        font-size: 2.3rem;
      }
    }

    &_p {
      font-size: 2.3rem;
      margin-bottom: 30px;

      @media (--sm-max) {
        font-size: 1.6rem;
      }
    }

    &_items {
      display: flex;
      flex-wrap:wrap;
      justify-content: space-around;
    }

    &_item {
      display: flex;
      flex-direction: column;
      width:22%;
      margin:0 10px 30px;
      background: var(--color--white);
      padding:25px 20px 30px;
      border: 1px solid #eee;
      border-radius: 8px;
      box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.1);

      @media (--md-max) {
           width:45%;
        }

      @media (--sm-max) {
         width:100%;
      }
    }

    &_box {
      display: flex;
      margin-bottom: 10px;
    }

    &_h3 {
      margin-right:auto;
    }

    &_link-list {
      background:#eee;
      padding:3px 10px 1px;
      border-radius: 20px;
      margin-left:auto;
    }

    &_link {
      margin-bottom:10px;
    }

    &_img {
      width :100%;
      max-width: 188px;
    }

    &_h4 {
      font-size:1.2rem;
      font-weight: 100;
      line-height: 2;
      text-align: left;
    }
  }

  &_QA {
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 60px;
    padding:60px 20px;
    background: #1b1b1b;

    &_h2 {
      font-size: 5rem;
      font-weight: bold;
      color: var(--color--white);
      line-height: 1.5;
      margin-bottom:10px;

      @media (--sm-max) {
        font-size: 2.3rem;
      }
    }

    &_p {
      font-size: 2.3rem;
      color: var(--color--white);
      margin-bottom: 30px;

      @media (--sm-max) {
        font-size: 1.6rem;
      }
    }

    &_box {
      display: flex;
      flex-direction: column;
      max-width: 800px;
      margin: 0 auto;

      &-Q {
        padding:12px 20px;
        background: var(--color--white);
        border:1px solid #000;
        border-radius: 8px;
        font-size: 1.6rem;
        margin-right: auto;
        margin-bottom:20px;
        text-align:left;
      }

      &-A {
        padding:12px 20px;
        background: var(--color--black);
        color:var(--color--white);
        border-radius: 8px;
        border:1px solid #FFF;
        font-size:1.6rem;
        margin-left: auto;
        margin-bottom:20px;
        text-align:left;
      }
    }

    &_link {
      color: var(--color--white);
      display: block;
      margin-top:30px;

      &:hover {
        color: var(--color--white);
      }
    }

    &_link-A {
      color: var(--color--yellow);
      padding:0 10px;

      &:hover {
        color: var(--color--yellow);
      }
    }
  }

  &_single {
    text-align: center;
    padding:0 20px;
    margin-bottom: 60px;

    &_box {
      border-bottom: 1px solid #333;
      margin-bottom:30px;
    }

    &_h2 {
      font-size: 5rem;
      font-weight: bold;
      line-height: 1.5;
      margin-bottom:10px;

      @media (--sm-max) {
        font-size: 2.3rem;
      }
    }

    &_h3 {
      font-size: 3.6rem;
      font-weight: bold;
      margin-bottom: 30px;

      @media (--sm-max) {
        font-size: 2rem;
      }
    }

    &_p {
      font-size: 1.6rem;
      line-height: 2;
      margin-bottom: 30px;
      word-break: keep-all;

      @media (--sm-max) {
        font-size: 1.6rem;
      }
    }

    &_h4 {
      font-size: 2.3rem;
      font-weight: 100;
      margin-bottom: 30px;

      @media (--sm-max) {
        font-size: 1.6rem;
      }
    }

    &_link {
      display: inline-block;
      width: 320px;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      background: #f00;
      background: var(--color--red);
      color: #fff;
      color: var(--color--white);
      padding: 10px;

      @media (--sm-max) {
        width: 100%;
      }

      &:hover {
        color: var(--color--white);
      }
    }
  }

  &_buy {
    display: flex;
    flex-direction:column;
    align-items: center;
    background:url(../img/ja/lpv/LPV_joinBackground.png) repeat-x;
    padding: 30px 20px;
    text-align: center;

    &_img {
      width:119.5px;
      margin-bottom:0px;
    }

    &_h2 {
      font-size:5rem;
      font-weight: bold;
      color:var(--color--white);

      @media (--md-max) {
          font-size: 3.5rem;
      }

      @media (--sm-max) {
          font-size: 2.3rem;
      }
    }

    &_p {
      font-size: 1.6rem;
      color:var(--color--white);
      margin-bottom: 0px;

      @media (--md-max) {
          font-size: 1.8rem;
      }

      @media (--sm-max) {
          font-size: 1.6rem;
      }
    }

    &_h3 {
      font-size:3rem;
      font-weight: bold;
      color:var(--color--white);
      margin-bottom:10px;
    }

    &_link {
      width: 320px;
      font-size:2rem;
      font-weight: bold;
      text-align: center;
      background:var(--color--red);
      color:var(--color--white);
      padding:10px;

      @media (--sm-max) {
        width: 100%;
      }

      &:hover {
        color:var(--color--white);
      }
    }
  }
}


