.c-fileList {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: solid 1px var(--color--darkgray);

    &Title {
      font-size: 1.4rem;
    }

    &Link {
      font-size: 2.6rem;
    }
  }

  &__items {}

  &__item {
    display: flex;
    border-bottom: solid 1px var(--color--darkgray);
    padding: 10px 0;
  }

  &__media {
    width: 125px;

    &Name {
      font-size: 1.2rem;
      margin-bottom: 3px;
    }

    &Thumbnail {}
    &Download {}
  }

  &__control {
    width: calc(100% - 100px);
    padding-left: 10px;

    &Items {}

    &Item {
      margin-bottom: 5px;
    }

    a&Link,
    button&Link {
      display: flex;
      width:200px;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1;
      white-space: nowrap;
      border-radius: 5px;
      color: var(--color--white);
      padding: 7px 10px;

      &.-message,
      &.-buyerMessage {
        background-color: var(--color--black);
      }

      &.-delete {
        width: 60px;
        background-color: var(--color--red);
        -webkit-appearance: none;
        border: none;
      }
    }

    &Icon {
      font-size: 1.6rem;
      margin-right: 5px;
    }
  }
}

.MaxUpload_Box .Maxuploader span {
  padding: 10px 4px;
}

.MaxUpload_Box .Maxuploader input[type=file] {
  top: 7px;
  left: 5%;
}
