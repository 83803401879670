/*
c-hr

HRタグ専用のコンポーネントです。

.c-hr - 基本スタイル
.c-hr--gray - グレイスタイル
.c-hr--darkgray - ダークグレイスタイル
.c-hr--space20 - スペース20pxスタイル
.c-hr--space30 - スペース30pxスタイル
.c-hr--space40 - スペース40pxスタイル

markup:
<hr class="c-hr {$modifiers}">

Styleguide 11.1.0
 */

.c-hr {
  height: 0;
  border: 0;
  border-top: solid 1px var(--color--gray);

  &--gray {
    border-top: solid 1px var(--color--gray);
  }

  &--darkgray {
    border-top: solid 1px var(--color--darkgray);
  }

  &--space20 {
    margin: 20px 0;
  }

  &--space30 {
    margin: 30px 0;
  }

  &--space40 {
    margin: 40px 0;
  }
}
