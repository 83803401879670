.p-pay {
  width: 100%;

  &__header {
    display: flex;
    justify-content: center;

    &Inner {
      width: 100%;
      padding: 0 20px 10px;

      @media (--sm) {
        width: 1030px;
        padding-left: 65px;
        padding-right: 65px;
      }
    }

    &__title {
      margin-bottom: 5px;

      @media (--sm) {
        margin-bottom: 35px;
      }
    }

    &__description {
      font-size: 1.8rem;
    }
  }

  &__errors {
    width: 100%;
    background-color: #fff;
    padding: 0 3% 20px;

    @media (--sm) {
      width: 770px;
      border-radius: 10px;
      padding: 25px;
      margin-top: 35px;
    }

    &Inner {
      @media (--sm-max) {
        border: solid 1px var(--color--gray);
        border-radius: 5px;
        padding: 25px 20px;
      }
    }
  }

  &__progressNav {
    padding: 35px 0;
  }

  &__product {
    &Area {
      display: flex;
      justify-content: center;
      background-color: var(--color--gray);
      padding: 0 0 40px;

      &Inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (--sm) {
          width: 1200px;
          padding-left: 65px;
          padding-right: 65px;
        }
      }
    }

    &Info {
      width: 100%;
      background-color: #fff;

      @media (--sm-max) {
        padding: 0 20px;
      }

      @media (--sm) {
        width: 770px;
        border-radius: 10px;
      }

      &__detail {
        border-bottom: solid 1px var(--color--gray);
        padding: 25px 0 10px;

        @media (--sm) {
          padding: 25px 30px 20px;
        }
      }

      &__title {
        font-size: 1.8rem;
        margin-bottom: 25px;

        @media (--sm) {
          margin-bottom: 18px;
        }
      }

      &__table {
        table-layout: fixed;
      }

      &__tr {
        @media (--sm-max) {
          display: block;
        }
      }

      &__th,
      &__td {
        vertical-align: top;
        padding: 10px 0;

        @media (--sm-max) {
          display: block;
          padding: 0 0 3px;
        }
      }

      &__th {
        &:first-of-type {
          width: 175px;
        }
      }

      &__td {
        margin-bottom: 15px;
      }

      &__siteName {
        display: inline-block;
        white-space: nowrap;
        margin-right: 10px;
      }

      &__price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &Wrapper {
          display: flex;
          justify-content: flex-end;
          padding: 0 20px;

          @media (--sm) {
            padding: 0 30px;
          }
        }

        &Title {
          color: var(--color--text-gray);
          margin-bottom: 10px;
        }

        &Num {
          font-size: 2rem;
          margin-bottom: 5px;
        }

        &Doller {
          color: var(--color--text-gray);
        }

        .l-detailSidebar__priceRate {
          margin-bottom: 0;
        }
      }
    }
  }

  &__attention {
    padding: 25px 30px 20px;

    @media (--sm) {
      padding: 25px 30px 20px;
    }

    &__title {
      font-size: 1.6rem;
      margin-bottom: 20px;
    }

    &__list {
    }

    &__item {
      line-height: 2;

      @media (--sm) {
      }
    }

    &__text {
      @media (--sm) {
      }
    }

    a&__strong {
      font-size: 1.8rem;
      color: var(--color--red);
    }
  }

  &__credit {
    &Area {
      display: flex;
      justify-content: flex-end;
      margin: 0 30px 30px;

      @media (--sm-max) {
        margin: 0 20px 30px;
      }

      &Inner {
        width: 100%;
        text-align: right;

        @media (--sm) {
          width: 900px;
        }
      }
    }

    &Form {
      &__title {
        font-size: 1.8rem;
        text-align: center;
        margin-bottom: 35px;

        @media (--sm) {
          font-size: 2.2rem;
          margin-bottom: 40px;
        }
      }

      &__description {
        margin-bottom: 20px;

        @media (--sm) {
          margin-bottom: 25px;
        }
      }

      &__group {
        margin-bottom: 25px;

        &--cardNum {
        }

        &--expire {
          .p-pay__creditForm__label {
            margin-bottom: 5px;

            @media (--sm) {
              margin-bottom: 0;
            }
          }

          .p-pay__creditForm__itemWrapper {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
          }

          .p-pay__creditForm__inputWrapper {
            display: flex;
            align-items: center;
            margin-right: 10px;

            @media (--sm) {
              margin-right: 30px;
            }
          }

          .p-pay__creditForm__unit {
            white-space: nowrap;
            margin-left: 10px;
          }
        }

        &--name {
          display: flex;
          flex-direction: column;

          @media (--sm) {
            flex-direction: row;
            justify-content: space-between;
          }
        }

        &--cvv {
          .p-pay__creditForm__label {
            margin-bottom: 0;
          }

          .p-pay__creditForm__itemWrapper {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            @media (--sm) {
              margin-bottom: 15px;
            }
          }
        }
      }

      &__item {
        &--monthExpire,
        &--yearExpire {
          margin-bottom: 0;
        }

        &--firstName,
        &--lastName {
          width: 100%;

          @media (--sm) {
            width: calc(50% - 15px);
          }
        }

        &--lastName {
          @media (--sm-max) {
            margin-bottom: 20px;
          }
        }

        &--cvv {
          margin-right: 15px;

          @media (--sm) {
            margin-right: 30px;
          }
        }
      }

      &__label {
        margin-bottom: 5px;
      }

      &__input {
        height: 52px;
        border: solid 2px var(--color--form-gray);
        border-radius: 5px;
        padding: 0 20px;

        &--cardNum {
          width: 100%;
        }

        &--monthExpire,
        &--yearExpire {
          width: 85px;

          @media (--sm) {
            width: 95px;
          }
        }

        &--firstName {
          width: 100%;
        }

        &--lastName {
          width: 100%;
        }

        &--cvv {
          width: 100px;

          @media (--sm) {
            width: 300px;
          }
        }
      }

      &__cardImg {
        width: 80px;

        @media (--sm) {
          width: 110px;
        }

        img {
          display: block;
        }
      }

      &__inputNote {
        font-size: 1.3rem;
        color: var(--color--text-gray);
      }

      button&__submitBtn {
        width: 100%;
        font-size: 1.8rem;
        font-weight: bold;
        margin-top: 10px;

        @media (--sm) {
          width: 200px;
          margin-top: 20px;
        }
      }
    }
  }

  &__modal {
    &Dialog {
      @media (--sm) {
        width: 100%;
        max-width: 900px;
      }
    }

    &Header {
      @media (--sm) {
        padding: 25px 30px;
      }

      &__closeBtn {
        font-size: 2.8rem;
      }
    }

    &Title {
      font-size: 2rem;
      font-weight: bold;
    }

    &Main {
      @media (--sm) {
        padding: 10px 50px;
      }
    }

    &Table {
      table-layout: fixed;

      &__th,
      &__td {
        vertical-align: top;
        padding: 15px 0;
      }

      &__th {
        font-weight: normal;
        width: 120px;

        @media (--sm) {
          width: 170px;
          padding-right: 15px;
        }
      }

      &__td {
        padding-left: 15px;

        &--price {
          font-size: 2rem;
        }
      }
    }

    &Footer {
      @media (--sm) {
        padding: 25px 30px;
      }
    }

    &Btn {
      width: calc((100% - (15px * 1)) / 2);
      font-size: 1.8rem;

      &:first-of-type {
        margin-right: 10px;
      }

      @media (--sm) {
        width: 130px;
      }

      &--red {
        font-weight: bold;
      }
    }
  }

  &__paySelect {
    &__items {
      display: flex;
      @media (--sm-max) {
        flex-direction: column;
      }
    }

    &__item {
      width: 50%;

      @media (--sm-max) {
        width: 100%;
      }
    }

    &__input {
      opacity: 0;
      position: absolute;

      &:checked + .p-pay__paySelect__label {
        border: 3px solid var(--color--red);
      }
    }

    &__label {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: calc(100% - 20px);
      margin: 10px;
      padding: 20px;
      border: 3px solid #eee;
      border-radius: 8px;

      @media (--sm-max) {
        width: calc(100% - 20px);
      }
    }

    &__labelTxt {
      margin-bottom: 10px;
    }
  }
}
