.p-recover {
  &__form {
    &Group {}

    &Label {
      display: block;
      margin-bottom: 10px;
    }

    &Block {
      @media (--xs) {
        display: flex;
      }
    }

    &Input {
      width: 100%;
      height: 50px;
      padding: 5px 10px;

      @media (--xs-max) {
        margin-bottom: 25px;
      }

      @media (--xs) {
        max-width: 355px;
        margin-right: 15px;
      }
    }

    button&Submit {
      width: 100%;
      height: 50px;

      @media (--xs) {
        max-width: 70px;
      }

      &.disabled {
        display: none;
      }
    }
  }
}
