/*
c-tagNav

タグナビゲーションコンポーネントです。

markup:
<nav class="c-tagNav c-tagNav--lg">
  <a href="#" class="c-tagNav__link">ニーハイ(6)</a>
  <a href="#" class="c-tagNav__link">素人妹(6)</a>
  <a href="#" class="c-tagNav__link">ギャル(6)</a>
</nav>

Styleguide 17.10.0
 */

.c-tagNav {
  display: flex;
  flex-wrap: wrap;

  a&__link {
    background-color: var(--color--gray);
    border: solid 1px var(--color--darkgray);
    border-radius: 30px;
    font-size: 1.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 8px;
    margin: 0 5px 9px 0;

    &--dark {
      background-color: #777;
      color: #fff;
      border: solid 1px #5b5b5b;
      outline: none;
    }
    &--top {
      margin: 0 0 0 5px;
    }
  }

  &--lg {
    .c-tagNav__link {
      font-size: 1.4rem;
      padding: 4px 8px;
    }
  }

  &--white {
    .c-tagNav__link {
      background-color: #fff;
    }
  }

  &--sidenav {
    background-color: var(--color--black);
    color: var(--color--white);
    font-size: 1rem;
    padding: 0px 5px 0;
    border-radius: 3px;
    border: 1px solid var(--color--white);
    padding: 0 15px -10px 15px;
  }
}
