.p-paid {
  width: 100%;

  @media (--sm-max) {
    padding: 0 3%;
  }

  &__header {
    display: flex;
    justify-content: center;
    border-bottom: solid 1px var(--color--gray);

    &Inner {
      width: 100%;
      padding: 0 0 10px;

      @media (--sm) {
        width: 1030px;
        padding-left: 65px;
        padding-right: 65px;
      }
    }
  }

  &__title {
    margin-bottom: 20px;

    @media (--sm) {
      margin-bottom: 30px;
    }
  }

  &__cardTitle {
    font-size: 1.8rem;
  }

  &__content {
    display: flex;
    justify-content: center;

    &Inner {
      width: 100%;
      padding: 25px 0 80px;

      @media (--sm) {
        width: 1030px;
        padding-left: 65px;
        padding-right: 65px;
      }
    }
  }

  &__progressNav {
    margin-bottom: 20px;
  }

  &__resultText {
    line-height: 1.8;
    margin-bottom: 25px;

    @media (--sm) {
      margin-bottom: 55px;
    }
  }

  &__btn {
    font-size: 1.8rem;
    width: 100%;

    @media (--sm) {
      width: 205px;
    }

    &Wrapper { }
  }
}
