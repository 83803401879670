.c-sampleIframe {
  max-width: 480px;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 57.4%;
  }

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

