/*
c-userPopover

ユーザーポップオーバー（メニュー）コンポーネントです。

markup:
<div class="popover c-userPopover fade bottom in">
<div class="arrow" style="left: 80%;"></div>
<div class="c-userPopover__header">
<div class="c-userPopover__avatar">
<img src="http://g1-test.bgp5.info/g1/resources/01/media/user/02/2/f3cb6fd3f594227cef2a08a60e17f310/avatar.jpg">
</div>
<div class="c-userPopover__userName"><a href="">mrym</a></div>
</div>
<nav class="c-userPopover__nav">
<ul class="c-userPopover__list">
<li class="c-userPopover__item"><a class="c-userPopover__link" href="/me?sk=history">視聴履歴</a></li>
<li class="c-userPopover__item"><a class="c-userPopover__link" href="/me?sk=later">後で見る</a></li>
<li class="c-userPopover__item"><a class="c-userPopover__link" href="/me?sk=possession">購入済み</a></li>
<li class="c-userPopover__item c-userPopover__item--borderTop"><a class="c-userPopover__link" href="/profile/user/2?sk=activity">アクティビティ</a></li>
<li class="c-userPopover__item"><a class="c-userPopover__link" href="/profile/user/2/?sk=comments">コメント</a></li>
<li class="c-userPopover__item c-userPopover__item--borderTop"><a class="c-userPopover__link" href="http://g1-test.bgp5.info/me">動画管理</a></li>
<li class="c-userPopover__item"><a class="c-userPopover__link" href="/add-video">アップロード</a></li>
<li class="c-userPopover__item c-userPopover__item--borderTop"> <a class="c-userPopover__link" href="http://g1-test.bgp5.info/pay/plan/monthly">30日間見放題プラン</a></li>
<li class="c-userPopover__item"><a class="c-userPopover__link" href="/dashboard/?sk=edit&amp;tab=social">SNSリンク</a></li>
<li class="c-userPopover__item"><a class="c-userPopover__link" href="/dashboard/?sk=edit">アバターの変更</a></li>
<li class="c-userPopover__item"><a class="c-userPopover__link" href="/dashboard/?sk=edit&amp;tab=password">パスワードの変更</a></li>
<li class="c-userPopover__item"><a class="c-userPopover__link" href="/dashboard/?sk=edit&amp;tab=text">詳細変更</a></li>
<li class="c-userPopover__item c-userPopover__item--borderTop"><a class="c-userPopover__link" href="/moderator">管理</a></li>
<li class="c-userPopover__item"><a class="c-userPopover__link" href="/?action=logout">ログアウト</a></li>
</ul>
</nav>
</div>

Styleguide 20.1.0
 */

.c-userPopover {
  width: 250px;

  &__header {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 8px;
  }

  &__avatar {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }

  &__userName {
    width: 100%;
    flex-grow: 1;
    word-break: break-word;
  }

  &__nav {
    background: var(--color--gray);
    padding-bottom: 15px;
  }

  &__list { }

  &__item {
    &--borderBottom {
      border-bottom: solid 1px var(--color--darkgray);
    }
  }

  a&__link {
    display: block;
    background: var(--color--gray);
    padding: 7px !important;

    &:hover {
      background: var(--color--darkgray) !important;
    }
  }
}
div#myMenu {
    height: 40px;
    width: 40px;
}

/*ハンバーガーメニューの設定*/
#myMenu-open { /*アイコンのスペース*/
  display: inline-block;
  cursor:pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: none;
  overflow:hidden;
}

#myMenu-open img{ /*アイコンのスペース*/
  max-width: 40px;
  max-height: 40px;
}

/*ハンバーガーメニュー展開後の中身*/
#myMenu-content{
  padding:1px;
  display: none;
  position: absolute;
  top: 100px;
  right: 1px;
  z-index: 9999;/*最前面に*/
  width: 90%;
  max-width: 250px;/*最大幅（調整してください）*/
  height: auto;
  border: 1px solid #c5c5c5;
  background-color: #FFF;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
  line-height: 1.45em;
}

#myMenu-content> .arrow,
#myMenu-content> .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid
}

#myMenu-content> .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-width: 11px;
  border-top-width: 0;
  border-bottom-color: #c5c5c5;
  border-bottom-color: rgba(0,0,0,.25)
}

#myMenu-content> .arrow:after {
  content: "";
  top: 1px;
  margin-left: -10px;
  border-width: 10px;
  border-top-width: 0;
  border-bottom-color: #fff;
}

