.p-lps {

  &_inner {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }

  &_header {
    margin-bottom: 60px;
    background: url(../img/ja/lps/LPS_background.png);

    @media (--sm-max) {
      margin-bottom: 40px;
    }
  }

  &_galList {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    text-align: center;

    @media (--sm-max) {
      margin-bottom: 40px;
      margin:0 20px 40px;
    }

    &_h2 {
      font-size: 5rem;
      font-weight: bold;
      margin-bottom: 30px;
      line-height: 1.5;

      @media (--sm-max) {
        font-size: 2.3rem;
        margin-bottom: 10px;
      }

      &-under {
        font-size: 5rem;
        font-weight: bold;
        margin-bottom: 60px;
        line-height: 1.5;

        @media (--sm-max) {
          font-size: 2.3rem;
          margin-bottom: 30px;
        }
      }
    }

    &_span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: var(--color--white);
      background: var(--color--red);
      border-radius: 8px;
      padding: 2px 10px 0px;
      margin: 0 15px;
    }
    &_h3 {
      font-weight: bold;
      font-size: 5rem;
      margin-bottom: 0px;

      @media (--sm-max) {
        font-size: 2.3rem;
      }
    }

    &_p {
      font-size: 2.3rem;
      margin-bottom: 10px;
      @media (--sm-max) {
        font-size: 2rem;
        margin-bottom: 0;
      }
    }
    &_p-top {
      font-size: 2.3rem;
      margin-bottom: 30px;
      @media (--sm-max) {
        font-size: 2rem;
      }
    }
    &_p-under {
      font-size: 2.3rem;
      @media (--sm-max) {
        font-size: 2rem;
      }
    }
    &_p-under:after {
      content: "▼";
      display: block;
      text-align: center;
      font-size: 5rem;
      color: var(--color--red);
    }
    &_items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    &_item {
      display: flex;
      flex-direction: column;
      align-content: center;
      margin: 0 10px 30px;
      max-width: 120px;
    }

    &_img {
      width: 100%;
      max-width: 120px;
      margin-bottom:10px;
    }
  }

  &_special {
    text-align: center;
    margin-bottom: 60px;
    background: url(../img/ja/lps/LPS_background.png);

    @media (--sm-max) {
      margin-bottom: 40px;
    }
  }

&_pushgal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &_p {
    font-size: 2.3rem;
    margin-bottom: 10px;

    @media (--sm-max) {
      margin-bottom: 0;
    }
  }

  &_h2 {
    font-size: 5rem;
    font-weight: bold;
    margin-bottom: 30px;

    @media (--sm-max) {
      font-size: 3rem;
    }
  }

  &_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
  }

  &_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    margin: 0 20px 30px;m
    background: var(--color--white);
    border-radius: 8px;
    padding: 20px 20px 30px 20px;
    border: 1px solid var(--color--gray);
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.1);
    text-align: center;

    @media (--md-max) {
      width: 43%;
      margin: 0 20px 30px;
    }
    @media (--sm-max) {
      width: 80%;
      margin: 0 20px 30px;
    }
  }

  &_img {
    max-width: 200px;
    margin-bottom: 20px;
  }

  &_h3 {
    font-size: 1.6rem;
    color: #FF639F;
    margin-bottom: 10px;
  }

  &_itemP {
    font-size: 1.2rem;
    @media (--sm-max) {
      font-size: 1.6rem;
    }
  }
}

  &_singleSale {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--color--black);
    color: var(--color--white);
    text-align: center;
    padding: 50px 20px;
    margin-bottom: 60px;
    @media (--sm-max) {
      margin-bottom: 40px;
    }
    &_h2 {
      font-size: 5rem;
      font-weight: bold;
      margin-bottom: 10px;

      @media (--sm-max) {
        font-size: 2.3rem;
      }
    }
    &_p {
      font-size: 1.6rem;
      line-height: 2;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid #FFF;
    }
    &_h3 {
      font-size: 2.3rem;
      font-weight: 100;
      margin-bottom: 30px;

      @media (--sm-max) {
        font-size: 2rem;
      }
    }
    &_link {
      max-width: 100%;
      width: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.3rem;
      color: var(--color--white);
      background: var(--color--red);
      padding: 8px 10px;

      &:hover {
        color: var(--color--white);
      }
    }
  }

  &_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    text-align: center;
    margin:0 20px;

    @media (--sm-max) {
      margin-bottom: 40px;
    }
    &_h2 {
      font-size: 5rem;
      font-weight: bold;
      @media (--sm-max) {
        font-size: 2.3rem;
      }
    }
    &_p {
      font-size: 2rem;
      margin-bottom:60px;
      @media (--sm-max) {
        margin-bottom:0;
      }
    }
  }

  &_sightSeeing {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align:center;
    background: url(../img/ja/lps/LPS_background.png);
    color: var(--color--white);
    padding: 50px 20px;

    &_h2 {
      font-size: 5rem;
      font-weight: bold;
      @media (--sm-max) {
        font-size: 3rem;
      }
    }
    &_p {
      font-size: 2rem;
    }
    &_h3 {
      font-size: 5rem;
      font-weight: bold;
      @media (--sm-max) {
        font-size: 3rem;
      }
    }
    &_link {
      max-width: 100%;
      width: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.3rem;
      color: var(--color--white);
      background: var(--color--red);
      padding: 8px 10px;

      &:hover {
        color: var(--color--white);
      }
    }
  }
}