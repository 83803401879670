.l-globalDrawer {
  width: 300px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 110;
  background: var(--color--black);
  overflow-y: scroll;
  padding: 50px 0;

  &__nav {
    margin-bottom: 30px;
  }

  &__title {
    border-bottom: solid 1px var(--color--border-darkgray);
    font-size: 1.6rem;
    color: #fff;
    padding: 5px 15px;
    margin-bottom: 10px;

    &--link {
      display: flex;
      align-items: flex-end;
    }
    a&__link {
      font-size: 1.4rem;
      color: var(--color--red);
      margin-left: 10px;

      i.fa {
        margin-right: 5px;
      }
    }
  }

  &__list {
  }

  &__item {
  }

  a&__link {
    display: block;
    color: #fff;
    padding: 5px 15px;
  }

  &__news {
    margin-bottom: 30px;

    &__list {

    }

    &__item {}

    a&__link {
      display: block;
      padding: 10px 15px;
    }

    &__date {
      display: block;
      font-size: 1.2rem;
      color: var(--color--red);
    }

    &__title {
      display: block;
      color: #fff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

  }
}

