/*
c-underLineTitle

アンダーラインタイトルコンポーネントです。

markup:
<h2 class="c-underLineTitle">アンダーラインタイトル</h2>

Styleguide 26.3.0
 */

.c-underLineTitle {
  font-size: 2rem;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--color--darkgray);

  @media (--sm) {
    font-size: 2.8rem;
  }
}
