/*
c-snsNav

SNSナビゲーションコンポーネントです。<br>
※スタイルガイドでは表示されません

markup:
<nav class="c-snsNav">
  <ul class="c-snsNav__list">
    <li class="c-snsNav__item">
      <a target="_blank" class="c-snsNav__link c-snsNav__link--facebook" href="http://www.facebook.com/sharer.php?u=http://g1-test.bgp5.info/video/174/&amp;t=【個人撮影】超巨乳【もちもちJカップ】オマタ全開！おっぱい揺らして大量潮吹き！【素人ハメ撮り】【高画質】" title="">
      <i class="fab fa-facebook-square"></i>
    </a>
    </li>
    <li class="c-snsNav__item">
      <a target="_blank" class="c-snsNav__link c-snsNav__link--twitter icon-twitter" href="http://twitter.com/home?status=http://g1-test.bgp5.info/video/174/" title="">
      <i class="fab fa-twitter-square"></i>
    </a>
    </li>
    <li class="c-snsNav__item">
      <a target="_blank" class="c-snsNav__link c-snsNav__link--icon-google-plus" href="https://plus.google.com/share?url=http://g1-test.bgp5.info/video/174/" title="">
      <i class="fab fa-google-plus-square"></i>
    </a>
    </li>
    <li class="c-snsNav__item">
      <a target="_blank" class="c-snsNav__link c-snsNav__link--linkedin icon-linkedin" href="http://www.linkedin.com/shareArticle?mini=true&amp;url=http://g1-test.bgp5.info/video/174/" title="">
      <i class="fab fa-linkedin"></i>
    </a>
    </li>
    <li class="c-snsNav__item">
      <a target="_blank" class="c-snsNav__link c-snsNav__link--whatsapp icon-whatsapp" href="whatsapp://send?text=確認してください:  http://g1-test.bgp5.info/video/174/" data-action="share/whatsapp/share"></a>
    </li>
    <li class="c-snsNav__item">
      <div class="c-snsNav__link c-snsNav__link--fblike fb-like" data-href="http://g1-test.bgp5.info/video/174/" data-width="124" data-layout="button_count" data-action="like" data-show-faces="true" data-share="true"></div>
    </li>
  </ul>
</nav><!-- /.p-videoDetail__snsNav -->

Styleguide 17.9.0
 */

.c-snsNav {
  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    line-height: 1;

    &:not(:last-of-type) {
      margin-right: 15px;

      @media (--sm) {
        margin-right: 20px;
      }
    }

    &--fblike {
      margin-left: auto;
    }
  }

  &__link {
    font-size: 3.6rem;

    @media (--sm) {
      font-size: 4.6rem;
    }

    &.fb_iframe_widget span {
      vertical-align: inherit !important;
    }
  }
}
