.p-cp {
  position: relative;
  &__inner {
    margin: 0 auto;
    max-width: 1460px;
  }
  &__head {
    display: flex;
    align-items: center;
    background: #00001e;
    &Tt {
      margin: 0 auto;
    }
    &Img {
      &--pc {
        display: block;
        @media (--sm-max) {
          display: none;
        }
      }
      &--sp {
        display: none;
        @media (--sm-max) {
          display: block;
        }
      }
    }
  }
  &__intro {
    margin: 90px 20px;
    @media (--sm-max) {
      margin: 30px 20px 60px;
    }
    &Tt {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
    }
    &SubTt {
      font-size: 2.2rem;
      line-height: 2.4;
      text-align: center;
      font-weight: bold;
      margin-bottom: 30px;
      @media (--md-max) {
        font-size: 1.6rem;
      }
      @media (--sm-max) {
        font-size: 1.5rem;
        line-height: 2;
        text-align: left;
      }
    }
    &Video {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
    }
    &Talent {
      display: flex;
      flex-direction: column;
      align-items: center;

      &Img {
        margin-bottom: 20px;
      }

      &H3 {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 10px;
      }
      &Text {
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.8;

        @media (--sm-max) {
          font-size: 1.4rem;
          line-height: 2;
          text-align: left;
        }
      }
    }
  }

  &__method {
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 90px 20px;

    @media (--sm-max) {
      padding: 60px 0;
    }

    &::before {
      position: absolute;
      content: url(/tpl/g1/img/ja/coupon/cp_downBtn.png);
      top: -40px;
      left: center;
    }
  }

  &__sale {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: #e2e2e2;
    padding: 90px 20px;

    @media (--md-max) {
      flex-direction: column;
    }

    @media (--sm-max) {
      padding: 60px 20px;
    }

    &::before {
      position: absolute;
      content: url(/tpl/g1/img/ja/coupon/cp_downBtn.png);
      top: -40px;
      left: center;
    }

    &::after {
      position: absolute;
      content: url(/tpl/g1/img/ja/coupon/cp_bgGirl@2x.png);
      bottom: -662px;
      right: -365px;
      transform: scale(0.5);
      transform-origin: 0 0;
      @media (--sm-max) {
        transform: scale(0.35);
        bottom: -537px;
        right: -474px;
      }
    }

    &Link {
      &--minario {
        display: block;
        margin: 0 40px 30px;
        z-index: 2;

        @media (--sm-max) {
          margin: 0 0 30px;
        }
      }
      &--mihoudai {
        display: block;
        margin: 0 40px 30px;
        z-index: 2;
        @media (--sm-max) {
          margin: 0 0 30px;
        }
      }
    }
  }

  &__Terms {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 90px 20px;
    background: #f3f3f3;

    @media (--sm-max) {
      padding: 60px 20px;
      flex-wrap: wrap;
    }

    &::before {
      position: absolute;
      content: url(/tpl/g1/img/ja/coupon/cp_downBtn.png);
      top: -40px;
      left: center;
    }
    &H2 {
      font-size: 4rem;
      margin-bottom: 40px;

      @media (--sm-max) {
        font-size: 2.4rem;
        margin-bottom: 20px;
      }
    }
    &Item {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1460px;
    }

    &Items {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      width: calc(25% - 80px);
      margin: 0 40px;

      @media (--md-max) {
        width: calc(33.3333% - 40px);
        margin: 0 20px;
      }

      @media (--sm-max) {
        width: 100%;
        margin-bottom: 30px;
      }
      &:last-child {
        @media (--sm-max) {
          margin-bottom: 0;
        }
      }
    }

    &H3 {
      margin-top: 30px;
      font-size: 1.4rem;
      line-height: 2;

      @media (--sm-max) {
        margin-top: 20px;
        font-size: 1.4rem;
        line-height: 2;
      }
    }
  }

  &__attention {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 90px 0;
    position: relative;

    @media (--sm-max) {
      padding: 60px 20px;
    }

    &::before {
      position: absolute;
      content: url(/tpl/g1/img/ja/coupon/cp_downBtn.png);
      top: -40px;
      left: center;
    }
    &H2 {
      font-size: 4rem;
      margin-bottom: 30px;

      @media (--sm-max) {
        font-size: 2.4rem;
      }
    }
    &Item {
      background: #f1f1f1;
      border-radius: 10px;
      padding: 50px 50px 50px 70px;
      list-style-type: decimal;
      margin-top: 30px;

      @media (--sm-max) {
        padding: 20px 20px 20px 40px;
      }
    }
    &Items {
      font-size: 1.4rem;
      line-height: 2;
    }
    &St {
      color: red;
      font-weight: bold;
    }
  }

  &__howto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 90px 20px;
    position: relative;
    background: #f1f1f1;
    @media (--sm-max) {
      padding: 60px 20px;
    }

    &::before {
      position: absolute;
      content: url(/tpl/g1/img/ja/coupon/cp_downBtn.png);
      top: -40px;
      left: center;
    }

    &H2 {
      font-size: 4rem;
      margin-bottom: 30px;

      @media (--sm-max) {
        font-size: 2.4rem;
        margin-bottom: 30px;
      }
    }
    &Item {
      border-radius: 10px;
      list-style-type: decimal;
      margin-bottom: 30px;
    }
    &Items {
      font-size: 1.8rem;
      line-height: 2;
      @media (--sm-max) {
        font-size: 1.4rem;
        margin-bottom: 10px;
      }
    }
    &Img {
      filter: drop-shadow(2px 2px 2px #ccc);
    }
  }

  /*決済ページ*/
  &__ad {
    background: #fee;
    border-radius: 8px;
    text-align: center;
    margin: 20px 10px 10px 10px;
    padding: 30px 25px;
    &Tt {
      margin-bottom: 5px;
      font-size: 2rem;
      font-weight: bold;
      color: red;
      @media (--sm-max) {
        font-size: 1.7rem;
      }
    }
    &Link {
      font-size: 1.4rem;
      font-weight: bold;
      padding: 8px 15px;
      color: red;
      border: 2px solid red;
      margin-top: 10px;
      display: inline-block;
      background: #fff;

      &:hover {
        color: #ffb6b6;
        background: #fff;
      }
    }
  }

  &__fix {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    padding: 10px 10px;
    z-index: 2;

    &Link {
      &--minario {
        display: block;
        margin: 0 10px;
        z-index: 2;
        @media (--sm-max) {
          margin: 10px;
        }
      }
      &--mihoudai {
        display: block;
        margin: 0 10px 0px;
        z-index: 2;
        @media (--sm-max) {
          margin: 10px;
        }
      }
    }
  }
}
