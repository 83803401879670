.p-mr {
  position: relative;
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffd8d9;
    background-image: url(/tpl/g1/img/ja/minario/mr_headerTtBg.png);
    background-size: cover;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    &Tt {
      margin: 60px 0;

      @media (--sm-max) {
        margin: 30px 0;
      }
    }

    &Box {
      background-image: url(/tpl/g1/img/ja/minario/mr_infoBg.png);
      background-position: center center;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    &Box001 {
      background-image: url(/tpl/g1/img/ja/minario/mr_infoBg001.png);
      background-position: center center;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    &Video {
      margin-bottom: 60px;

      @media (--sm-max) {
        margin-bottom: 30px;
      }
      .video-js {
        @media (--sm-max) {
          width: 320px !important;
        }
      }
    }
    &Text {
      font-size: 1.8rem;
      line-height: 3;
      text-align: center;
      margin-bottom: 90px;
      white-space: pre-wrap;

      @media (--sm-max) {
        font-size: 1.4rem;
        line-height: 2;
        margin-bottom: 60px;
        white-space: normal;
        text-align: left;
      }
    }
  }

  &__gallery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #f1f1f1;
    padding: 90px 20px;

    @media (--sm-max) {
      padding: 60px 20px;
    }

    &::before {
      position: absolute;
      content: url(/tpl/g1/img/ja/minario/mr_ya.png);
      top: -40px;
      left: center;
    }

    &Tt {
      background: var(--color--black);
      color: var(--color--white);
      padding: 5px 15px;
      font-size: 1.6rem;
      border-radius: 5px;
      margin: 0 0 60px;

      @media (--sm-max) {
        margin: 0 0 30px;
      }
    }
    &Box {
      width: 100%;

      & .slick-next {
        right: -10px;
        @media (--sm-max) {
          right: -10px;
        }
      }
      & .slick-prev {
        left: -10px;
        @media (--sm-max) {
          left: -10px;
        }
      }
    }

    &Items img {
      width: 200px;
      border-radius: 10px;
      margin: 0 10px;
    }
  }
  &__buy {
    &--pink {
      position: relative;
      background-image: url(/tpl/g1/img/ja/minario/mr_buybg1.gif);
      background-size: cover;
      padding: 90px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (--sm-max) {
        padding: 60px 20px 30px;
      }
      &::before {
        position: absolute;
        content: url(/tpl/g1/img/ja/minario/mr_ya.png);
        top: -40px;
        left: center;
      }
    }
    &Bt {
      position: relative;
      &::before {
        position: absolute;
        content: url(/tpl/g1/img/ja/minario/mr_buyGirl1.png);
        right: -150px;
        bottom: 0;
        z-index: 2;
        @media (--md-max) {
          right: -20px;
          bottom: -50px;
        }
        @media (--sm-max) {
          transform: scale(0.35);
          right: -100px;
          bottom: -220px;
        }
      }
    }
  }

  &__buy2 {
    &--gleen {
      position: relative;
      background-image: url(/tpl/g1/img/ja/minario/mr_buybg2.gif);
      background-size: cover;
      padding: 90px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (--sm-max) {
        padding: 60px 20px 30px;
      }
      &::before {
        position: absolute;
        content: url(/tpl/g1/img/ja/minario/mr_ya.png);
        top: -40px;
        left: center;
      }
    }
    &Bt {
      position: relative;
      &::before {
        position: absolute;
        content: url(/tpl/g1/img/ja/minario/mr_buyGirl2.png);
        right: -150px;
        bottom: 0;
        z-index: 2;
        @media (--md-max) {
          right: -20px;
          bottom: -50px;
        }
        @media (--sm-max) {
          transform: scale(0.35);
          right: -100px;
          bottom: -220px;
        }
      }
    }
  }

  &__coupon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #00001e;

    &Box {
      background-image: url(/tpl/g1/img/ja/minario/mr_buyBg3.png);
      background-position: center center;
      width: 100%;
      position: relative;
      background-size: cover;
      padding: 90px 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (--sm-max) {
        padding: 30px 20px;
      }

      &::before {
        position: absolute;
        content: url(/tpl/g1/img/ja/minario/mr_ya001.png);
        top: -40px;
        left: center;

        @media (--sm-max) {
          display: none;
        }
      }

      &::after {
        position: absolute;
        content: url(/tpl/g1/img/ja/minario/mr_buyBg3Ft.png);
        bottom: -7px;
        left: center;

        @media (--md-max) {
          display: none;
        }
      }
      &Link {
        z-index: 2;
      }
    }
  }

  &__contents {
    padding: 60px 20px 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (--sm-max) {
      padding: 30px 20px 60px;
    }

    &Tt {
      margin-bottom: 30px;
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    padding: 10px 10px;
    z-index: 2;

    &Bt {
      display: block;
      margin: 0 10px;
      z-index: 2;
      @media (--sm-max) {
        margin: 10px;
      }
    }
  }
}
