.p-videolist {
  &__videosample {
    display: none;
    border: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #0e0e0e;
    &__link {
      width: 100%;
      height: 100%;
      border: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    &__progress {
      display: none;
      background: #000;
      bottom: 0;
      height: 2px;
      left: 0;
      margin-bottom: 0;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 99999;
    }
    &__bar {
      width: 0%;
      background: #de2600;
      height: 2px;
    }
  }
}
