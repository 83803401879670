/*
c-icons

オリジナルアイコンコンポーネントです。

markup:
<i class="c-icons--yen"></i>
<a class="c-icons--circle" href="#" title="削除"><i class="fa fa-trash"></i></a>
<a class="c-icons--circle c-icons--red" href="#" title="削除"><i class="fa fa-trash"></i></a>

Styleguide 12.1.0
 */

.c-icons {
  &--yen {
    display: inline-block;
    vertical-align: 1px;

    &::before {
      width: 13px;
      height: 13px;
      content: "￥";
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color--black);
      font-size: 1.1rem;
      color: #fff;
      line-height: 1;
      border-radius: 2px;
    }

    &.c-icons--lg {
      &::before {
        width: 20px;
        height: 20px;
        font-size: 1.5rem;
        border-radius: 3px;
        margin-right: 3px;
      }
    }

    &.c-icons--xl {
      &::before {
        width: 28px;
        height: 28px;
        font-size: 1.6rem;
        border-radius: 3px;
      }
    }
  }

  &--circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border: solid 1px var(--color--black);
    border-radius: 50%;
    font-size: 2.0rem;

    &.c-icons--red {
      color: var(--color--red);
      border: solid 1px var(--color--red);
    }
  }
}
