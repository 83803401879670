/*
c-headerCounter

ヘッダーの商品数カウンターコンポーネントです。

markup:
<dl class="c-headerCounter">
	<dt class="c-headerCounter__term">商品点数</dt>
	<dd class="c-headerCounter__description"><span>145</span>点</dd>
</dl><!-- /.l-header__counter -->

Styleguide 6.1.0
 */

.c-headerCounter {
  display: flex;

  &__term {
    font-weight: normal;
    white-space: nowrap;
    margin-right: 10px;

    @media (--sm-only) {
      font-size: 1.3rem;
    }
  }

  &__description {
    white-space: nowrap;

    span {
      display: inline-block;
      color: var(--color--red);
      margin-right: 5px;

      @media (--sm-only) {
        font-size: 1.3rem;
      }
    }
  }
}
