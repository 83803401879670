.l-myPageSidebar {
  width: 100%;
  background-color: var(--color--gray);
  border-left: solid 1px var(--color--darkgray);
  padding: 45px 3% 50px;

  @media (--lg) {
    width: 360px;
    min-width: 360px;
    padding: 45px 40px 50px;
  }

  &__returnToTop {
    a&__link {
      display: block;
    }
  }

  &__profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__avator {
    width: 150px;
    margin-bottom: 30px;
  }

  &__name {
  }

  &__counterWrapper {
  }

  &__counterTable {
    th {
      width: 110px;
    }
  }

  &__nav {
  }

  &__navTitle {
    font-size: 2.4rem;
    line-height: 1;
    margin-bottom: 12px;
  }

  &__list {
  }

  &__item {
    &:not(:last-of-type) {
      margin-bottom: 7px;
    }
  }

  &__link {
    display: block;
    padding: 3px 0;
  }
}
