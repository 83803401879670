/*
c-headerSiteTitle

ヘッダーサイトタイトルコンポーネントです。

markup:
<h1 class="l-header__siteTitle c-headerSiteTitle">すぐ買ってすぐ見れる！動画ダウンロードサイト G1</h1><!-- /.l-header__siteTitle -->

Styleguide 26.2.0
 */

.c-headerSiteTitle {
  @media (--sm-only) {
    font-size: 1.3rem;
  }
}
