.l-wrapper {
  position: relative;
  z-index: 1;

  &--col1 {
    padding: 45px 3% 50px;

    @media (--sm) {
      display: flex;
      justify-content: center;
    }

    .l-main {
      width: 100%;
    }
  }

  &--col2Left {
    display: flex;
    flex-direction: column;

    @media (--sm) {
      flex-direction: row;
    }

    .l-main {
      width: 100%;
      padding: 45px 3% 50px;

      @media (--sm-max) {
        order: 1;
      }

      @media (--sm) {
        width: 100%;
        flex-grow: 1;
        padding-left: 50px;
        padding-right: 50px;
      }
    }

    .l-sidebar {
      @media (--sm-max) {
        order: 2;
      }
    }
  }

  &--col2Right {
    display: flex;
    flex-direction: column;

    @media (--lg) {
      flex-direction: row;
    }

    .l-main {
      width: 100%;
      overflow: hidden;
      padding: 45px 0 50px;
      border-right: solid 1px var(--color--darkgray);

      @media (--lg) {
        flex-grow: 1;
      }
    }
  }

  &--col3 {
    display: flex;
    flex-direction: column;
    padding: 45px 0 50px;

    @media (min-width: 851px) {
      flex-direction: row;
    }

    .l-main {
      width: 100%;
      padding: 0 3%;

      @media (min-width: 851px) {
        flex-grow: 1;
        padding: 0;
        margin: 0 30px;
      }

      @media (max-width: 850px) {
        order: 1;
      }
    }

    .l-sidebar {
      width: 100%;

      @media (min-width: 851px) {
        width: 230px;
        min-width: 230px;
      }

      &--left {
        @media (max-width: 850px) {
          order: 2;
        }
      }

      &--right {
        @media (max-width: 850px) {
          order: 3;
        }
      }
    }
  }

  &--w900 {
    &.l-wrapper--col1 {
      @media (--sm) {
        padding-left: 0;
        padding-right: 0;

        .l-main {
          width: 900px;
          padding-left: 65px;
          padding-right: 65px;
        }
      }
    }
  }

  &--w1200 {
    &.l-wrapper--col1 {
      @media (--sm) {
        padding-left: 0;
        padding-right: 0;

        .l-main {
          max-width: 1200px;
          width: 100%;
          padding-left: 65px;
          padding-right: 65px;
        }
      }
    }
  }

  &--space65 {
    padding: 0 3% 0;

    @media (--sm) {
      padding: 0 65px 0;
    }
  }

  &--noSpace {
    padding: 45px 0 50px;

    @media (--sm) {
      padding: 45px 0 50px;
    }
  }

  &--space45 {
    padding: 45px 0 0;

    @media (--sm) {
      padding: 45px 0 0;
    }
  }
}
