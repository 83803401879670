div.tagsinput {
  min-height: 50px !important;
  border: solid 1px var(--color--form-gray);
  border-radius: 3px;
  padding: 6px 8px 0;

  span.tag {
    height: 23px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: solid 1px var(--color--form-gray);
    color: var(--color--text-gray);
    line-height: 1;
    margin-top: 6px;

    a {
      width: 11px;
      height: 11px;
      float: none;
      background-color: var(--color--black);
      border-radius: 2px;
      color: #fff;
      line-height: 11px;
      text-align: center;
    }
  }

  input {
    width: 131px !important;
  }
}
