/*
c-hamburger

ハンバーガーコンポーネントです。

markup:
<div class="l-header__hamburger c-hamburger" style="display: block !important;">
  <a href="javascript:void(0)" id="js-hamburger" class="c-hamburger__link" aria-controls="js-globalDrawer" role="button">
    <i class="c-hamburger__bar"></i>
    <i class="c-hamburger__bar"></i>
    <i class="c-hamburger__bar"></i>
  </a>
</div><!-- /.l-header__hamburger -->

Styleguide 9.1.0
 */

.c-hamburger {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  margin-right: 8px;
  margin-left: -8px;

  &__link {
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  &__bar {
    display: block;
    width: 26px;
    height: 3px;
    background-color: var(--color--black);
    border-radius: 3px;
    transition: all 0.4s;

    &:first-of-type {
      transform: translateY(-7px) rotate(0deg);
    }

    &:last-of-type {
      transform: translateY(7px) rotate(0deg);
    }
  }
}
