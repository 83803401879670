.p-prepare {
  padding: 25px 0 20px;

  &__title {
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 60px;
  }

  &__description {
    font-size: 1.5rem;
    margin-bottom: 30px;

    @media (--sm-max) {
      line-height: 2.0rem;
    }

    @media (--sm) {
      font-size: 1.6rem;
      text-align: center;
    }
  }

  &__annotation {
    font-size: 1.6rem;

    &Frame {
      border: solid 3px var(--color--black);
      padding: 25px;

      @media (--sm) {
        padding: 20px 45px;
      }
    }
  }
}
