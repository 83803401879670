/*
c-footerLogo

フッターロゴコンポーネントです。

markup:
<div class="c-footerLogo">
  <a href="/" class="c-footerLogo__link">
    <img src="http://g1-test.bgp5.info/tpl/g1/img/ja/site_logo.png" alt="xxx" class="footerLogo__img">
  </a>
</div><!-- /.l-footer__logo -->

Styleguide 16.1.0
 */

.c-footerLogo {
  width: 144px;
  margin-bottom: 20px;

  @media (--sm-max) {
    margin: 0 auto 30px;
  }

  a {
    display: block;
  }
}
