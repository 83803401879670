.p-myPageBox {
  background-color: var(--color--gray);
  padding: 30px;
  margin: 30px 0;
  border-radius: 5px;
}

.p-myPage {
  &__inner {
    padding: 0 3%;

    @media (--sm) {
      max-width: 1160px;
      padding: 0 65px;
      margin: 0 auto;
    }
  }

  &__title {
    font-size: 2rem;
    margin-bottom: 25px;

    @media (--sm) {
      font-size: 2.8rem;
      margin-bottom: 10px;
    }
  }

  &__nav {
    margin-bottom: 45px;

    @media (--sm) {
      margin-bottom: 30px;
    }
  }

  &__main {
  }

  &__myData {
    margin-bottom: 50px;

    &--manager {
      @media (--sm) {
        margin-top: 55px;
      }
    }

    &Controls {
      display: none;

      @media (--sm) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-bottom: solid 1px var(--color--gray);
        padding-bottom: 10px;
      }

      &__change {
        margin-right: 131px;

        button&Btn {
          height: 30px;
        }

        .fa {
          margin-right: 3px;
        }
      }

      &__checkbox {
        margin-right: 10px;
      }

      &__trash {
        button&Btn {
          height: 30px;
        }

        .fa {
          margin-right: 3px;
        }
      }
    }

    &__list {
    }
  }

  &__formGroup {
    margin-bottom: 30px;

    &__bottomGroup {
      display: flex;
      align-items: flex-end;
    }

    &__inputWrapper {
      width: 100%;
      flex-grow: 1;
      margin: 0;
    }

    &__btn {
      width: 150px;
      min-width: 150px;
      margin-left: 30px;
      height: 50px;

      @media (--sm-max) {
        width: 100px;
        min-width: 100px;
        margin-left: 20px;
      }
    }

    &--videoUploader {
      .MaxUpload_Box .Maxuploader {
        margin: 0;
        border-bottom-width: 2px;
        border-bottom-color: #7fc7f3;

        input[type="file"] {
          left: 10px;
          width: calc(100% - 20px);
        }
      }
    }
  }

  &__formLabel {
    display: block;
    margin-bottom: 10px !important;
  }

  &__reviewer {
    max-width: 100%;
    border-radius: 3px;
    padding: 10px 5px;
  }

  &__radioGroup {
    display: flex;

    &__item {
      &:not(:first-of-type) {
        margin-left: 20px;
      }
    }
  }

  &__formThumbnail {
    display: flex;
    align-items: center;

    &__img {
      width: 80px;
      min-width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color--gray);
      margin-right: 15px;

      @media (--xs) {
        width: 100px;
        min-width: 100px;
        height: 100px;
      }

      img {
        max-width: 80px !important;
        max-height: 80px !important;
        margin-bottom: 0 !important;

        @media (--xs) {
          max-width: 100px !important;
          max-height: 100px !important;
          margin-bottom: 0 !important;
        }
      }
    }

    &__file {
      flex-grow: 1;
      width: 100%;
    }
  }

  &__formEditBtn {
    width: 150px;
  }

  &__addFileGroup {
    background-color: var(--color--danger);
    padding: 20px;
    border-radius: 7px;
  }

  &__inputFile {
    &Wrapper {
      position: relative;
    }
  }

  &__formFileUploader {
    &Btn {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1;
      background-color: var(--color--black);
      white-space: nowrap;
      color: var(--color--white);
      -webkit-appearance: none;
      border: none;
      border-radius: 4px;
      padding: 7px 12px;
    }

    &Icon {
      font-size: 1.6rem;
      margin-right: 5px;
    }
  }

  &__videoPreview {
    width: 100%;
    max-width: 854px;
    position: relative;
    margin-bottom: 20px;

    &::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 56%;
    }

    .video-js {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .vjs-big-play-button {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__videoTable {
    margin-bottom: 40px;
    .c-colonSeparatorTable__th,
    .c-colonSeparatorTable__td {
      font-size: 1.2rem;
      padding: 3px 0;
    }

    .c-colonSeparatorTable__td--colon {
      padding-left: 15px;
    }

    .c-colonSeparatorTable__th {
      width: 85px;
    }
  }
}

.p-myPageLater,
.p-myPageHistory,
.p-myPagePossession,
.p-myPageManager {
  .p-myPage__nav {
    margin-bottom: 0;
  }
}

.p-myPageLater {
}

.p-myPageHistory {
}

.p-myPagePossession {
}

.p-myPageSetting {
  &__avatarForm {
    &__img {
      display: flex;
      justify-content: center;
      padding: 0 0 30px;

      @media (--sm) {
        padding: 20px 0 30px;
      }
    }

    &__bottomGroup {
      @media (--sm) {
        display: flex;
        align-items: flex-end;
      }
    }

    &__inputWrapper {
      margin-bottom: 30px;

      @media (--sm) {
        width: 100%;
        flex-grow: 1;
        margin: 0;
      }
    }

    &__inputFile {
      &Wrapper {
        position: relative;
      }
    }

    button&__btn {
      height: 50px;
      width: 100%;

      @media (--sm) {
        width: 150px;
        min-width: 150px;
        margin-left: 30px;
      }
    }
  }

  &__snsForm {
    @media (--sm) {
      padding: 15px 0;
    }

    &__labelWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      @media (--sm) {
        margin-bottom: 15px;
      }

      i.fa {
        margin-right: 5px;
      }
    }

    &__inputWrapper {
      .hint {
        font-size: 1.3rem;
        background: #fff;
        border: solid 1px var(--color--gray);
        border-radius: 5px;
        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3);
        padding: 5px;
        margin-top: 3px;
        z-index: 10;
      }
    }

    &__btn {
      width: 150px;
    }
  }

  &__passwordForm {
    @media (--sm) {
      padding: 20px 0 20px;
    }

    &__btn {
      width: 100%;

      @media (--sm) {
        width: 150px;
      }
    }
  }

  &__editForm {
    @media (--sm) {
      padding: 20px 0 20px;
    }

    &__btn {
      width: 100%;

      @media (--sm) {
        width: 150px;
      }
    }
  }

  &__inputFile {
    &Wrapper {
      position: relative;
    }
  }
  /* 設定画面内のメルマガ設定 */
  &__mailmg {
    &__settingCont {
      margin-bottom: 24px;
    }
    &__subtitle {
      display: block;
      font-weight: 700;
      margin-bottom: 10px;
    }
    &__crnt {
      font-weight: 700;
      margin-bottom: 16px;
    }
    &__crntmailForm {
      display: block;
      @media (--sm) {
        margin-top: 16px;
        display: flex;
      }
    }
    &__crntmailForm button {
      margin-left: 0;
      margin-top: 8px;
      @media (--sm) {
        margin-left: 8px;
        margin-top: 0;
      }
    }
    &__crntmailLabel {
      display: inline-block;
      font-size: 1.2rem;
      padding: 2px 4px;
      border-radius: 2px;
      margin-right: 4px;
      border: 1px solid var(--color--black);
    }
    &__crntmailaddress {
      width: 100%;
      margin-top: 8px;
      @media (--sm) {
        display: inline-block;
      }
    }
    &__radio {
      display: block;
      @media (--sm) {
        display: flex;
      }
    }
    &__radio .p-myPage__radioGroup__item:not(:first-of-type) {
      margin-left: 0;
      @media (--sm) {
        margin-left: 20px;
      }
    }
    &__error {
      color: var(--color--red);
      font-weight: bold;
    }
  }
}

.p-myPageUpload {
  &__formGroup {
    .ui-autocomplete-input {
      width: 131px !important;
    }
  }

  &__controls {
  }

  &__thumbnailGroup {
    display: flex;
    align-items: center;
  }

  &__thumbnailRadio {
    width: 190px;
    min-width: 190px;
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  &__thumbnailFile {
    flex-grow: 1;
    display: flex;
    align-items: center;
    position: relative;

    &__label {
      font-weight: normal;
      width: 180px;
    }
  }
}
