/*
c-accountForm

ログイン・入会ページ用のフォームコンポーネントです。

markup:
<section class="c-accountForm">
  <header class="c-accountForm__header">
    <div class="c-accountForm__logo"><img src="http://g1-test.bgp5.info/tpl/g1/img/ja/site_logo.png" alt="xxx"></div>
    <h2 class="c-accountForm__title">ログイン</h2>
  </header><!-- /.c-accountForm__header -->
  <div class="c-accountForm__body">
    <div class="c-accountForm__textFormGroup">
      <form class="form form-block" method="POST" action="">
        <fieldset class="c-accountForm__fieldset">
          <div class="c-accountForm__formItem">
            <div class="c-accountForm__labelGroup">
            <label for="UserName" class="c-accountForm__label">メールアドレス</label>
          </div>
          <input type="email" name="email" id="login_email" class="c-accountForm__input" placeholder="adress@example.com" required>
          </div>
          <div class="c-accountForm__formItem">
            <div class="c-accountForm__labelGroup">
            <label for="email" class="c-accountForm__label">パスワード</label>
            <div class="c-accountForm__recover">
            パスワードを忘れた方は<a href="/recover" class="c-accountForm__recoverLink">こちら</a>                  </div>
          </div>
          <input type="password" name="password" id="login_password" class="c-accountForm__input" required>
          <span class="form-attention"></span>
          </div>
          <div class="c-accountForm__formItem">
            <label class="c-accountForm__label">
            <input type="checkbox" name="remember" id="remember" class="c-accountForm__checkbox">保持する                </label>
          </div>
          <button id="login_button" type="submit" class="c-accountForm__submitBtn c-accountForm__submitBtn--login c-btn c-btn--outline c-btn--red">ログインする</button>
        </fieldset>
      </form>
    </div><!-- /.c-accountForm__textFormGroup -->
    <div class="c-accountForm__socialFormGroup">
      <div class="c-accountForm__social">
        <ul class="c-accountForm__socialList">
          <li class="c-accountForm__socialItem c-accountForm__socialItem--facebook">
            <a href="xxx" class="c-accountForm__socialBtn c-btn c-btn--facebook c-btn--lg c-btn--block">
            <i class="fab fa-facebook-square"></i>Facebookでログインする</a>
          </li>
          <li class="c-accountForm__socialItem c-accountForm__socialItem--googleplus">
            <a href="xxx" class="c-accountForm__socialBtn c-btn c-btn--googleplus c-btn--lg c-btn--block">
            <i class="fab fa-google-plus-g"></i>Google＋でログインする</a>
          </li>
        </ul>
      </div><!-- /.c-accountForm__social -->
      <div class="c-accountForm__annotation">
        <ul class="c-accountForm__annotationList">
          <li class="c-accountForm__annotationItem">お持ちのFacebook, Google＋アカウントでかんたんにログインすることができます。</li>
          <li class="c-accountForm__annotationItem">xxxではFacebook, Google＋を認証手段としてのみ利用し、投稿や「いいね!」の操作は行いません。</li>
          <li class="c-accountForm__annotationItem">ソーシャルアカウントとお持ちでない方はメールアドレスよりログインしてください。</li>
        </ul>
      </div><!-- /.c-accountForm__annotation -->
    </div><!-- /.c-accountForm__socialFormGroup -->
  </div><!-- .c-accountForm__body -->
  <footer class="c-accountForm__footer">
    <div class="c-accountForm__unregistered">
      <p class="c-accountForm__unregisteredText">
    まだxxxに登録がお済みでない方は<a href="/signup" class="c-accountForm__unregisterdLink">こちら</a>          </p>
    </div><!-- /.c-accountForm__unregistered -->
  </footer><!-- /.c-accountForm__footer -->
</section><!-- /.c-accountForm -->

Styleguide 7.1.0
 */

.c-accountForm {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  &__logo {
    width: 180px;
    margin-bottom: 25px;
  }

  &__title {
    font-size: 2.4rem;
    font-weight: bold;

    @media (--sm) {
      font-size: 2rem;
    }
  }

  &__body {
    display: flex;
    margin-bottom: 45px;

    @media (--sm-max) {
      flex-direction: column;
    }

    @media (--sm) {
      justify-content: space-between;
    }
  }

  &__textFormGroup {
    @media (--sm-max) {
      order: 2;
    }

    @media (--sm) {
      width: 50%;
      min-width: 50%;
      border-right: solid 1px var(--color--darkgray);
      padding-right: 30px;
    }
  }

  &__socialFormGroup {
    @media (--sm-max) {
      order: 1;
      border-bottom: solid 1px var(--color--darkgray);
      padding-bottom: 20px;
      margin-bottom: 20px;
    }

    @media (--sm) {
      width: 50%;
      min-width: 50%;
      padding: 25px 0 20px 30px;
    }
  }

  &__formItem {
    margin-bottom: 25px;
  }

  &__label {
    &--check {
    }
  }

  &__labelGroup {
    display: flex;
    height: 25px;

    .c-accountForm__label {
      margin-right: 18px;
    }
  }

  &__input {
    width: 100%;
    height: 50px;
    padding: 5px 10px;
  }

  &__checkbox {
    &--none {
      display: none;
      width: 20px;
      height: 20px;

      & + label {
        padding-left: 30px;
        position: relative;
        margin-right: 30px;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          border: 1px solid #999;
        }
      }

      &:checked + label {
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 3px;
          left: 3px;
          width: 14px;
          height: 14px;
          background: var(--color--red);
        }
      }
    }
  }

  button&__submitBtn {
    width: 100%;
    height: 55px;
    border: solid 4px var(--color--red) !important;
    font-size: 1.8rem;
    margin-top: 30px;

    &--login {
      @media (--sm) {
        width: 180px;
      }
    }
  }

  &__social {
    &List {
    }

    &Item {
      &--fc2 {
        margin-bottom: 10px;
      }

      &--facebook {
        margin-bottom: 10px;
      }

      &--googleplus {
        margin-bottom: 10px;
      }

      &--twitter {
        margin-bottom: 20px;
        @media (--sm) {
          margin-bottom: 50px;
        }
      }
    }

    &Btn {
    }
  }

  &__recover {
    color: var(--color--text-gray);
    font-size: 1.3rem;

    a&Link {
      color: var(--color--text-gray);
    }
  }

  &__annotation {
    &List {
    }

    &Item {
      font-size: 1.3rem;
      display: flex;

      &:not(:last-of-type) {
        margin-bottom: 30px;
      }

      &::before {
        content: "※";
        width: 1em;
        min-width: 1em;
      }
    }
  }

  &__unregistered {
    &Text {
      text-align: center;
      font-size: 1.6rem;
    }
  }

  &__caution {
    border: solid 4px var(--color--black);
    padding: 20px;
    margin-top: 40px;

    @media (--sm) {
      padding: 20px 45px;
      margin-top: 30px;
    }

    &Text {
      font-size: 1.6rem;
    }
  }
}
