/*
c-outlineNav

アウトラインナビゲーションコンポーネントです。

markup:
<nav class="c-outlineNav">
  <ul class="c-outlineNav__list">
    <li class="c-outlineNav__item">
      <a href="/me?sk=history" class="c-outlineNav__link c-outlineNav__link--active">視聴履歴</a>
    </li>
    <li class="c-outlineNav__item">
      <a href="/me?sk=later" class="c-outlineNav__link">後で見る</a>
    </li>
    <li class="c-outlineNav__item">
      <a href="/me?sk=possession" class="c-outlineNav__link">購入済み</a>
    </li>
  </ul>
</nav>

Styleguide 17.6.0
 */

.c-outlineNav {
  border-top: solid 1px var(--color--gray);
  border-bottom: solid 1px var(--color--gray);
  padding: 18px 0;

  @media (--sm) {
    padding: 30px 0;
  }

  &__list {

    @media (--sm) {
      display: flex;
    }
  }

  &__item {

    @media (--sm) {
      margin-right: 10px;
    }

    @media (--sm-max) {
      margin-bottom: 2px;
    }

    /* bootstrap tab用 */
    &.active {
      .c-outlineNav__link {
        border: solid 1px var(--color--red);
        color: var(--color--red);

      }
    }
  }

  a&__link {
    display: flex;
    justify-content: left;
    align-items: center;
    border: solid 1px #fff;
    border-radius: 7px;
    padding: 8px 7px;

    @media (--sm) {
      padding: 2px 7px;
      border-radius: 3px;
      justify-content: center;
    }

    &--active {
      border: solid 1px var(--color--red);
      color: var(--color--red);
    }
  }

  &--gray {
    a.c-outlineNav__link {
      &--active {
        border: solid 1px var(--color--gray);
        color: inherit
      }
    }
  }
}
