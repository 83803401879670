.l-footer {
  &__main {
    background-color: var(--color--gray);

    &Inner {
      @media (--sm-max) {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      @media (--sm) {
        display: flex;
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
  }

  &__navArea {
    @media (--sm-max) {
      margin-bottom: 30px;
      width: 300px;
      min-width: 300px;
    }

    @media (--sm) {
      width: 210px;
      min-width: 210px;
    }

    @media (--md) {
      width: 300px;
      min-width: 300px;
    }
  }

  &__logo {
  }

  &__noticeArea {
    margin: 0 0 30px;

    @media (--sm-max) {
      margin: 0 0 30px;
    }

    @media (--sm) {
      width: 100%;
      flex-grow: 1;
      margin: 0 10px 30px;
    }

    @media (--md) {
      width: 100%;
      flex-grow: 1;
      margin: 0 20px 30px;
    }
  }

  &__navGroup {
    display: flex;

    .c-footerMainNav {
      width: 50%;
      min-width: 50%;
    }
  }

  &__bar {
    background-color: var(--color--black);

    &Inner {
      @media (--sm-max) {
        padding: 30px 3%;
      }

      @media (--sm) {
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &__barNav {
  }

  &__copy {
  }
}
