.c-verification {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

body.is-unverified {
  .c-verification {
    visibility: visible;
    opacity: 1;
  }
}
