/*
c-userBtn

ユーザーメニューボタンコンポーネントです。

markup:
<a id="js-userBtn" class="c-userBtn" href="javascript:void(0);" data-toggle="popover" data-placement="bottom" data-html="true" title="tmp" data-content="tmp" data-template='<div class="popover c-userPopover">
<div class="arrow"></div>
<div class="c-userPopover__header">
<div class="c-userPopover__avatar">
<img src="/g1/resources/01/media/user/02/2/f3cb6fd3f594227cef2a08a60e17f310/avatar.jpg" />
</div>
<div class="c-userPopover__userName"><a href="/profile/user/2/">mrym</a></div>
</div>
<div class="c-userPopover__body">
<ul class="c-userPopover__list"><li class="c-userPopover__item"><a class="c-userPopover__link" href="/me?sk=history">視聴履歴</a></li><li class="c-userPopover__item"><a class="c-userPopover__link" href="/me?sk=later">後で見る</a></li><li class="c-userPopover__item"><a class="c-userPopover__link" href="/me?sk=possession">購入済み</a></li><li class="c-userPopover__item c-userPopover__item--borderTop"><a class="c-userPopover__link" href="/profile/user/2?sk=activity">アクティビティ</a></li><li class="c-userPopover__item"><a class="c-userPopover__link" href="/profile/user/2/?sk=comments">コメント</a></li><li class="c-userPopover__item c-userPopover__item--borderTop"><a class="c-userPopover__link" href="http://g1-test.bgp5.info/me">動画管理</a></li><li class="c-userPopover__item"><a class="c-userPopover__link" href="/add-video">アップロード</a></li><li class="c-userPopover__item c-userPopover__item--borderTop"> <a class="c-userPopover__link" href="http://g1-test.bgp5.info/pay/plan/monthly">30日間見放題プラン</a></li><li class="c-userPopover__item"><a class="c-userPopover__link" href="/dashboard/?sk=edit&tab=social">SNSリンク</a></li><li class="c-userPopover__item"><a class="c-userPopover__link" href="/dashboard/?sk=edit">アバターの変更</a></li><li class="c-userPopover__item"><a class="c-userPopover__link" href="/dashboard/?sk=edit&tab=password">パスワードの変更</a></li><li class="c-userPopover__item"><a class="c-userPopover__link" href="/dashboard/?sk=edit&tab=text">詳細変更</a></li><li class="c-userPopover__item c-userPopover__item--borderTop"><a class="c-userPopover__link" href="/moderator">管理</a></li><li class="c-userPopover__item"><a class="c-userPopover__link" href="/?action=logout">ログアウト</a></li></ul>
</div>
</div>' role="button">
  <img src="http://g1-test.bgp5.info/g1/resources/01/media/user/02/2/f3cb6fd3f594227cef2a08a60e17f310/avatar.jpg" />
</a>

Styleguide 2.4.0
 */

.c-userBtn {
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
}
