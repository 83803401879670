/*
c-authorGrid

出展者用のグリッドコンポーネントです。

markup:
<ul class="c-authorGrid">
  <li class="c-authorCard c-authorGrid__item">
    <a href="/author/6" class="c-authorCard__link">
      <div class="c-authorCard__img" style="background-image: url(http://g1-test.bgp5.info/uploads/def-avatar.jpg)"></div>
      <div class="c-authorCard__body">
        <div class="c-authorCard__maker">Y井Z菜</div>
        <div class="c-authorCard__counter">出展数 26本</div>
      </div>
    </a>
  </li><!-- /.c-authorCard -->
  <li class="c-authorCard c-authorGrid__item">
    <a href="/author/6" class="c-authorCard__link">
      <div class="c-authorCard__img" style="background-image: url(http://g1-test.bgp5.info/uploads/def-avatar.jpg)"></div>
      <div class="c-authorCard__body">
        <div class="c-authorCard__maker">Y井Z菜</div>
        <div class="c-authorCard__counter">出展数 26本</div>
      </div>
    </a>
  </li><!-- /.c-authorCard -->
  <li class="c-authorCard c-authorGrid__item">
    <a href="/author/6" class="c-authorCard__link">
      <div class="c-authorCard__img" style="background-image: url(http://g1-test.bgp5.info/uploads/def-avatar.jpg)"></div>
      <div class="c-authorCard__body">
        <div class="c-authorCard__maker">Y井Z菜</div>
        <div class="c-authorCard__counter">出展数 26本</div>
      </div>
    </a>
  </li><!-- /.c-authorCard -->
</ul>

Styleguide 8.1.0
 */

.c-authorGrid {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: calc((100% - (15px * 2)) / 3);
    margin-bottom: 50px;


    @media (--sm-max) {
      &:not(:nth-of-type(3n)) {
        margin-right: 15px;
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      width: calc((100% - (15px * 3)) / 4);

      &:not(:nth-of-type(4n)) {
        margin-right: 15px;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      width: calc((100% - (15px * 5)) / 6);

      &:not(:nth-of-type(6n)) {
        margin-right: 15px;
      }
    }

    @media (--lg) {
      //width: calc((100% - (15px * 7)) / 8);
      width: calc((100% - (15.2px * 7)) / 8);

      &:not(:nth-of-type(8n)) {
        margin-right: 15px;
      }
    }
  }
}
