/*
c-timeline

タイムラインコンポーネントです。

markup:
<div class="c-timeline">
  <ul class="c-timeline__list">
    <li class="c-timeline__item">
      <div class="c-timeline__time">3 週間前</div>
        <div class="c-timeline__icon c-timeline__icon--film">
          <i class="fa fa-film"></i>
        </div>
        <div class="c-timeline__content">
          <div class="c-timeline__message">
            <div class="c-timeline__message__authorName">
            <a href="/user/mrym">mrym</a>
          </div>
          <div class="c-timeline__message__text">動画：<a class="text-primary" href="http://g1-test.bgp5.info/video/172/" title="【素人ハメ撮り】巨乳フリータ娘サキちゃん。連続イカされ絶頂でドＭ開眼ガールの巻【高画質】">【素人ハメ撮り】巨乳フリータ娘サキちゃん。連続イカされ絶頂でドＭ開眼ガールの巻【高画質】</a>を再生しました。</div>
        </div>
      </div><!-- /.c-timeline__content -->
    </li><!-- /.c-timeline__item -->
    <li class="c-timeline__item">
      <div class="c-timeline__time">3 週間前</div>
        <div class="c-timeline__icon c-timeline__icon--film">
          <i class="fa fa-film"></i>
        </div>
        <div class="c-timeline__content">
          <div class="c-timeline__message">
            <div class="c-timeline__message__authorName">
            <a href="/user/mrym">mrym</a>
          </div>
          <div class="c-timeline__message__text">動画：<a class="text-primary" href="http://g1-test.bgp5.info/video/172/" title="【素人ハメ撮り】巨乳フリータ娘サキちゃん。連続イカされ絶頂でドＭ開眼ガールの巻【高画質】">【素人ハメ撮り】巨乳フリータ娘サキちゃん。連続イカされ絶頂でドＭ開眼ガールの巻【高画質】</a>を再生しました。</div>
        </div>
      </div><!-- /.c-timeline__content -->
    </li><!-- /.c-timeline__item -->
  </ul><!-- /.c-timeline__list -->
</div><!-- /.c-timeline -->

Styleguide 25.1.0
 */

.c-timeline {

  &__list { }

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 0;

    @media (--sm-max) {
      flex-wrap: wrap;
    }

    @media (--sm) {
      padding: 20px 30px 20px 0;
    }

    &::before {
      content: "";
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 24px;
      background-color: var(--color--black);

      @media (--sm) {
        left: 133px;
      }
    }

    &:first-of-type {
      &::before {
        @media (--sm) {
          transform: translateY(50%);
        }

        @media (--sm-max) {
          top: 38px;
        }
      }

      &:last-of-type {
        &::before {
          display: none;
        }
      }
    }

    &:last-of-type {
      &::before {
        @media (--sm) {
          transform: translateY(-50%);
        }

        @media (--sm-max) {
          height: 38px;
        }
      }
    }
  }

  &__time {
    display: flex;
    color: var(--color--text-gray);

    @media (--sm-max) {
      order: 2;
      width: calc(100% - 70px);
      margin-left: 70px;
    }

    @media (--sm) {
      width: 90px;
      min-width: 90px;
      justify-content: flex-end;
      margin-right: 20px;
    }
  }

  &__icon {
    width: 48px;
    min-width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    border: solid 1px var(--color--black);
    background-color: var(--color--black);
    margin-right: 25px;
    z-index: 10;

    @media (--sm-max) {
      order: 1;
      position: absolute;
      left: 0;
      top: 10px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border: solid 5px #fff;
      border-radius: 50%;
      left: 0;
      top: 0;
    }

    &--thumbs-up {
      background-color: var(--color--darkgreen);
      border: solid 1px var(--color--darkgreen);
    }

    &--upload {
      background-color: var(--color--darkred);
      border: solid 1px var(--color--darkred);
    }

    &--share {
      background-color: var(--color--darkskyblue);
      border: solid 1px var(--color--darkskyblue);
    }

    &--film {
      background-color: var(--color--black);
      border: solid 1px var(--color--black);
    }

    &--camera {
      background-color: var(--color--black);
      border: solid 1px var(--color--black);
    }

    &--star {
      background-color: var(--color--darkblue);
      border: solid 1px var(--color--darkblue);
    }

    &--heart {
      background-color: var(--color--red);
      border: solid 1px var(--color--red);
    }

    &--comments {
      background-color: var(--color--darkskyblue);
      border: solid 1px var(--color--darkskyblue);
    }

    &--rss {
      background-color: var(--color--darkskyblue);
      border: solid 1px var(--color--darkskyblue);
    }

    &--like {
      background-color: var(--color--red);
      border: solid 1px var(--color--red);
    }

    i.fa {
      color: #fff;
    }
  }

  &__content {
    width: 100%;
    flex-grow: 1;

    @media (--sm-max) {
      order: 3;
      width: calc(100% - 70px);
      margin-left: 70px;
    }
  }

  &__message {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    &__authorName {
      margin-right: 5px;

      a {
        color: var(--color--text-gray);
      }
    }

    &__text {
      a {
        color: var(--color--text-gray);
      }
    }
  }

  &__comment {
    border: solid 1px var(--color--darkgray);
    border-radius: 10px;
    padding: 10px 20px;
  }
}
