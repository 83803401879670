.p-plan {
  &__ttl {
    &--maker {
      font-size: 2.6rem;
      display: inline-block;
      margin-bottom: 5px;
    }

    &--plan {
      font-size: 1.4rem;
      margin-right: 40px;
    }

    &--desc {
      font-size: 1.4rem;
      padding: 20px 0;
    }

    &--num {
      display: flex;
      font-size: 1.4rem;
    }

    &--more {
      text-align: right;
      font-size: 1.4rem;
      padding-bottom: 20px;

      .fas {
        margin-right: 5px;
      }
    }

    &--usd {
      font-size: 1.4rem;
    }

    &--usds {
      font-size: 2.8rem;
      line-height: 0;
    }

    &--price {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ff0000;
      padding: 10px 70px;
      font-size: 1.8rem;
      color: var(--color--red);
      background: var(--color--white);
    }
  }

  &__line1 {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px #000 solid;
    border-bottom: 1px #ccc solid;
    padding: 20px 0;
    font-size: 1.4rem;
  }

  &__line2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 50px;

    .l-detailSidebar__priceRate {
      margin-bottom: 10px;
    }
  }

  &__line2--plan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 10px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;

    .l-detailSidebar__priceRate {
      margin-bottom: 10px;
    }
  }

  &__line3 {
    display: flex;
    justify-content: center;
  }

  &__items {
  }

  &__item {
  }

  &__link {
  }

  &__tag {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color--black);
    color: var(--color--white);
    padding: 2px 5px 0;
    margin-right: 8px;
    font-size: 1.2rem;
  }
}
