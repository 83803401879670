.u-color {
  &--red {
    color: var(--color--red);
  }

  &--blue {
    color: var(--color--blue);
  }

  &--gray {
    color: var(--color--gray);
  }

  &--black {
    color: var(--color--black);
  }
}
