/*
c-pagination

ページネーションコンポーネントです。

markup:
<nav class="c-pagination"><ul class="c-pagination__list"><li class="c-pagination__item c-pagination__item--disabled"><span class="c-pagination__nolink"><i class="fa fa-chevron-left"></i></span></li><li class="c-pagination__item c-pagination__item--active"><span class="c-pagination__nolink">1</span></li><li class="c-pagination__item"><a class="c-pagination__link" href="2">2</a></li><li class="c-pagination__item"><a class="c-pagination__link" href="3">3</a></li><li class="c-pagination__item"><a class="c-pagination__link" href="4">4</a></li><li class="c-pagination__item"><a class="c-pagination__link" href="5">5</a></li><li class="c-pagination__item"><a class="c-pagination__link" href="6">6</a></li><li class="c-pagination__item"><a class="c-pagination__link" href="7">7</a></li><li class="c-pagination__item"><a class="c-pagination__link c-pagination__link--next" href="2"><i class="fa fa-chevron-right"></i></a></li></ul></nav><!-- /.p-myPage__pagination -->

Styleguide 18.1.0
 */

.c-pagination {
  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__form {
    border: none;
    background: var(--color--gray);
    border-radius: 22px;
    padding: 7px 10px;
  }

  &__nolink,
  &__link {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    @media (--xs-max) {
      width: 27px;
      height: 27px;
    }
  }

  &__link {

    &--prev { }

    &--next { }
  }

  &__nolink { }

  &__item {
    margin: 0 3px;

    &--active {
      .c-pagination__nolink {
        background-color: var(--color--red);
        color: #fff;
      }
    }
  }

  &--disabled { }
}
