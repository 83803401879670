/*
c-lineTable

会社概要や特定商取引法などのテーブル用

.c-lineTable - 基本スタイル

markup:
<table class="c-lineTable">
  <tbody class="c-lineTable__tbody">
  <tr class="c-lineTable__tr">
    <th class="c-lineTable__th">販売業者</th>
    <td class="c-lineTable__td">XXXXXXXX</td>
  </tr>
  <tr class="c-lineTable__tr">
    <th class="c-lineTable__th">サービス名</th>
    <td class="c-lineTable__td">XXXX</td>
  </tr>
  <tr class="c-lineTable__tr">
    <th class="c-lineTable__th">運営責任者</th>
    <td class="c-lineTable__td">XXXXXXXXX</td>
  </tr>
  <tr class="c-lineTable__tr">
    <th class="c-lineTable__th">住所</th>
    <td class="c-lineTable__td">XXXXXXXXXXXXX</td>
  </tr>
  <tr class="c-lineTable__tr">
    <th class="c-lineTable__th">電話番号</th>
    <td class="c-lineTable__td">XX-XXXX-XXXX</td>
  </tr>
  <tr class="c-lineTable__tr">
    <th class="c-lineTable__th">メールアドレス</th>
    <td class="c-lineTable__td">xxx@xx.xxx</td>
  </tr>
  <tr class="c-lineTable__tr">
    <th class="c-lineTable__th">URL</th>
    <td class="c-lineTable__td">https://xxx.xxx</td>
  </tr>
  <tr class="c-lineTable__tr">
    <th class="c-lineTable__th">注文方法</th>
    <td class="c-lineTable__td">
      商品詳細ページの「ご購入はこちら」ボタンをクリックまたは、タップしていただくと、クレジットカード情報の入力欄が表示されますので 必要事項をご記入ご確認の上、送信ボタンをクリックして下さい。
    </td>
  </tr>
  <tr class="c-lineTable__tr">
    <th class="c-lineTable__th">支払方法</th>
    <td class="c-lineTable__td">
      クレジットカードのみご利用頂けます。<br />
      為替相場の変動により、記載されている円相当額と実際にカード会社から請求される金額に若干の誤差が生じることがあります。<br />
      カード会社よっては手数料を別途為替手数料を徴収するところもございます。
    </td>
  </tr>
  <tr class="c-lineTable__tr">
    <th class="c-lineTable__th">支払期限</th>
    <td class="c-lineTable__td">
      動画視聴前にクレジット決済でのお支払いとなります。
    </td>
  </tr>
  <tr class="c-lineTable__tr">
    <th class="c-lineTable__th">引渡し時期</th>
    <td class="c-lineTable__td">
      決済後すぐにご視聴、ダウンロード頂けます。
    </td>
  </tr>
  </tbody>
</table>

Styleguide 24.2.0
 */

.c-lineTable {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;

  &__tr {
    border-top: 1px solid var(--color--darkgray);

    @media (--sm-max) {
      display: block;
      padding: 20px 0;
    }

    &:last-of-type {
      border-top: 1px solid var(--color--darkgray);
      border-bottom: 1px solid var(--color--darkgray);

      @media (--sm-max) {
        display: block;
        padding: 20px 0;
      }
    }
  }

  &__th {
    font-weight: 600;

    @media (--sm-max) {
      display: block;
    }

    @media (--sm) {
      padding: 30px 0;
      font-weight: normal;
    }
  }

  &__td {
    @media (--sm-max) {
      display: block;
    }

    @media (--sm) {
      padding: 30px 0;
    }
  }
}
