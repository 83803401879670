.p-sample {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    z-index: 10;
  }

  &__meta {
    flex-grow: 1;
    width: calc(100% - 80px - 10px);
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;

    @media(--sm) {
      width: calc(100% - 120px - 10px);
    }

    a&__link {
      display: block;
      color: #fff;
      padding: 5px;

      @media(--sm) {
        padding: 20px;
      }
    }
  }

  &__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: normal;
    margin-bottom: 7px;
  }

  &__maker {
    display: flex;
    align-items: center;

    &__term {
      margin-right: 5px;
    }

    &__description {}
  }

  &__logo {
    width: 80px;
    opacity: 0.5;

    @media(--sm) {
      width: 120px;
    }
  }

  #video__loading__sign {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .video-js {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .vjs-big-play-button {
      width: 60px;
      height: 70px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: none;
      background-color: transparent !important;

      .vjs-icon-placeholder {
        display: block;

        &::before {
          content: "";
          display: block;
          width: 60px;
          height: 70px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url(/tpl/g1/img/ja/icon-play.png);
        }
      }
    }
  }

  .vjs-modal-dialog .vjs-modal-dialog-content {
    padding-top: 120px;
  }
}
