.p-cart {
  &__priceCard {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #666;
    padding-bottom: 10px;
    margin-bottom: 50px;
    &--l {
      font-size: 1.8rem;
    }
    &--r {
      margin: 0 0 0 auto;
    }
  }
}
