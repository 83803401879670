.u-vish {
  &--sp {
    @media (--sm-max) {
      visibility: hidden;
    }
  }

  &--pc {
    @media (--sm) {
      visibility: hidden;
    }
  }
}
