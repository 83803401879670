.p-sales {
  &__carender {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-item: baseline;
    margin-bottom: 50px;

    &__input {
      flex-grow: 1;
      padding: 14px;
      border-radius: 3px;
      margin-right: 20px;

      @media (--sm-max) {
        margin-right: 10px;
      }
    }

    &__btn {
      width: 80px;
      border-radius: 3px;
      background: var(--color--black);
      color: var(--color--white);

      &--white {
        display: block;
        border: 1px solid #333;
        text-align: center;
        align-self: center;
        padding: 14px 0;
        width: 80px;
        color: var(--color--black);
        border-radius: 3px;
      }
    }
  }

  &__price {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--color--gray);
    line-height: 1;

    @media (--sm-max) {
      flex-wrap: wrap;
    }

    &__date {
      font-size: 1.4rem;

      @media (--sm-max) {
        height: 20px;
        width: 100%;
      }
    }

    &__matter {
      margin-left: auto;
      font-size: 1.4rem;

      @media (--sm-max) {
        margin-left: 0;
      }
    }

    &__profit {
      font-size: 3rem;
      margin-left: 20px;
      margin-bottom: -2px;

      @media (--sm-max) {
        margin-left: auto;
      }
    }
  }

  &__date {
    margin-top: 5px;
  }

  &__info {
    margin-top: 40px;

    &__table {
      width: 100%;
    }

    &__tr {
      border-bottom: 1px solid var(--color--gray);
    }

    &__th {
      border-bottom: 2px solid var(--color--gray);
      margin-bottom: 15px;
      padding-bottom: 10px;
      text-align: center;
    }

    &__th:first-child {
      text-align: left;
    }

    @media (--sm-max) {
      &__th:last-child {
        display: none;
      }
    }

    &__td {
      padding: 15px;
      text-align: center;
      vertical-align: middle;
    }

    &__td:first-child {
      text-align: left;
      padding-left: 0;
    }

    @media (--sm-max) {
      &__td:last-child {
        display: none;
      }
    }
  }
}
