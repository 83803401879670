/*
c-headerLogo

ヘッダーロゴコンポーネントです。

markup:
<div class="c-headerLogo">
  <a href="/" class="c-headerLogo__link">
    <img class="c-headerLogo__img" src="http://g1-test.bgp5.info/tpl/g1/img/ja/site_logo.png" alt="xxx">
  </a>
</div><!-- /.l-header__logo -->

Styleguide 16.2.0
 */


.c-headerLogo {
  width: 108px;
  min-width: 108px;

  @media (--sm) {
    width: 144px;
    min-width: 144px;
  }

  a {
    display: block;
  }
}
