.p-privacy {
  &__introduction {
    margin-bottom: 30px;
    line-height: 2em;
  }

  &__section {
    margin-bottom: 60px;

    &Title {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }

  &__paragraph {
    margin-bottom: 15px;
  }
}
