.p-faq {
  &__anchorNav {
    margin-bottom: 70px;

    &__list {
      margin-bottom: 70px;
    }

    &__title {
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 20px;
    }

    &__item {
      margin-bottom: 20px;
    }
  }

  &__main {
    margin-bottom: 50px;
  }

  &__post {
    padding-top: 10px;
    margin-bottom: 40px;

    &__question {
      font-size: 1.8rem;
      font-weight: bold;
      position: relative;
      padding-left: 30px;
      margin-bottom: 10px;

      &Icon {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 1.8rem;
        font-weight: bold;
        color: var(--color--text);
      }
    }

    &__answer {
      position: relative;
      padding-left: 30px;

      &Icon {
        position: absolute;
        left: 0;
        top: 0;
        font-weight: bold;
        color: var(--color--red);
        font-size: 1.8rem;
      }

      &Text {
        box-sizing: border-box;
      }
    }

    &__returnTop {
      text-align: right;
      margin-top: 20px;
    }
  }
}

