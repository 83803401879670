.p-monthly {
  &__mainvis {
    height: 335px;
    display: flex;
    justify-content: center;
    background-color: var(--color--black);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
    background: url(/tpl/g1/img/ja/monthly/mainvis.png) repeat;

    &Inner {
      width: 100%;
      padding: 15px 12px;

      @media(--sm) {
        max-width: 1200px;
        padding: 22px 55px;
      }
    }

    &__title {
      margin-bottom: 15px;

      @media(--sm) {
        margin-bottom: 10px;
      }
      &Img {}
    }

    &__description {
      color: #fff;
      font-size: 1.6rem;
      margin: 0 17px 25px;

      @media(--sm) {
        margin-bottom: 17px;
      }

      @media (max-width: 320px) {
        font-size: 1.3rem;
      }
    }

    &__btn {
      width: 100%;
      font-weight: bold;

      &Wrapper {
        width: 100%;
        padding: 0 17px;
      }

      @media(--sm) {
        width: 160px;
      }
    }
  }

  &__anchorNav {
    background-color: var(--color--gray);

    @media(--sm) {
      height: 90px;
      display: flex;
      justify-content: center;
    }

    &__list {
      @media(--sm) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__item {
      @media(--sm-max) {
        border-bottom: solid 1px var(--color--darkgray);
      }

      @media(--sm) {
        margin: 0 30px;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      font-size: 1.8rem;
      padding: 21px 18px;

      @media(--sm) {
        padding: 5px;
      }

      .fas {
        font-size: 2.4rem;
        color: var(--color--red);
        margin-right: 15px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 3% 60px;

    @media(--sm) {
      padding: 50px 0 90px;
    }

    &__title {
      font-size: 2.8rem;
      text-align: center;
      margin-bottom: 35px;

      @media(--sm) {
        font-size: 3.4rem;
      }

      @media (max-width: 320px) {
        font-size: 2.4rem;
      }

      &--price {
        margin-bottom: 20px;
      }
    }

    &__eyecatch {
      display: flex;
      justify-content: center;
      margin-bottom: 45px;
      color: var(--color--darkgray);

      @media(--sm) {
        margin-bottom: 50px;
      }

      .fas {
        font-size: 12.0rem;
        color: var(--color--darkgray);

        &:first-of-type {
          margin-right: 30px;
        }
      }
    }

    &__paragraph {
      font-size: 1.6rem;
      line-height: 1.8;
      text-align: center;

      &--price {
        font-size: 1.8rem;
        margin-bottom: 13px;
      }
    }
  }

  &__section {
    max-width: 900px;
    width: 100%;
    margin-bottom: 75px;

    &--price {
      margin-bottom: 0;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;

    &__term {
      font-size: 2.6rem;
      margin-bottom: 20px;
    }

    &__description {
      display: flex;
      align-items: flex-end;
      color: var(--color--red);
      font-size: 5.4rem;
      font-weight: bold;
      line-height: 1;
    }

    &__yen {
      position: relative;
      bottom: 6px;
      font-size: 2.2rem;
    }

    &__tax {
      position: relative;
      bottom: 9px;
      font-size: 1.4rem;
    }
  }

  &__borderList {
    border-top: solid 1px var(--color--black);

    @media(--sm) {
      margin: 0 20px;
    }

    &__item {
      border-bottom: solid 1px var(--color--black);
      font-size: 1.8rem;
      text-align: center;
      padding: 20px 0;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color--black);
    padding: 40px 30px 60px;

    @media(--sm) {
      padding: 65px 0 60px;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__btn {
      width: 100%;
      font-size: 1.8rem;
      font-weight: bold;

      &Wrapper {
        width: 100%;

        @media(--sm) {
          width: 342px;
        }
      }
    }
  }
}
