.p-uploader {
  padding: 0 20px;

  &__form {
    display: block;
    margin-bottom: 15px;
  }
}


