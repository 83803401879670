#c-sliderCard {
  padding: 0 0 10px 0;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}
#c-slider {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  max-height: 200px;

  @media (--sm-max) {
    max-width: 100%;
    margin: 0 auto;
  }
}

#c-thumbnail_slider {
  width: 500px;
  margin: 10px auto 0;
  display: flex;
  justify-content: center;

  @media (--sm-max) {
    max-width: 100%;
    width: 300px;
    margin: 0 auto;
  }

  .slick-prev:before,
  .slick-next:before {
    color: black;
  }

  .slick-prev,
  .slick-next {
    top: 23px;
  }
}

.c-slide-item {
  padding: 0;
  display: inline;
}

.c-thumbnail-item {
  padding: 0;
}

.c-slide-item img {
  max-width: 100%;
  max-height: 200px;
  display: inline;

  @media (max-width: 414px) {
    max-width: 414px;
    width: 100%;
  }
}

.c-thumbnail-item img {
  max-width: 100%;
  margin: 0 auto;
  width: 70px;
  height: 40px;
}

#c-slider .slick-slide:not(.slick-center) {
  transition: 0.2s linear;
  filter: brightness(50%);
}

#c-thumbnail_slider .c-slick-slide:not(.slick-center) {
  opacity: 1;
}

#c-thumbnail_slider .slick-center img {
  border: 2px solid #000;
  display: inline-block;
}
