.carousel-indicators {
  counter-reset: number;
  list-style: none;
  margin: 0 0 50px 0;
  padding: 0;
  position: relative;
  left: 0;
  text-align: left;

  li {
    margin: 10px;
    display: inline-block;
    text-indent: 0;
    line-height: 0;
    border: 0;
    text-align: center;

    &.active {
      width: 57px;
      height: 0;
      margin: 0px;
      padding: 0px;
      text-indent: 0;
      line-height: 0;
      border: 0;
      text-align: center;

      &::before {
        display: inline-block;
        background: red;
        color: white;
        padding: 12px;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin: 10px 20px;
        position: relative;
        display: flex;
        justify-content: center;
      }
    }
  }

  li::before {
    display: inline-block;
    counter-increment: number;
    content: counter(number);
  }

  &_input120 {
    max-width: 120px;

    @media (--sm-max) {
      max-width: 60px;
    }
  }

  &_input100 {
    width: 100px;
    max-width: 100px;
  }
}

.u-m10 {
  margin: 10px;
}

.u-p10 {
  padding: 10px;
}

.p-affiliate {
  &__afImgBox {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  &__tt {
    &--20 {
      font-size: 2rem;
    }
  }

  &__ttBorder {
    &--16 {
      font-size: 1.6rem;
      margin-bottom: 20px;
      padding-bottom: 5px;
      border-bottom: 1px solid #ddd;
    }
  }

  &_data {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    &InputBox {
      max-width: 200px;

      @media (--sm-max) {
        max-width: 100%;
        width: 100%;
      }
    }

    &InputTextBox {
      margin-bottom: 5px;
    }

    &Input {
      padding: 12px 10px;
      max-width: 200px;
      border-radius: 7px;

      @media (--sm-max) {
        max-width: 100%;
        width: 100%;
      }
    }

    &Btn-serch {
      border-radius: 7px;
      color: var(--color--white);
      height: 50px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color--black);
      border: none;
      padding: 0 30px;
    }

    &Btn-reset {
      border-radius: 7px;
      color: var(--color--black);
      height: 50px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color--white);
      border: none;
      padding: 0 30px;
      border: 1px solid #000;
    }
  }

  &Dis {
    &Sp {
      display: none;

      @media (--sm) {
        display: table-cell;
      }
    }
  }

  &Table {
    font-size: 1.2rem;

    @media (--sm-max) {
      table-layout: fixed;
      word-break: break-word;
    }

    .input-group .form-control {
      @media (--sm-max) {
        float: right;
      }
    }
  }

  &Th {
    &140 {
      width: 140px;
    }

    &130 {
      width: 130px;
    }

    &120 {
      width: 120px;
    }
    &110 {
      width: 110px;
    }
    &100 {
      width: 100px;
    }
    &90 {
      width: 90px;
    }
    &80 {
      width: 80px;
    }
    &70 {
      width: 70px;
    }
    &60 {
      width: 60px;
    }
  }

  &Tdcc {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__InputGroupAddon {
    padding: 5px;
  }

  &__font {
    &--12 {
      font-size: 1.2rem;
    }
    &--14 {
      font-size: 1.4rem;
    }
  }

  &__Btn {
    &Tl10 {
      margin-top: 10px;
      margin-left: 10px;

      @media (--sm-max) {
        margin-top: 20px;
        margin-left: 0px;
      }
    }
  }

  &__rewardBox {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    justify-content: center;
  }

  &__rewardBox--random {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
    background: #f5dfb3;
    border-radius: 5px;
    padding: 15px 15px 10px 10px;
    position: relative;
    margin-top: 10px;
    &::before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 15px 20px 15px;
      border-color: transparent transparent #f5dfb3 transparent;
      display: block;
      content: "";
      position: absolute;
      top: -8px;
      left: 40%;
    }
  }

  &__rewardBox--randomTitle {
    text-align: center;
  }

  &__costBox {
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
    text-align: right;
    font-size: 1.8rem;
    margin: 10px 0 0;
  }

  &__font14 {
    font-size: 1.4rem;
  }

  &__font16 {
    font-size: 1.6rem;
  }

  &__bannerBox {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
  }

  &__samplePre {
    background: #eee;
    overflow: hidden;
    padding: 20px 0 15px;

    &Tt {
      margin: 0 20px 5px;
      padding: 10px;
      background: white;
      border-radius: 5px;
    }
  }
}

.affiTag_{
    &title {
        font-weight: bold;
        margin-bottom: 8px;
    }
    &cnt {
        margin-bottom: 50px;
        display: none;
        padding: 20px 15px 15px;
        border:1px solid #ddd;
    }
    &cnt_show {
        display: block;
    }
}
#affiTag_tabTitle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: -1px;
    li{
        color: #043c78;
        margin: 0;
        list-style: none;
        -webkit-transition: .3s;
        transition: .3s;
        border-radius: 5px 5px 0 0;
        -webkit-border-radius: 5px 5px 0 0;
        -moz-border-radius: 5px 5px 0 0;
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-bottom: none;
        span{
            padding: 10px 20px 8px;
            cursor: pointer;
            display: block;
            -webkit-transition: opacity .3s linear;
            transition: opacity .3s linear;
            border-radius: 5px 5px 0 0;
            -webkit-border-radius: 5px 5px 0 0;
            -moz-border-radius: 5px 5px 0 0;
            &:hover{
                @media (--sm) {
                    opacity: 0.7;
                }
            }
        }

    }
}
.affiTag_tabTitle_active{
    color: #fff;
    cursor: auto;
    color: var(--color--red)!important;
    border-top: 1px solid #ddd !important;
    border-left: 1px solid #ddd !important;
    border-right: 1px solid #ddd !important;
    background-color: #FFF;

}
