/*
data-plugin slick

Slick Sliderプラグインオーバーライド用です。

markup:

Styleguide 23.1.0
 */

[data-plugin="slick"] {
  .slick-slide {
    margin: 10px;

    .c-productGrid__item {
      margin-bottom: 0;
    }
  }

  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;

    &::before {
      font-size: 3.0rem;
      color: var(--color--black);
    }
  }

  .slick-prev {
    left: -45px;
  }

  .slick-next {
    right: -45px;
  }
}
