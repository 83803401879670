/*
c-errorList

エラーリストコンポーネントです。

markup:
<div class="c-errorList">
  <div class="msg-warning">エラーメッセージ</div>
  <div class="msg-warning">エラーメッセージ</div>
  <div class="msg-warning">エラーメッセージ</div>
</div><!-- /.c-errorList -->

Styleguide 15.2.0
 */

.c-errorList {
  .msg-warning {
    display: flex;

    &:not(:last-of-type) {
      margin-bottom: 15px;

      @media (--sm) {
        margin-bottom: 10px;
      }
    }

    &::before {
      content: "\f06a";
      display: inline-block;
      font-weight: 900;
      font-size: 2.0rem;
      font-family: Font Awesome\ 5 Free;
      font-style: normal;
      font-variant: normal;
      color: var(--color--red);
      line-height: 1;
      text-rendering: auto;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      margin-right: 8px;
    }
  }
}
