:root {
  --color--black: #000;
  --color--white: #fff;
  --color--gray: #eee;
  --color--darkgray: #d8d8d8;
  --color--red: #f00;
  --color--crimsonRed: #db0300;
  --color--blue: #004597;
  --color--yellow: #f39c12;
  --color--danger: #fff1f1;

  --color--facebook-blue: #3b5998;
  --color--twitter-blue: #55acee;
  --color--linkedin-blue: #0077b5;
  --color--googleplus-red: #dd4b39;
  --color--fc2-red: #ff0000;

  --color--text: #333;
  --color--text-gray: #777;
  --color--form-gray: #999;
  --color--text-placeholder-gray: #999;

  --color--darkblue: #252d66;
  --color--darkskyblue: #5c7886;
  --color--darkyellow: #523711;
  --color--darkorenge: #943500;
  --color--darkred: #810000;
  --color--darkgreen: #005b4b;
}
