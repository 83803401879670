/*
c-checkNav

チェックボックス風ナビゲーションコンポーネントです。

markup:

Styleguide 17.20.0
 */

.c-checkNav {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-bottom: 20px;

    @media(--xs-max) {
      width: 50%;
    }

    &:not(:last-of-type) {
      @media (--xs) {
        margin-right: 28px;
      }
    }
  }

  a&__link {
    display: flex;
    align-items: center;
    outline: none;

    &--active {
      .c-checkNav__icon {
        background-color: var(--color--black);
      }
    }
  }

  &__icon {
    width: 26px;
    height: 26px;
    position: relative;
    border-radius: 50%;
    background-color: var(--color--gray);
    margin-right: 6px;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }

    &::before {
      width: 12px;
      height: 12px;
      background-color: #fff;
    }

    &::after {
      width: 6px;
      height: 6px;
      background-color: var(--color--black);
    }
  }
}

