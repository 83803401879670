.l-header {
  position: relative;
  z-index: 100;

  &__bar {
    background-color: var(--color--gray);

    @media (--sm-max) {
      display: none;
    }

    @media (--sm) {
      display: block;
    }

    &Inner {
      min-height: 34px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  &__siteTitle {
    margin-right: 15px;
  }

  &__counter {
    margin-right: auto;
  }

  &__main {
    &Inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
    }
  }

  &__hamburger {
    @media (--sm) {
      display: none !important;
    }
  }

  &__logo {
  }

  &__searchBtnWrapper {
    margin-right: auto;
    margin-left: 10px;

    @media (--sm) {
      display: none;
    }
  }

  &__registerNav {
  }

  &__searchWrapper {
    &--pc {
      margin: 0 30px;
      max-width: 385px;
      width: 100%;
      flex-grow: 1;

      @media (--sm-max) {
        display: none;
      }

      .l-header__mainSearch {
        width: 100%;
        max-width: 300px;
      }
    }

    &--sp {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color--black);

      .l-header__search {
        width: 100%;
      }
    }
  }

  &__userBtnWrapper {
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
