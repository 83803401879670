/*
c-tagPopover

タグリストポップオーバーコンポーネントです。

markup:

Styleguide 20.2.0
 */

.c-tagPopover {
  width: 250px;
  padding: 20px 20px 12px;
  //transform: translateX(80px);

  .arrow {
    //left: calc(50% - 80px) !important;
  }
}

