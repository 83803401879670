/*
c-thumbnailCard

横並びサムネイルカードコンポーネントです。

markup:
<div class="c-thumbnailCard">
  <div class="c-thumbnailCard__img"><img src="http://g1-test.bgp5.info/g1/resources/01/media/user/03/3/e28524ccd1613fca1f0720be999f1ada/avatar.jpg" alt="kwt"></div>
  <div class="c-thumbnailCard__body">
    <div class="c-thumbnailCard__name">kwt</div>
    <dl class="c-thumbnailCard__counter">
      <dt class="c-thumbnailCard__counterTerm">出展数</dt>
      <dd class="c-thumbnailCard__counterDescription">1本</dd>
    </dl>
  </div>
</div>
<div class="c-thumbnailCard">
  <div class="c-thumbnailCard__img">
    <img src="http://g1-test.bgp5.info/g1/resources/01/media/video/09/233/b4d70bfbf05bd1d1b3e02713d4349543/image/thumb.png" alt="">
  </div>
  <div class="c-thumbnailCard__body">
    <div class="c-thumbnailCard__title">W鬼チンポ３Pｘ人妻】巨根乱入３P編●人</div>
    <div class="c-thumbnailCard__maker">smda</div>
  </div>
</div>
<div class="c-thumbnailCard">
  <div class="c-thumbnailCard__img"><img src="http://g1-test.bgp5.info/g1/resources/01/media/video/09/233/b4d70bfbf05bd1d1b3e02713d4349543/image/thumb.png" alt="評価！！！"></div>
  <div class="c-thumbnailCard__body">
    <div class="c-thumbnailCard__comment">評価！！！</div>
  </div>
</div>
<div class="c-thumbnailCard">
  <div class="c-thumbnailCard__img"><img src="http://g1-test.bgp5.info/g1/resources/01/media/notice/01/0/b3d18e6f1d6b5b6eaa756be2b8eb2c59/b3d18e6f1d6b5b6eaa756be2b8eb2c59-7.png" alt="ドカンと一発！7000コンテンツ以上あるギャルの中のギャル作品初更新！"></div>
  <div class="c-thumbnailCard__body">
    <time class="c-thumbnailCard__date" datetime="2018-04-20">2018.04.20</time>
    <h3 class="c-thumbnailCard__title">ドカンと一発！7000コンテンツ以上あるギャルの中のギャル作品初更新！</h3>
  </div>
</div>

Styleguide 3.3.0
 */

.c-thumbnailCard {
  display: flex;

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color--gray);
    margin-right: 15px;
    height: 60px;

    img {
      max-width: 60px;
      max-height: 60px;
    }
  }

  &__body {
    width: 100%;
    min-width: 0;
    flex-grow: 1;
  }

  &__title {
    font-size: 1.3rem;
    max-height: 40px;
    overflow: hidden;
    margin-bottom: 5px;
  }

  &__name {
    font-size: 1.3rem;
    max-height: 40px;
    overflow: hidden;
  }

  &__maker {
    color: var(--color--text-gray);
    max-height: 20px;
    overflow: hidden;
  }

  &__date {
    color: var(--color--text-gray);
    font-size: 1.3rem;
    margin-right: 10px;
  }

  &__counter {
    display: flex;
    flex-wrap: wrap;
  }

  &__counterTerm {
    @media (--sm-max) {
      display: none;
    }

    font-size: 1.3rem;
    font-weight: normal;
    color: var(--color--text-gray);
    margin-right: 5px;
  }

  &__counterDescription {
    font-size: 1.3rem;
    color: var(--color--text-gray);
  }

  &__box {
    display: flex;
    margin: 5px 0;
  }
}
