/*
c-searchForm

価格検索用フォームです。

markup:
<div class="c-searchForm">
    <div class="c-searchForm__group">
        <label for="" type="text">価格</label>
        <div class="input-group">
        <input type="number" class="form-control" name="price_start" value="<?php echo (isset($_GET['price_start']))?_get('price_start') : ''; ?>">
        <div class="input-group-addon">円</div>
        </div>
    </div>
    <div class="u-ml5 u-mr5">～</div>
    <div class="c-searchForm__group">
        <div class="input-group">
        <input type="number" class="form-control" name="price_end" value="<?php echo (isset($_GET['price_end']))?_get('price_end') : ''; ?>">
        <div class="input-group-addon">円</div>
        </div>
    </div>
</div>

Styleguide 7.2.0
 */

.c-searchForm {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &__group {
    display: flex;
    align-items: center;
  }
  &__lab {
    width: auto;
    margin-right: 5px;
    @media (--sm-max) {
      width: 65px;
      margin-right: 5px;
    }
  }
}
