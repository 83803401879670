:root {
  /* ゴシック体 */
  --font-family--gothic1: "メイリオ", Meiryo, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "游ゴシック体", "游ゴシック Medium", "Yu Gothic Medium", YuGothic, "MS PGothic", "ＭＳ Ｐゴシック", sans-serif;

  /* ゴシック体 */
  --font-family--gothic2: -apple-system, BlinkMacSystemFont, "Helvetica Neue", HelveticaNeue, "Segoe UI", "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

  /* 明朝体 */
  --font-family--mincho: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;

  /* 等幅 monospace 和文英文混在で表示する機会が少ない場合 … 英字フォントを優先する */
  --font-family--mono1: MeiryoKe_Gothic, "Ricty Diminished", "Courier New", Courier, Monaco, Menlo, Consolas, "Lucida Console", "Osaka－等幅", "Osaka-等幅", Osaka-mono, "ＭＳ ゴシック", "MS Gothic", monospace;

  /* 和文英文混在で表示する機会が多い場合 … 混在フォントを優先して等幅表示を優先させる */
  --font-family--mono2: MeiryoKe_Gothic, "Ricty Diminished", "Osaka－等幅", "Osaka-等幅", Osaka-mono, "ＭＳ ゴシック", "MS Gothic", "Courier New", Courier, Monaco, Menlo, Consolas, "Lucida Console", monospace;
  --fs--xl: 2.4rem;
  --fs--lg: 1.8rem;
  --fs--md: 1.4rem;
  --fs--sm: 1.2rem;
  --fs--xs: 1.0rem;
}
