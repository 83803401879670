/*
c-productCard

商品のカードコンポーネント

markup:
<article class="c-productCard">
  <div class="c-productCard__img" style="background-image:url(/g1/resources/01/media/video/0f/239/4d1ea3b4904326eae4830b5bfbd9c0ad/image/thumb.jpg)">
    <a class="c-productCard__img__link" href="http://g1-test.bgp5.info/video/239/">
      <span class="c-productCard__label c-label c-label--new c-label--sm">新作</span>
      <time class="c-productCard__time">00:07</time>
    </a>
  </div><!-- /.c-productCard__img -->
  <div class="c-productCard__body">
    <h4 class="c-productCard__title">
    <a href="http://g1-test.bgp5.info/video/239/" class="c-productCard__title__link">test__2本立て！】天然かわいい ヲタ女子 聖ちゃん 19才 アニオタのスケベ度は半端ない！壁に足掛け全力で膣壁擦る痴態を世界へ晒したライブチャット映像【全国配信】</a>
    </h4>
    <div class="c-productCard__price"><i class="c-icons--yen"></i><span class="c-productCard__priceNum u-color--red">140</span> 円</div>
    <div class="c-productCard__tags c-tagNav">
      <a href="/tag/おっぱい" class="c-tagNav__link">おっぱい</a>
      <a href="/tag/フェラ" class="c-tagNav__link">フェラ</a>
      <a href="/tag/ハメ撮り" class="c-tagNav__link">ハメ撮り</a>
    </div>
  </div>
</article>

Styleguide 3.2.0
 */

.c-productCard {
  &__img {
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-color: var(--color--gray);
    margin-bottom: 8px;

    &__link {
      display: block;
      padding-top: 100%;
    }
  }

  &__label {
    position: absolute;
    top: 0;

    &--release {
      right: 0;
    }

    &--category {
      left: 0;
    }
  }

  &__time {
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--color--black);
    font-size: 1.2rem;
    color: #fff;
    line-height: 1;
    padding: 0 7px;
  }

  &__body {
  }

  &__title {
    font-size: 1.3rem;
    max-height: 42px;
    overflow: hidden;
    margin-bottom: 10px;

    &__link {
      display: block;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    line-height: 1;
    height: 15px;
    margin-bottom: 5px;

    i {
      margin-right: 3px;
    }

    &Num {
      margin-right: 3px;

      &--mon {
        background: var(--color--black);
        color: var(--color--white);
        font-size: 1rem;
        padding: 2px 4px;
        border-radius: 2px;
        margin-right: 5px;
      }
    }
  }

  &__meta {
    display: flex;
    justify-content: space-between;
  }

  &__postdate {
  }

  &__author {
  }

  &__tags {
    align-items: flex-start;
    max-height: 33px;
    overflow: hidden;
  }

  &__evaluation {
    display: flex;
    align-items: center;
    color: var(--color--text-gray);
    margin-bottom: 5px;

    &__star {
      margin-left: -5px;
      bottom: 2px;
    }

    &__starCount {
      margin-right: 5px;
    }

    &__coment {
      i {
        margin-right: 5px;
      }
    }
  }
}
