/*
c-alert

注釈など目立たせたい場所に適用します。

.c-alert - 基本スタイル
.c-alert--danger - 警告スタイル

markup:
<div class="c-alert {$modifiers}">（{$modifiers}）テキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</div>

Styleguide 1.1.0
 */

.c-alert {
  border-radius: 10px;
  background-color: var(--color--gray);
  padding: 16px;

  &--danger {
    background-color: var(--color--danger);
  }

  &__reward {
    &--yellow {
      padding: 10px 15px;
      margin: 3px;
      text-decoration: none;
      background: #ffa920;
      color: #fff;
      border-radius: 1px;
      box-shadow: 0px 0px 0px 3px #ffa920;
      border: dashed 2px #fff;
      font-size: 1.6rem;
    }

    &--red {
      padding: 10px 15px;
      margin: 3px;
      text-decoration: none;
      background: #ff8181;
      color: #fff;
      border-radius: 1px;
      box-shadow: 0px 0px 0px 3px #ff8181;
      border: dashed 2px #fff;
      font-size: 1.6rem;
    }

    &--blue {
      padding: 10px 15px;
      margin: 3px;
      text-decoration: none;
      background: #00bcd4;
      color: #fff;
      border-radius: 1px;
      box-shadow: 0px 0px 0px 3px #00bcd4;
      border: dashed 2px #fff;
      font-size: 1.6rem;
    }
  }

  &__rewardS {
    &--yellow {
      padding: 8px 10px;
      margin: 3px;
      text-decoration: none;
      background: #ffa920;
      color: #fff;
      border-radius: 1px;
      box-shadow: 0px 0px 0px 3px #ffa920;
      border: dashed 1px #fff;
      font-size: 1.2rem;
    }

    &--red {
      padding: 8px 10px;
      margin: 3px;
      text-decoration: none;
      background: #ff8181;
      color: #fff;
      border-radius: 1px;
      box-shadow: 0px 0px 0px 3px #ff8181;
      border: dashed 1px #fff;
      font-size: 1.2rem;
    }

    &--blue {
      padding: 8px 10px;
      margin: 3px;
      text-decoration: none;
      background: #00bcd4;
      color: #fff;
      border-radius: 1px;
      box-shadow: 0px 0px 0px 3px #00bcd4;
      border: dashed 1px #fff;
      font-size: 1.2rem;
    }
  }
  &__notice {
    &--pink {
      background: #ffe8ff;
      padding: 15px 20px;
      color: var(--color--black);
      text-align: center;
    }
  }
}
