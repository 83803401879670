.p-404Sample {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px var(--color--gray);

  &__in {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .c-404__title {
    @media (--xs-max) {
      font-size: 12rem;
      line-height: 1;
    }
  }

  .c-404__discription {
    @media (--xs-max) {
      margin-bottom: 0;
    }
  }
}
