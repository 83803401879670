/*
c-customComment

phpVibeのcommentを上書くカスタムコメントです。

markup:

Styleguide 4.1.0
 */

.c-customComment {
  .addEmComment {
    padding: 15px;
    line-height: 1.8;
  }

  .pull-right {
    float: left !important;
    margin: 10px 30px 0 0;
    padding: 10px 15px;
    background: var(--color--black);
  }

  .comments {
    & > li {
      border-bottom: 1px solid var(--color--gray);
      padding-bottom: 30px;
      margin-bottom: 15px;

      .message span.caution {
        margin-top: 12px;
      }
    }

    li {
      .arrow-inner,
      .message .arrow-outer {
        display: none;
      }
    }

    .msg-footer {
      .jq-ry-container {
        top: 3px;
        margin-top: 5px;
      }
    }
  }

  .jq-ry-container {
    padding: 0;
    margin-top: 10px;
  }
}

