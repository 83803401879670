/*
c-headerSearch

ヘッダー検索ボックスコンポーネントです。

markup:
<div id="js-header__search" class="l-header__search c-headerSearch c-headerSearch--radius">
  <form action="" method="get" id="searchform" class="c-headerSearch__form" onsubmit="" role="search">
    <i class="fa fa-search c-headerSearch__icon"></i>
    <input id="search_word" class="c-headerSearch__input" type="text" name="tag" placeholder="キーワード入力">
  </form>
</div>

Styleguide 22.1.0
 */

.c-headerSearch {
  &__form {
    display: flex;
    position: relative;
  }

  &__select {
    width: 100%;
    height: 40px;
    appearance: none;
    border: none;
    background-color: var(--color--gray);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding-left: 16px;

    &::-ms-expand {
      display: none;
    }

    &Wrapper {
      min-width: 140px;
      width: 140px;
      position: relative;
      border-right: solid 1px transparent;

      &::after {
        display: block;
        content: "";
        width: 8px;
        position: absolute;
        top: 50%;
        right: 7px;
        transform: translateY(-50%);
        border-top: solid 7px var(--color--black);
        border-left: solid 4px transparent;
        border-right: solid 4px transparent;
        pointer-events: none;
      }
    }
  }

  &__input {
    width: calc(100% - 140px);
    height: 40px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    background-color: var(--color--gray);
    border: none;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    outline: none;
    -webkit-appearance: none;
    padding: 0 20px 0 10px;

    &--act {
      width: 100%;
      max-width: 500px;
      margin: 0px auto 20px;
      height: 40px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      background-color: var(--color--gray);
      border: none;
      border-radius: 50px;
      outline: none;
      -webkit-appearance: none;
      padding: 0 20px;

      @media (--sm-max) {
        margin: 20px auto 20px;
      }
    }
  }

  &__conp {
    border-radius: 10px;
    position: relative;
    padding: 20px;
    background: var(--color--gray);

    &::before {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid #eee;
      position: absolute;
      display: block;
      content: "";
      top: -15px;
      right: 50%;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    pointer-events: none;
    font-size: 2rem;
    color: var(--color--gray);
    margin-right: 10px;
  }

  &__suggest {
    background: white;
    padding: 20px 15px 15px 15px;
    width: 700px;
    border: 1px solid #ddd;
    margin-top: 10px;
    border-radius: 8px;
    display: none;
    position: absolute;
    left: 27%;
    @media (--lg) {
      width: 700px;
      left: auto;
    }

    @media (--md-max) {
      width: calc(100% - 40px);
      left: 20px;
    }
    @media (--sm-max) {
      width: calc(100% - 30px);
      top: 120px;
      left: 15px;
    }
    &::before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 13px 8px;
      border-color: transparent transparent #ffffff transparent;
      display: block;
      content: "";
      top: -10px;
      left: 44%;
      position: absolute;
      z-index: 999;
      @media (--lg) {
        left: 35%;
      }
      @media (--sm-max) {
        left: 45%;
      }
    }
    &::after {
      display: block;
      content: "";
      height: 0;
      border-style: solid;
      border-width: 0 8px 13px 8px;
      border-color: transparent transparent #ddd transparent;
      position: absolute;
      top: -13px;
      left: 44%;
      @media (--lg) {
        left: 35%;
      }
      @media (--sm-max) {
        left: 45%;
      }
    }
    li {
      margin-bottom: 5px;
    }
  }
}
